import { MainContext } from 'context/mainContext';
import { head } from 'lodash';
import React, { useContext, useEffect, useState } from 'react'
import { useCallback } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { ChromePicker } from 'react-color'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { getRandomLightColor } from 'services/EVAService';
import { defaultControl } from 'services/EVAVisualService';

function VisualGraphView({ gridHeader, selectedViewOption, sqlresult, dataValue, treeViewData, headerToMultiSelect, handleSaveVisual, 
    templateItemVisual, item, onEditVisual, handleCloseEditVisual, onEditName }) {
    const {
        bgColor,
        textColor
    } = useContext(MainContext);

    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedFooter, setSelectedFooter] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState([{ label: '', data: '', colIndex: null, color: defaultControl(0), isOpen: false }]);
    const [chartDataSet, setChartDataSet] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState('#ffffff');
    const [color, setColor] = useState(bgColor);
    const [chartHeader, setChartHeader] = useState([])
    const [selectedGraphHeaderOption, setSelectedGraphHeaderOption] = useState(null)
    const [isEditVisual, setIsEditVisual] = useState(false)
    const [visualSettings, setVisualSettings] = useState(null)
    const [gridGroupLevel, setGridGroupLevel] = useState(null)

    let chartData = {
        labels: [],
        datasets: [],
    }
    useEffect(() => {
        if (selectedFooter) {
            const footer = getChartFooter(selectedFooter.id);
            chartData.labels = footer;
        }
    
        if (selectedFooter && selectedGroups) {
            getChartData(selectedGroups, selectedGraphHeaderOption, selectedFooter.id);
        }
    
        const param = {
            selectedViewOption,
            selectedGroups,
            selectedGroup,
            chartHeader,
            selectedFooter,
            selectedGraphHeaderOption,
            headerToMultiSelect,
            chartData,
        };
        setVisualSettings(param);
    }, [selectedGroups, selectedFooter, selectedGraphHeaderOption]);
    useEffect(() => {
        if(item && item.editedGridCellData) setGridGroupLevel(item.editedGridCellData.gridGroupLevel)
    }, [item])
    useEffect(() => {
        if (!templateItemVisual) return;
        
        console.log(templateItemVisual)
        
        const {
            chartHeader,
            selectedFooter,
            selectedGroup,
            selectedGroups,
            selectedViewOption,
            headerToMultiSelect,
        } = templateItemVisual;
    
        if (selectedViewOption === undefined) return;
    
        if (["Bar Chart", "Line Chart"].includes(selectedViewOption)) {
            // console.log(templateItemVisual);
    
            if (selectedGroup !== "") {
                setSelectedGroup(selectedGroup);
                handleSelectGroupList(selectedGroup, headerToMultiSelect);
            }
    
            setSelectedGroups(selectedGroups);
            setSelectedFooter(selectedFooter);
            setSelectedGraphHeaderOption(selectedGraphHeaderOption);
        }
    }, [templateItemVisual]);
    

    const getChartFooter = (id) => {
        const label = []
        if(gridGroupLevel) {
            let currentIndentLevel = 0;
            console.log(selectedGraphHeaderOption)
            if(selectedGraphHeaderOption) loopThruParentChild(id, treeViewData)
            else loopThruItems(id, treeViewData)

            function loopThruItems(indentLevel, parent)
            {
                for (let i = 0; i < parent.length; i++) { 
                    const row = parent[i];
                    if(id === 0) {
                        insertLabel(row)
                    } else {
                        if (currentIndentLevel === id) {
                            insertLabel(row)
                        }

                        if (row.children) {
                            currentIndentLevel++;
                            loopThruItems(indentLevel, row.children)
                            currentIndentLevel--;
                        }
                    }
                }
            }

            function loopThruParentChild(indentLevel, parent)
            {
                for (let i = 0; i < parent.length; i++) { 
                    const row = parent[i];
                    if(row.sqlId === selectedGraphHeaderOption.id) 
                    {
                        if(row.children) {
                            currentIndentLevel++;
                            loopThruChildren(row.children)
                        }
                        else insertLabel(row)
                    }

                    if(row.children) {
                        currentIndentLevel++;
                        loopThruParentChild(indentLevel, row.children)
                        currentIndentLevel--;
                    }
                }

                function loopThruChildren(parent)
                {
                    for (let i = 0; i < parent.length; i++) { 
                        const row = parent[i];
                        if (currentIndentLevel === id) {
                            insertLabel(row)
                        }

                        if(row.children) {
                            currentIndentLevel++;
                            loopThruChildren(row.children)
                            currentIndentLevel--;
                        }
                    }
                }
            }

            // function loopThruItems(parent, id)
            // {
            //     for (let i = 0; i < parent.length; i++) { 
            //         const row = parent[i];
            //         if(id === 0) {
            //             insertLabel(row)
            //         } else {
            //             if(row.id.includes(level[id])) {
            //                 insertLabel(row)
            //             }

            //             if (row.children) {
            //                 loopThruItems(row.children, id);
            //             }
            //         }
            //     }
            // }

            // function loopThruParentChild(parent)
            // {
            //     for (let i = 0; i < parent.length; i++) { 
            //         const row = parent[i];
            //         if(row.sqlId === selectedGraphHeaderOption.id) 
            //         {
            //             if(row.children) loopThruChildren(row.children)
            //             else insertLabel(row)
            //         }

            //         if(row.children) loopThruParentChild(row.children)
            //     }

            //     function loopThruChildren(parent)
            //     {
            //         for (let i = 0; i < parent.length; i++) { 
            //             const row = parent[i];
                        
            //             if(row.children) loopThruChildren(row.children)
            //             else if(row.value) insertLabel(row)
            //         }
            //     }
            // }

            function insertLabel(row) {
                const param = {id: row.sqlId, label: row.name}
                if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-"))
                {
                    label.push(param)
                }
                
            }
        } else {
            sqlresult.forEach(element => {
                if(selectedGraphHeaderOption)
                {
                    if(!label.includes(element[id]) && element[chartHeader.fieldIndex] === selectedGraphHeaderOption)
                    label.push(element[id])
                }else{
                    if(!label.includes(element[id]))
                    label.push(element[id])
                }
            });
        }
        console.log(label)
        return label
    }
    const getChartData = (filter, withHeader, id) => 
    {
        filter.forEach((fil, index) => {
            if(fil.data !== '' && fil.label !== '')
            {
                const res = headerToMultiSelect && headerToMultiSelect.length > 0 && headerToMultiSelect.filter((e) => e.name === fil.data);
                if(res.length > 0)
                {
                    const id = res[0].id
                    if(chartData.labels.length > 0)
                    {
                        const item = {
                            id: index,
                            label: fil.label,
                            data: [],
                            backgroundColor: fil.color,
                            borderColor: fil.color,
                        }
                        let itemData = []
                        
                        chartData.labels.forEach((item) => {
                            let totalAmount = 0
                           
                            if(gridGroupLevel) {
                                const level = ['-parent-', '-sub-', '-child-']
                              
                                loopThruItems(treeViewData, id)

                                function loopThruItems(parent, id)
                                {
                                    for (let i = 0; i < parent.length; i++) { 
                                        const row = parent[i];
  
                                        if(row.sqlId === item.id) {
                                            processCalculation(row)

                                            if (row.children) {
                                                loopThruParentChild(row.children)
                                            }
                                        }

                                        if (row.children && id !== 0) {
                                            loopThruItems(row.children, id);
                                        }
                                    }
                                }

                                function loopThruParentChild(parent)
                                {
                                    for (let i = 0; i < parent.length; i++) { 
                                        const row = parent[i];

                                        processCalculation(row)
                                       

                                        if (row.children) {
                                            loopThruParentChild(row.children, id);
                                        }
                                        
                                    }
                                }

                                function processCalculation (row)
                                {
                                    if(row.value && !row.sqlId.includes("-TotalRow"))
                                        {
                                            const itemToSum = row.value[fil.colIndex]
                                            if (typeof itemToSum === 'string') 
                                            if (itemToSum.includes('%')) 
                                                if(!isNaN(parseFloat(itemToSum.replace('%', '')).toFixed(2)))
                                                    itemToSum = parseFloat(itemToSum.replace('%', '')).toFixed(2)
                                        
                                                totalAmount += itemToSum
                                        }
                                }

                                // sqlresult.forEach((data) => {
                                //     if(item.label === data[selectedFooter.id])
                                //     {
                                //         if (typeof data[id] === 'string') 
                                //             if (data[id].includes('%')) 
                                //                 if(!isNaN(parseFloat(data[id].replace('%', '')).toFixed(2)))
                                //                     data[id] = parseFloat(data[id].replace('%', '')).toFixed(2)
                                        
                                //         totalAmount += data[id]
                                //     }
                                // })
                            } else {
                                sqlresult.forEach((data) => {
                                    if(item === data[selectedFooter.id])
                                    {
                                        if (typeof data[id] === 'string') 
                                            if (data[id].includes('%')) 
                                                if(!isNaN(parseFloat(data[id].replace('%', '')).toFixed(2)))
                                                    data[id] = parseFloat(data[id].replace('%', '')).toFixed(2)
                                        
                                        totalAmount += data[id]
                                    }
                                })
                            }
                            itemData.push(totalAmount)
                        })
                    
                        item.data = itemData
                        chartData.datasets.push(item)
                    }
                }
                
            }
        })
        
        if(chartData.datasets.length > 0 && chartData.labels.length > 0)
        {
            chartData.labels = gridGroupLevel ? reconstructChartLabel(chartData.labels) : chartData.labels
            setChartDataSet(chartData)
        }
        function reconstructChartLabel(label)
        {
            const newLabelSet = label.map(item => { return item.label })
            return newLabelSet
        }
    }
    const handleSelectGroupChange = event => {
        if(event.target.value !== 'Select Group By')
        {
            setSelectedGroup(event.target.value);
            handleSelectGroupList(event.target.value, headerToMultiSelect)
        }else{
            setSelectedGraphHeaderOption(null)
            setSelectedGroup(null)
            setChartHeader({fieldIndex: 0, header : []})
        }
    };
    const handleSelectGroupList = (value, headerToMultiSelect) => {
       
        const res = headerToMultiSelect && headerToMultiSelect.length > 0 && headerToMultiSelect.filter((e) => e.name === value);
        if(res && res.length > 0)
        {
            const header = getChartHeader(res[0].id)
            console.log(header)
            setChartHeader({fieldIndex: res[0].id, header : header})
        }
    }
    const getChartHeader = (id) => {
        const label = []
        if(gridGroupLevel) {
            const level = ['-parent-', '-sub-', '-child-']
           
            loopThruParentChild(treeViewData, id)

            function loopThruParentChild(parent, id)
            {
                for (let i = 0; i < parent.length; i++) { 
                    const row = parent[i];
                    if(id === 0) {
                        insertLabel(row)
                    } else {
                        if(row.id.includes(level[id])) {
                            insertLabel(row)
                        }

                        if (row.children) {
                            loopThruParentChild(row.children, id);
                        }
                    }
                }
            }

            function insertLabel(row) {
                const param = {id: row.sqlId, label: row.name}
                if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-"))
                label.push(param)
            }
        } else 
        sqlresult.forEach(element => {
            if(!label.includes(element[id]))
            label.push(element[id])
        });
        console.log(label)
        return label
    }
    const handleSelectFooterChange = (value) => {
        const res = headerToMultiSelect.filter((e) => e.name === value);
        if(res && res.length > 0) setSelectedFooter({ id: res[0].id, label: value });
        else setSelectedFooter(null);
    };
    const toggleColorPicker = (index, value) => {
        setIsOpen(!isOpen);
        setSelectedGroups(prevGroups => {
            const updatedGroups = [...prevGroups];
            updatedGroups[index]['isOpen'] = value;
            return updatedGroups;
        });
    };
    const handleRemoveFormGroup = index => {
        setSelectedGroups(prevGroups => prevGroups.filter((group, i) => i !== index));
    };
    const handleSelectChange = (index, type, value) => {
        setSelectedGroups(prevGroups => {
          const updatedGroups = [...prevGroups];
         
          updatedGroups[index][type] = value;
          if(type === "data") 
          {
            const colIndex = gridHeader.indexOf(value) - gridGroupLevel
            updatedGroups[index]['colIndex'] = colIndex
          }
          return updatedGroups;
        });
    };
    const handleChangeComplete = (newColor, index) => {
        setSelectedColor(newColor.hex);
        setSelectedGroups(prevGroups => {
            const updatedGroups = [...prevGroups];
            updatedGroups[index]['color'] = newColor.hex;
            return updatedGroups;
        });
    };
    const handleAddFormGroup = () => {
        setSelectedGroups(prevGroups => {
            const newLength = prevGroups.length;
            return [
                ...prevGroups,
                { label: '', data: '', colIndex: null, color: defaultControl(newLength), isOpen: false }
            ];
        });
        
    };
    const handleSelectGraphHeader = (item) => {

        setSelectedGraphHeaderOption(item)
    }
    const onHandleSaveVisual = () => {
        handleSaveVisual(visualSettings)
    }
   
    
    return (
        <>
            
            {(onEditVisual || onEditName) &&
                <>
                    <FormGroup>
                        <Col sm='12' md='4'>
                            <Row>
                                <Label htmlFor="selectGroup">Select Group By (Optional)</Label>
                                <Input type="select" id="selectGroup" value={selectedGroup} onChange={handleSelectGroupChange}>
                                <option value={null}>Select Group By</option>
                                {/* {gridHeader.length > 0 && gridHeader.map(group => (
                                    <option key={group} value={group}>
                                        {group}
                                    </option>
                                ))} */}
                                {gridGroupLevel ? 
                                    <>
                                        {gridHeader.map((group, index) => (
                                            index < gridGroupLevel && 
                                            <option key={group} value={group}>
                                                {group}
                                            </option>
                                        ))}
                                    </> : <>
                                        {gridHeader.map((group, index) => (
                                            <option key={group} value={group}>
                                                {group}
                                            </option>
                                        ))}
                                    </>
                                }
                                </Input>
                            </Row>
                        </Col>
                    </FormGroup>
                    {gridHeader && gridHeader.length > 0 &&
                        <FormGroup>
                            <Col sm='12' md='4'>
                                <Row>
                                    <Label htmlFor="selectGroup"><span style={{ color: 'red'}}>* </span>Select Primary Horizontal</Label>
                                    <Input 
                                        type="select" 
                                        id="selectGroup" 
                                        value={selectedFooter ? selectedFooter.label : ''}
                                        onChange={e => handleSelectFooterChange(e.target.value)}
                                    >
                                        <option value="">Select Primary Horizontal</option>
                                        {/* {gridHeader.map((group) => (
                                            <option key={group} value={group}>
                                                {group}
                                            </option>
                                        ))} */}
                                        {gridGroupLevel ? 
                                            <>
                                                {gridHeader.map((group, index) => (
                                                    index < gridGroupLevel && 
                                                    <option key={group} value={group}>
                                                        {group}
                                                    </option>
                                                ))}
                                            </> : <>
                                                {gridHeader.map((group, index) => (
                                                    <option key={group} value={group}>
                                                        {group}
                                                    </option>
                                                ))}
                                            </>
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </FormGroup>
                    }
                    {selectedGroups && selectedGroups.length > 0 && selectedGroups.map((group, index) => (
                        <FormGroup key={index}>
                            <Col sm='12'>
                                <Row>
                                    <Col sm='12' md='3'>
                                        <Row>
                                            <Label htmlFor={`selectGroupLabel${index}`}><span style={{ color: 'red'}}>* </span>Select Data Label</Label>
                                            <Input type="select" id={`selectGroupLabel${index}`} value={group.label} onChange={e => handleSelectChange(index, 'label', e.target.value)}>
                                            <option value="">Select Label</option>
                                            {gridHeader.length > 0 && gridHeader.map(group => (
                                                <option key={group} value={group}>
                                                    {group}
                                                </option>
                                            ))}
                                            </Input>
                                        </Row>
                                    </Col>
                                    <Col sm='12' md='3'>
                                        <Label htmlFor={`selectGroupData${index}`}><span style={{ color: 'red'}}>* </span>Select Primary Vertical</Label>
                                        <Input type="select" id={`selectGroupData${index}`} value={group.data} onChange={e => handleSelectChange(index, 'data', e.target.value)}>
                                            <option value="">Select Data</option>
                                            {/* {gridHeader.length > 0 && gridHeader.map(group => (
                                                <option key={group} value={group}>
                                                {group}
                                                </option>
                                            ))} */}
                                            {gridGroupLevel ? 
                                                <>
                                                    {gridHeader.map((group, index) => (
                                                        index >= gridGroupLevel && 
                                                        <option key={`${group}${index}`} value={group}>
                                                            {group}
                                                        </option>
                                                    ))}
                                                </> : <>
                                                    {gridHeader.map((group, index) => (
                                                        <option key={`${group}${index}`} value={group}>
                                                            {group}
                                                        </option>
                                                    ))}
                                                </>
                                            }
                                        </Input>
                                        <div className='cardControllerContainer' style={{ width: 111, right: -120 }}>
                                            <div  style={{ 
                                                backgroundColor: group.color, 
                                                border: 'none',
                                                width: 30,
                                                borderRadius: 5,
                                                cursor: 'pointer'
                                                }} color="primary" onClick={e => toggleColorPicker(index, true)}></div>
                                            {group.isOpen &&
                                                <div style={{ position: "absolute", zIndex: "99999" }}>
                                                    <div
                                                        key={index}
                                                        id={index}
                                                        style={{
                                                            position: "fixed",
                                                            top: "0px",
                                                            right: "0px",
                                                            bottom: "0px",
                                                            left: "0px",
                                                        }}
                                                        onClick={e => toggleColorPicker(index, false)}
                                                    />
                                                    <ChromePicker color={group.color} onChangeComplete={(newColor) => handleChangeComplete(newColor, index)} />
                                                </div>
                                            }
                                            {index === selectedGroups.length - 1 && (
                                                <div 
                                                    className='addNewCardlData'
                                                    onClick={handleAddFormGroup}
                                                ><i className="fas fa-solid fa-plus"></i></div>
                                            )}
                                            {selectedGroups.length > 1 && (
                                                <div className='removeCardlData'  onClick={() => handleRemoveFormGroup(index)}><i className="fas fa-solid fa-trash"></i></div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </FormGroup>
                    ))}
                    <FormGroup>
                        <Col sm='12'>
                        <Row>
                            <div 
                                className='addNewVisualData' 
                                style={{ background: '#088bf4', border: '1px solid #088bf4', color: '#fff', width: 100, marginTop: 10, marginRight: 10 }}
                                onClick={onHandleSaveVisual}
                            >Save View</div>
                            <div 
                                className='addNewVisualData' 
                                style={{ border: '1px solid #333', color: '#333', width: 100, marginTop: 10, marginRight: 0 }}
                                onClick={handleCloseEditVisual}
                            >Cancel</div>
                        </Row>
                        </Col>
                    </FormGroup>
                </>
            }
            

            <div className='templateVisualGraphHeaderContent'>
                {chartDataSet &&
                    <>
                        <div className='templateVisualGraphHeaderContainer'>
                        {gridGroupLevel ? 
                            <>
                                {chartHeader && chartHeader.header && chartHeader.header.map((item, index) => (
                                    <div 
                                        key={index}
                                        className='templateVisualGraphHeaderItem' 
                                        style={selectedGraphHeaderOption && selectedGraphHeaderOption.id === item.id ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                                        onClick={() => handleSelectGraphHeader(item)}
                                    >{item.label}</div>
                                ))}
                            </>
                            :
                            <>
                                {chartHeader && chartHeader.header && chartHeader.header.map((item, index) => (
                                    <div 
                                        key={index}
                                        className='templateVisualGraphHeaderItem' 
                                        style={selectedGraphHeaderOption === item ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                                        onClick={() => handleSelectGraphHeader(item)}
                                    >{item}</div>
                                ))}
                            </>
                        }
                        </div>
                        {selectedViewOption === 'Line Chart' &&
                            <Line
                                datasetIdKey='id'
                                data={chartDataSet}
                            />}
                        {selectedViewOption === 'Bar Chart' &&
                            <Bar
                                datasetIdKey='id'
                                data={chartDataSet}
                        />}
                    </>
                }
            </div>
        </>   
    )
}

export default VisualGraphView