
const FormatEVAResponse = async (currentMessage) => 
{
    const { text } = currentMessage;

    const fetchData = async () => {
        try {
            const [answer, observation, parsedData, integration, headers, treeViewData, isWithChild, version, stext] = parseDataString(text);

            const formattedResponse = {
                answer,
                observation,
                parsedData,
                integration,
                headers,
                treeViewData,
                isWithChild,
                version,
                stext
            };
            return formattedResponse
        } catch (error) {
            console.error("Error parsing data:", error);
        }
    };

    const parseDataString = (text) => {
        const jsonStringStr = text.lastIndexOf("```json");
        const jsonStringEnd = text.lastIndexOf("}");
        let jsonString = text.substring(jsonStringStr, jsonStringEnd + 1)
        const startIdx = jsonString.indexOf("{");
        const endIdx = jsonString.lastIndexOf("}");

        if (endIdx < 1) return []
        let validJsonString = jsonString.substring(startIdx, endIdx + 1)
        
        const jsonAnswer = get_answer(validJsonString)
        const jsonObservation = get_observation(validJsonString)
        let sqlResult = get_sqlResult(validJsonString)

        const jsonSQLResult = textParcer(sqlResult.sql_result)

        const integration = get_integration(validJsonString)
        const version = get_version(validJsonString)
        console.log(`EVA Version: ${version}`)
        const stext = get_stext(validJsonString)
        const parsedData = validateSQLResponse(jsonSQLResult)
        const headers = getHeader(jsonSQLResult)
        reFormatEVADateRange(headers[0], parsedData)

        if(parsedData && parsedData.length === 0) console.log(sqlResult.sql_result)
        let treeViewData = convertToTreeViewData(parsedData, headers[0] && headers[0].length)
        // treeViewData = SetTotalAmount(treeViewData, headers[0] && headers[0].length)

        const isWithChild = isWithChildHandler(treeViewData)
        // console.log({jsonAnswer, jsonObservation, parsedData, integration, headers : headers[0], treeViewData, isWithChild, version, stext})
        return [jsonAnswer, jsonObservation, parsedData, integration, headers[0], treeViewData, isWithChild, version, stext]
    }
    
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const get_version = (validJsonString) => {
        let startIdx = validJsonString.indexOf("version");
        const version = validJsonString.substring(startIdx, validJsonString.length)
        startIdx = version.indexOf(":");
        const endIdx = version.indexOf(",");
        const result = version.substring(startIdx + 4, endIdx - 2)
        return result
    }
    const get_stext = (validJsonString) => {
        // console.log(textParcer(validJsonString).replace(/\`nl`/g, ""))
        if (!validJsonString.includes("stext")) return null

        const str = textParcer(validJsonString).replace(/\`nl`/g, "")
        let startIdx = str.indexOf("stext:");
        const stext = str.substring(startIdx, str.length)
        // console.log(stext)
        startIdx = stext.indexOf(":");
        const endIdx = stext.indexOf("}");
        const result = stext.substring(startIdx + 1, endIdx)
        // console.log(result)
        return result.trim()
    }
    
    function get_sqlResult(validJsonString) {
        let startIdx = validJsonString.indexOf("sql_result");
        let sql_result_Str = validJsonString.substring(startIdx, validJsonString.length);

        const datetimeRegex = /datetime\.datetime\((\d+), (\d+), (\d+), (\d+), (\d+)\)/g;
        const dateRegex = /datetime\.date\((\d+), (\d+), (\d+)\)/g;
        const decimalRegex = /Decimal\('(-?\d+\.\d+)'\)/g;
        const decimalIntRegex = /Decimal\('(-?\d+)'\)/g;

        let sql_result = sql_result_Str.replace(datetimeRegex, (match, year, month, day, hour, minute) => {
            const date = new Date(year, month - 1, day);
            return `'${formatDate(date)}'`;
        });

        sql_result = sql_result.replace(dateRegex, (match, year, month, day) => {
            const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            return `'${formattedDate}'`;
        });
        
        sql_result = sql_result.replace(decimalRegex, (match, value) => {
            const decimalValue = parseFloat(value);
            return `'${decimalValue.toFixed(2)}'`;
        });

        sql_result = sql_result.replace(decimalIntRegex, (match, value) => {
            const decimalValue = parseFloat(value);
            console.log(decimalValue)
            return `'${decimalValue.toFixed(2)}'`;
        });
        
        startIdx = sql_result.indexOf("[");
        const endIdx = sql_result.lastIndexOf("]");
        return {sql_result: sql_result.substring(startIdx, endIdx + 1), sql_result_string: sql_result_Str};
    }

    function get_answer(validJsonString) {
        let startIdx = validJsonString.indexOf(`\\"answer\\"`);
        let endIdx = validJsonString.indexOf("observation");
        let answer = validJsonString.substring(startIdx + 14, endIdx-1)
        answer = textParcer(answer)
        return answer.replace(/\\",/g, '.').replace(/\."/g, '.').replace(/\"/g, "'");
    }
    function get_observation(validJsonString) {
        const sqlResultFirst = validJsonString.indexOf('sql_result\\') < validJsonString.indexOf('observation\\');
        let startIdx = validJsonString.indexOf("observation\\");
        let endIdx = sqlResultFirst ? validJsonString.lastIndexOf("}") : validJsonString.lastIndexOf("sql_result");
        let observation = validJsonString.substring(startIdx + 15, endIdx - 12)
            .replace(/"/g, "")
            .replace(/\\n/g, "")
            .replace(/"\\n/g, '\n');
        observation = textParcer(observation)
        return observation.replace(/"/g, "")
    }
    function get_integration(validJsonString) {
        let startIdx = validJsonString.indexOf("integration");
        let endIdx = validJsonString.indexOf("version");
        let answer = validJsonString.substring(startIdx + 15, endIdx - 9)
        answer = textParcer(answer)
        return answer.replace(/\."/g, '.').replace(/\"/g, "'")
        
        try {
            const dataObject = JSON.parse(validJsonString.replace(/'/g, '"'));
            const integrationValue = dataObject.integration;
            return integrationValue
        } catch (error) {
            let startIdx = validJsonString.lastIndexOf("integration': '");
            let jsonStr = validJsonString.substring(startIdx + 15, validJsonString.length)
            let endIdx = jsonStr.indexOf(",")
            jsonStr = jsonStr.substring(0, endIdx-1)
            let integration = jsonStr.substring(0, endIdx-1)
            integration = textParcer(integration)
            return integration
        }
    }
    function getHeader(validJsonString) {
        const data = []
        try {
            const sql_resultIdx = validJsonString.indexOf("sql_result");
            let sql_result = validJsonString.substring(sql_resultIdx, validJsonString.length)
            const startIdx = sql_result.indexOf("[");
            const endIdx = sql_result.lastIndexOf("]");

            let sqlResultData = sql_result.substring(startIdx, endIdx + 1)
            const objectRegex = /{[^{}]*}/g;
            const objects = sqlResultData.match(objectRegex);

            if (objects !== null && objects !== undefined)
                objects.map((objectString) => {
                    const parsedobjectString = JSON.parse(objectString)
                    const convertedData = Object.keys(parsedobjectString).map(value => (typeof value === 'string' ? value.replace(',', '') : value));
                    // console.log("convertedData", convertedData)
                    data.push(convertedData)

                })
        } catch (error) {
            // console.log("function: getChatHistoryList error!", error)
        }
        return data
    }
    function validateSQLResponse(validJsonString) {
        const data = []
        try {
            const sql_resultIdx = validJsonString.indexOf("sql_result");
            let sql_result = validJsonString.substring(sql_resultIdx, validJsonString.length)
            const startIdx = sql_result.indexOf("[");
            const endIdx = sql_result.lastIndexOf("]");

            let sqlResultData = sql_result.substring(startIdx, endIdx + 1)
            // console.log("sqlResultData: ", sqlResultData)

            const objectRegex = /{[^{}]*}/g;
            const objects = sqlResultData.match(objectRegex);
            // console.log("parsedData objects: ", objects)
            if (objects !== null && objects !== undefined)
                objects.map((objectString) => {
                    const parsedobjectString = JSON.parse(objectString)
                    const convertedData = Object.values(parsedobjectString).map(value => (typeof value === 'string' ? value.replace(',', '') : value));
                    // console.log("convertedData", convertedData)
                    data.push(convertedData)

                    const objString = objectString.substring(1, objectString.length - 1);
                    objString.split(', "').map((item) => {
                        item.split(":").map((i) => {

                            if (!i.includes("'") && !i.includes('"') && isNaN(parseFloat(i)))
                                if (validJsonString.includes(i.trim())) {
                                    validJsonString = validJsonString.replace(new RegExp(i, 'g'), '"' + i.trim() + '"');
                                }
                        })
                    })
                })
        } catch (error) {
            // console.log("function: getChatHistoryList error!")
        }

        return data
    }
    function textParcer(item) {
        return item
            .replace(/\\",/g, '')
            .replace(/\\t/g, "")
            .replace('"\n', "\n")
            .replace(/\\n/g, "`nl` ")
            // .replace(/\(/g, '[')
            // .replace(/\)/g, ']')
            .replace("   ", "")
            .replace(/\\'/g, '"')
            .replace(/\\"/g, '')
            .replace(/'/g, '"')
            .replace(/\\/g, '')
            .replace(/\\/g, '')
            .replace(/\: None/g, ': "None"')
            .replace(/\: Null/g, ': "None"')
            // .replace(/Decimal\(/g, '')
            .replace(/\),/g, ',')
            .replace(/\)}/g, '}');
    }

    return fetchData();
}
const isWithChildHandler = (treeViewData) => {
    // console.log(treeViewData)
    if (!Array.isArray(treeViewData)) {
        // Handle the case where treeViewData is not an array (e.g., return false)
        return false;
    }

    for (const item of treeViewData) {
        if (item.children?.length !== undefined && item.children.length > 2) {
            return true;
        }
    }
    return false;
}

const convertToTreeViewData = (tableData, columnCount = 0) => {
    const transformedData = {};

    // Iterate over the tableData array
    tableData.forEach(row => {
        const [label, clabel, ...values] = row;
        // console.log("label: ", label)
        // console.log("clabel: ", clabel)
        // console.log("values: ", values)
        // If the label doesn't exist in transformedData, create an empty array
        transformedData[label] = transformedData[label] || [];

        // Find if clabel already exists in the children array
        // const existingChild = transformedData[label].find(child => child.clabel[0] === clabel);

        // If found, push values to the existing child
        // if (existingChild) {
        //     existingChild.clabel.push(...values);
        // } else {
            // Otherwise, create a new child object and push it to the corresponding label array
            transformedData[label].push({ clabel: [clabel, ...values] });
        // }
    });

    // Convert transformedData into the desired format
    const outputData = Object.entries(transformedData).map(([label, children]) => ({
        label,
        children
    }));

    return outputData
    // console.log("tableData: ", tableData)
    // const treeViewData = [];

    // if(columnCount >= 4)
    // {
    //     tableData.forEach((rowData) => {

    //         const yearNode = treeViewData.find((node) => node.label === rowData[0]);

    //         if (yearNode) {
    //             const AccountNode = yearNode.children?.find((node) => node.label === rowData[1]);
    //             if (AccountNode) {
    //                 AccountNode.children?.push({ clabel: rowData.slice(2) });
    //             } else {
    //                 yearNode.children?.push({
    //                     label: rowData[1],
    //                     children: [{ clabel: rowData.slice(2) }],
    //                 });
    //             }
    //         } else {
    //             treeViewData.push({
    //                 label: rowData[0],
    //                 children: [
    //                     {
    //                         label: rowData[1],
    //                         children: [{ clabel: rowData.slice(2) }],
    //                     },
    //                 ],
    //             });
    //         }
    //     });
    // }
    // else
    // {
    //     tableData.forEach((rowData) => {
    //         const yearNode = treeViewData.find((node) => node.label === rowData[0]);
    //         if (yearNode) {
    //             yearNode.children?.push({
    //                 clabel: rowData.slice(1),
    //               });
    //         } else {
    //             treeViewData.push({
    //                 label: rowData[0],
    //                 children: [{ clabel: rowData.slice(1) }],
    //             });
    //         }
    //     });
    // }
    // console.log("treeViewData: ", treeViewData)
    // return treeViewData;
};


const NewEVAResponseTreeviewData = (GridData) => {
    if(!GridData) return 
    // console.log(GridData)
    const newData = []
    let isSubWithChild = false
    const groupedData = GridData.reduce((acc, item) => {
        const [parent, sub, ...rest] = item;
        if (!acc[parent]) {
          acc[parent] = {};
        }
        if (!acc[parent][sub]) {
          acc[parent][sub] = [];
        }
        
        // Find existing entry in newData
        let existingEntry = newData.find(entry => entry.parent === parent && entry.sub === sub);
        
        if (existingEntry) {
          // If the entry exists, add the new details to its details array
          isSubWithChild = true
          existingEntry.child.details.push(rest);
        } else {
          // If the entry doesn't exist, create a new one with details array
          existingEntry = { parent, sub, child: { details: [rest] } };
          newData.push(existingEntry);
        }
        
        // Add to accumulator
        acc[parent][sub].push(existingEntry);
        
        return acc;
    }, {});
    return {newData, isSubWithChild}
}

const generateUUID = async () => {
    let uuid = '';
    const characters = 'abcdef0123456789';
    for (let i = 0; i < 32; i++) {
        if (i === 8 || i === 12 || i === 16 || i === 20) {
            uuid += '-';
        } else {
            uuid += characters[Math.floor(Math.random() * characters.length)];
        }
    }
    return uuid;
}
const evaThemeLighter = (percent, theme, textColor) => {
    if(theme === undefined) return
    const newTheme = theme
    const rgbColor = hexToRgb(newTheme.PrimaryColor);

    const newColor = `rgb(${rgbColor.r.toFixed(0)}, ${rgbColor.g.toFixed(0)}, ${rgbColor.b.toFixed(0)}, ${percent / 100})`;
    const lightenedRgbColor = lightenColor(rgbColor, percent);
    return { backgroundColor: newColor, color: textColor ? textColor : newTheme.TextColor }
}
function hexToRgb(hex) {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
}
function lightenColor(color, percent) {
    const factor = percent / 100;
    const r = Math.min(255, color.r + 255 * factor);
    const g = Math.min(255, color.g + 255 * factor);
    const b = Math.min(255, color.b + 255 * factor);

    return { r, g, b };
}
const styleAlignRight = (value) => {
    const alignment = typeof value === 'string' ? !isNaN(Number(value.replace(/,/g, ''))) ? 'right' : 'left' : 'right';
    return { textAlign: alignment }
}
const reformatEVAColumnValue = (value) => {
    return isNaN(value) ? value : Number(value).toLocaleString()
}
const withHeaderFilter = (gridHeader, dataFilter, selectedHeader, sqlresult) => 
{
    if(selectedHeader === "All") return {header : gridHeader , data: convertToTreeViewData(sqlresult), newSQL: sqlresult}

    const toUpdate = sqlresult.map(row => row.slice());
    const headerToUpdate = gridHeader.map(row => row.slice());
    const unselectedDataFilter = dataFilter.filter((item) => item !== selectedHeader);

    // console.log(gridHeader)
    unselectedDataFilter.map((item) => 
    {
        const toRemoveIndex = gridHeader.indexOf(item);
        if(toRemoveIndex >= 0)
        {
            for (let i = 0; i < toUpdate.length; i++) {
                toUpdate[i].splice(toRemoveIndex, 2);
            }
            headerToUpdate.splice(toRemoveIndex, 2)
        }
    })
   

    return {header : headerToUpdate , data: convertToTreeViewData(toUpdate), newSQL: toUpdate};
};

const reFormatEVADateRange = async (headers, sqlResult) => 
    {
        if(headers === undefined) return

        // console.log(headers)
        const dataValue = []
        let withDateRange = []
        headers.forEach((item, index) => {
            if (typeof item === 'string')
                if(item.toLowerCase().includes('heading'))
                { 
                    withDateRange.push({i: index, column: item})
                }
        })

        withDateRange.forEach(({ i }) => {
            headers.splice(i + 1, 1, sqlResult[0][i]);
            dataValue.push(sqlResult[0][i])
        });

        const sortedIndices = withDateRange.map(item => item.i).sort((a, b) => b - a);
        sortedIndices.forEach(index => {
            headers.splice(index, 1);
        });

        sqlResult.forEach(row => {
            sortedIndices.forEach(index => {
                row.splice(index, 1);
            });
        });
        return {headers, sqlResult, dataValue}
    }

export { FormatEVAResponse, generateUUID, evaThemeLighter, convertToTreeViewData, isWithChildHandler, styleAlignRight, reformatEVAColumnValue, withHeaderFilter, reFormatEVADateRange, NewEVAResponseTreeviewData }