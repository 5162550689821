import React, { useContext, useEffect, useState } from "react";
import {
    Container,
    Row,
    Card,
    CardHeader,
    Col,
    CardBody,
} from "reactstrap";
import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";
import DashboardTabs from "components/CustomDashboardDetails/DashboardTabs";
import PersonalDashboard from "./PersonalDashboard";
import GroupDashboard from "./GroupDashboard";
import { checkEvaAccess } from "services/EVAService";
import CustomSpinner from "components/Misc/CustomSpinner";
import ReactBSAlert from "react-bootstrap-sweetalert";

function CustomDashboard() {
    const { bgColor, textColor, selectedClientID, userDetails, selectedClient, setIsTemplateEdit } = useContext(MainContext);
    const [tabKey, setKey] = useState('personal');
    // const [tabKey, setKey] = useState('group');
    
    const [evaIframeUrl, setEvaIframeUrl] = useState(null);
    const [noEvaAccess, setNoEvaAccess] = useState(false);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [editMode, setEditMode] = useState(false)
    const history = useHistory();
    const [showDashboardOption, setShowDashboardOption] = useState(false)
    const [selectedTemplateName, setSelectedTemplateName] = useState(null)
    const [showEVA, setShowEVA] = useState(false)
    const [showChat, setShowChat] = useState(false)
    const [stillLoading, setStillLoading] = useState(true);
    const [isChangingClient, setIsChangingClient] = useState(0)
    const [isDimensionChange, setIsDimensionChange] = useState(false)
    const [alert, setAlert] = useState(false);

    const handleTabSelect = (value) => {
        setKey(value)
    }

    useEffect(() => {
        // console.log(userDetails)
        if (Object.keys(userDetails).length === 0) return;
        checkEvaAccess(selectedClientID, userDetails, handleSetEvaIframeUrl, handleSetNoEvaAccess)
        // console.log('selectedClient', selectedClient)
    }, [selectedClientID, userDetails, selectedClient])
    useEffect(() => {
        setEditMode(false)
        setShowEVA(false)
        setShowChat(false)
        setIsChangingClient(prev => prev + 1)
    },[selectedClientID])
    
    const handleSetEvaIframeUrl = (value) => {
        // console.log(value)
        setEvaIframeUrl(value)
    }
    const handleSetNoEvaAccess = (value) => {
        setNoEvaAccess(value)
    }
    const toggleEditMode = () => 
    {
        if(isDimensionChange && editMode) showWarning()
        else setEditMode(!editMode)
        
        setShowEVA(false)
        setShowChat(false)
    }

    const toggleTemplateModal = () => {
        setIsTemplateEdit(false)
        sessionStorage.setItem("selectedTemplatetoEdit", null);
        // setShowTemplateModal(!showTemplateModal)
        return history.push(`/client/templates`);
    }
    const toggleShowDashboardOption = () => {
        setShowDashboardOption(!showDashboardOption)
    }
    const handleSelectTemplate = (value) => {
        // setShowDashboardOption(!showDashboardOption)
        // console.log(value)
        setSelectedTemplateName(value)
    }
    const handleShowEVA = () => {
        setShowEVA(!showEVA)
        setShowChat(false)
    }
    const handleShowChat = () => {
        setShowChat(!showChat)
    }
    const handleStillLoading = (val) => {
        setStillLoading(val)
    }
    const handleIsDimensionChange = (val) => {
        setIsDimensionChange(val)
    }
    const showWarning = () => 
    {
        setAlert(
            <ReactBSAlert
                warning
                title="Warning"
                style={{ display: "block", marginTop: "-100px" }}
                onConfirm={() => {
                    setEditMode(false)
                    setAlert(null)
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="warning"
                confirmBtnText="Yes"
                showCancel={true}
                btnSize=""
            >
                <p>
                    Are you sure you want to close edit mode with unsaved changes?
                </p>
            </ReactBSAlert>
        );
    };

    const handleRedirect = () => {
        history.push("/client/lists")
    }
    return (
        <>
            <div className="mt-6 fadeIn mainDashboard" style={{ height: windowHeight - 72 }}>
                <div className="nav-wrapper customDashboard">
                    {
                        !selectedClient ? <CustomSpinner /> :
                            !selectedClient?.Details?.ShowEvaPage ?
                                <Container className="mt-0" fluid>
                                    <div className="nav-wrapper">
                                        <Card>
                                            <Row>
                                                <Col className="text-center p-5">
                                                    {
                                                        userDetails?.User?.UserAccess >= 5 ?
                                                            <span>Dashboard is not yet enabled for this Client. Please speak to your admin about turning this feature on.</span>
                                                            :
                                                            <span>Dashboard is not yet enabled for this Client. Please click <strong style={{ color: '#5e72e4', cursor: 'pointer', fontWeight: '600' }} onClick={() => handleRedirect()}>here</strong> to go to clients and enable EVA.</span>
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </Container>
                                :
                                <DashboardTabs
                                    tabKey={tabKey}
                                    handleTabSelect={handleTabSelect}
                                    toggleEditMode={toggleEditMode}
                                    editMode={editMode}
                                    toggleTemplateModal={toggleTemplateModal}
                                    toggleShowDashboardOption={toggleShowDashboardOption}
                                    selectedTemplateName={selectedTemplateName}
                                    handleShowEVA={handleShowEVA}
                                    showEVA={showEVA}
                                    handleShowChat={handleShowChat}
                                    showChat={showChat}
                                    stillLoading={stillLoading}
                                    userDetails={userDetails}
                                >
                                    {alert}
                                    {Object.keys(userDetails).length > 0 && selectedClientID ?
                                        <>
                                            {tabKey === "personal" &&

                                                <PersonalDashboard
                                                    evaIframeUrl={evaIframeUrl}
                                                    noEvaAccess={noEvaAccess}
                                                    editMode={editMode}
                                                    showDashboardOption={showDashboardOption}
                                                    onToggleShowDashboardOption={toggleShowDashboardOption}
                                                    onHandleSelectTemplate={handleSelectTemplate}
                                                    showEVA={showEVA}
                                                    showChat={showChat}
                                                    handleStillLoading={handleStillLoading}
                                                    isChangingClient={isChangingClient}
                                                    handleIsDimensionChange={handleIsDimensionChange}
                                                />
                                            }
                                            {tabKey === "group" &&
                                                <GroupDashboard
                                                    evaIframeUrl={evaIframeUrl}
                                                    noEvaAccess={noEvaAccess}
                                                    editMode={editMode}
                                                    showEVA={showEVA}
                                                    showChat={showChat}
                                                    showDashboardOption={showDashboardOption}
                                                />
                                            }
                                        </>
                                        :
                                        <>
                                            <CustomSpinner />
                                        </>
                                    }
                                </DashboardTabs>
                    }
                </div>
            </div>


        </>
    );
}

export default CustomDashboard;
