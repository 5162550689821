import React, { useCallback, useEffect, useState } from 'react'
import { evaThemeLighter, withHeaderFilter } from '../../services/EVAResponseService';
import { TransformSQLResult, PopulateNewRows } from 'services/EVAEditGridService';
import classNames from 'classnames';
import { ProcessDnDTreeviewData } from 'services/EVAEditGridService';
import { TableHeader } from 'services/EVAEditGridService';
import { NewParseFormula } from 'services/EVAEditGridService';
import { ReplaceCellIdsWithValues } from 'services/EVAEditGridService';
import { EvaluateFormula } from 'services/EVAEditGridService';
import { RecalculateWithTotal } from 'services/EVAEditGridService';
import { ProcessRenamingSQLData } from 'services/EVAEditGridService';
import { ProcessRemoveRow } from 'services/EVAEditGridService';
import { GetCellIdValue } from 'services/EVAEditGridService';
import { toLength } from 'lodash';
import { ProcessGrandTotal } from 'services/EVAEditGridService';
import { ProcessGrandParentRow } from 'services/EVAEditGridService';

export default function TreeView({ item, theme, sqlresult, gridHeader, treeViewData, toggleAll, isFromPnL }) {

  const [subParentVisibility, setSubParentVisibility] = useState({});
  const [childVisibility, setChildVisibility] = useState({});
  const [dataHeader, setDataHeader] = useState([])
  const [treeData, setTreeData] = useState([])
  const [newTreeData, setNewTreeData] = useState([])
  const [headerFilter, setHeaderFilter] = useState([])
  const [newSQL, setNewSQL] = useState([])
  const [activeHeaderIndex, setActiveHeaderIndex] = useState(0)
  const [editedGridCellData, setEditedGridCellData] = useState(null)
  const [gridGroupLevel, setGridGroupLevel] = useState(null)
  const [newRowList, setNewRowList] = useState([])
  const [dragAndDropData, setDragAndDropData] = useState([])
  const [cellFormulaList, setCellFormulaList] = useState([])
  const [renamedSQLData, setRenamedSQLData] = useState([])
  const [hiddenRow, setHiddenRow] = useState([])

  const toggleVisibility = (label, setState) => {

    setState((prevVisibility) => ({
      ...prevVisibility,
      [label]: !prevVisibility[label]
    }));
  };

  const toggleSubParentVisibility = (label) => {
    toggleVisibility(label, setSubParentVisibility);
  };

  useEffect(() => {
    // console.log(subParentVisibility)
  },[subParentVisibility])

  // const toggleChildVisibility = (label) => {
  //   toggleVisibility(label, setChildVisibility);
  // };

  function getIsAmount(cl) 
  {
    // Check if cl is a valid value and convert it to a string
    const newcl = cl ? JSON.stringify(cl).replace(/,/g, "").replace(/\$/g, "") : "";
  
    if (cl === "Total Amount") {
      return "isTotalAmount";
    }
  
    // Parse newcl as a number
    const newNumber = parseFloat(newcl);
  
    // Check if newNumber is a valid number
    if (!Number.isNaN(newNumber)) {
      return "isAmount";
    }
  
    return "";
  }
  

  // const toggleAllVisibility = (expand) => {

  //   // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

  //   setSubParentVisibility((prevVisibility) => {
  //     const updatedVisibility = {};
  //     for (const key in prevVisibility) {
  //       updatedVisibility[key] = expand;
  //     }
  //     return updatedVisibility;
  //   });

  //   // setToggleAll(expand)
  // };

  useEffect(() => {
    setDataHeader(gridHeader)
    if(item && item.editedGridCellData)
    {
     
      // console.log(item.editedGridCellData)
      setGridGroupLevel(item.editedGridCellData.gridGroupLevel)
      // setNewRowList(item.editedGridCellData.newRowList)
      // setDragAndDropData(item.editedGridCellData.dragAndDropData)
      // setCellFormulaList(item.editedGridCellData.cellFormulaList)
      // setRenamedSQLData(item.renamedSQLData)
      // setHiddenRow(item.hiddenRow)
      setNewTreeData(treeViewData)
    } else {
      setTreeData(treeViewData)
      setNewSQL(sqlresult)
    }

  },[sqlresult])

  // useEffect(() => {
    // if(editedGridCellData) {
    //   const { gridGroupLevel, newRowList, dragAndDropData, cellFormulaList, renamedSQLData, hiddenRow } = editedGridCellData

    //   let treeData = []
    //   treeData = TransformSQLResult(sqlresult, gridHeader, gridGroupLevel)
    //   treeData = PopulateNewRows(newRowList, sqlresult, gridHeader, gridGroupLevel)
    //   treeData = ProcessRemoveRow(treeData, hiddenRow)

    //   if(dragAndDropData.length > 0) 
    //   {
    //     treeData = ProcessDnDTreeviewData(dragAndDropData, treeData)
    //     const isWithGroup = dragAndDropData.filter(g => g.parentId && g.parentId.includes("-group-"))
    //     if(isWithGroup.length > 0)
    //     {
    //       treeData = RecalculateWithTotal(treeData)
    //     }
    //   }

    //   let cellValue = GetCellIdValue(treeData)
    //   treeData = processGroupWithTotal(treeData, cellValue)

    //   if(cellFormulaList) {
    //     cellValue = GetCellIdValue(treeData)
    //     updateCellWithFormula(treeData, cellValue, cellFormulaList)
    //   }

    //   const processRenaming = (treeData) => {
    //     if (renamedSQLData && renamedSQLData.length > 0) {
    //         return ProcessRenamingSQLData(renamedSQLData, treeData);
    //     }
    //     return treeData;
    //   };


    //   treeData = ProcessGrandTotal(treeData, cellValue, newRowList, gridHeader, gridGroupLevel, cellFormulaList)
    //   cellValue = GetCellIdValue(treeData)
      
    //   treeData = ProcessGrandParentRow(newRowList, treeData)
    //   treeData = processRenaming(treeData);

    //   // console.log(treeData)
    //   setNewTreeData(treeData)
    // }
  // }, [editedGridCellData])

  useEffect(() => {

    function getInitialParentSubGroup(newTreeData) {
      const result = {}; // Initialize an empty object to store the key-value pairs
    
      function traverseTree(data) {
        for (let i = 0; i < data.length; i++) {
          const currentItem = data[i];
          const currentId = currentItem?.id;
    
          // Ensure the id is defined before calling includes
          if (
            (currentItem.children && currentItem.children.length > 0) || 
            gridGroupLevel === 1 || 
            currentId?.includes("-parent-") || 
            currentId?.includes("-child-") || 
            currentId?.includes("-sub-") || 
            currentId?.includes("-TotalRow")
          ) {
            result[currentId] = !toggleAll; // Add the name as a key with a value of false
          }
    
          if (currentItem.children && currentItem.children.length > 0) {
            traverseTree(currentItem.children); // Recursively traverse children
          }
        }
      }
    
      traverseTree(newTreeData);
      return result;
    }
    

    if(newTreeData.length > 0)
    {
      const initialState = getInitialParentSubGroup(newTreeData);
      // console.log(initialState)
      setSubParentVisibility(initialState)
    }

  }, [newTreeData])



  useEffect(() => {
    const initialState = treeViewData.reduce((acc, parent) => {
      acc[parent.label] = toggleAll;
      return acc;
    }, {});

    setSubParentVisibility(initialState)
  }, [gridHeader]);

  const processGroupWithTotal = (newTreeData, cellIdValue) => {
    loopThruItems(newTreeData)
    function loopThruItems(newTreeData)
    {
        for(let i = 0; i < newTreeData.length; i++)
        {
            if(newTreeData[i].children)
            {
                const subTotal = calculateSubTotal({ children: newTreeData[i].children }, newTreeData[i].children);
                const newChild = {
                    "id": `${newTreeData[i].id}-TotalRow`,
                    "sqlId": `${newTreeData[i].sqlId}-TotalRow`,
                    "name": `Total ${newTreeData[i].name}`,
                    "value": subTotal
                }

                const index = newTreeData[i].children ? newTreeData[i].children.findIndex(d => d.id === newChild.id) : -1
                if(index !== -1)
                {
                    newTreeData[i].children.splice(index, 1)
                }
                newTreeData[i].children.push(newChild)
                loopThruItems(newTreeData[i].children)
            } 
            // else {
            //     if (newTreeData[i].value) {
            //         const subTotal = calculateSubTotal(newTreeData[i] , newTreeData[i].children);
            //         toSum.push(subTotal)
            //     }
            // }
        }
    }

    return newTreeData
 
    function calculateSubTotal(node, newTreeData) {
        // Determine the length of the values array from the first item in newTreeData
        let length = gridHeader.length - gridGroupLevel
        // Initialize subtotal and containsString arrays
        let subtotal = Array(length).fill(0);
        let containsString = Array(length).fill(false);
      
     
        // Check if the node should be skipped
        if (node.id && node.id?.includes("groupTotal")) {
          // Skip this node and return the initial subtotal
          return subtotal;
        }

     
        // Base case: If the node has no children, process its values
        if (!node.children || node.children.length === 0) {
          if (node.value) {
            // Map values, converting to numbers or "-" if non-numeric
            // console.log(node)
            return node.value.map((v, i) => {
                // console.log(node.id, v, i)
                const cellId = `${TableHeader(i)}${node.id.split("-")[0]}`
                const formulaData = cellFormulaList.find(f => f.rowId === node.id && f.cellId === cellId )
                const cellData = cellIdValue.find(i => i.id === node.id && i.cellId === cellId)
                const amount = formulaData ? formulaData.newValue : cellData ? cellData.cellValue : 0
                return (isNaN(amount) ? "-" : parseFloat(amount) || 0)
            });
          }
          return subtotal; // Return initial subtotal if no values are found
        }
      
        // Recursive case: Traverse each child node
        for (const child of node.children) {
          const childTotal = calculateSubTotal(child, newTreeData);
      
          // Accumulate child totals into subtotal
          for (let i = 0; i < subtotal.length; i++) {
            if (isNaN(childTotal[i])) {
              containsString[i] = true; // Mark if any child total is non-numeric
            } else {
              subtotal[i] += parseFloat(childTotal[i]) || 0; // Accumulate numeric values
            }
          }
        }
      
        // Return the final subtotal, replacing numeric values with "-" where needed
        return subtotal.map((total, index) => (containsString[index] ? "-" : total));
    }
  }
  // useEffect(() => {
  //   setSubParentVisibility((prevVisibility) => {
  //     const updatedVisibility = {};
  //     for (const key in prevVisibility) {
  //       updatedVisibility[key] = toggleAll;
  //     }
  //     return updatedVisibility;

  //   });
  // }, [toggleAll])

  const backgroundColorStyleLighttd = evaThemeLighter(10, theme, '#333')
  const backgroundColorStyleLight = evaThemeLighter(90, theme)

  const SetGrandTotal = () => {
    const percentIndexes = dataHeader.reduce((indexes, item, index) => {
      if (item?.includes('%')) {
          indexes.push(index);
      }
      return indexes;
    }, []);

    percentIndexes.forEach(index => {
      newSQL.forEach(child => {
        const item = String(child[index - 1])
        if(!item?.includes('%'))
        {
          let value = parseFloat(child[index]);
          if (!isNaN(value)) {
              // Divide by 100 and format to two decimal places, then append '%'
              const formattedValue = (value / 100).toFixed(2) + ' %';
              child[index] = formattedValue;
          }
        }
      });
    }); 
    
    const newRow = ['Grand Total']
    for (const column in newSQL[0]) {
      if (column > (dataHeader.length >= 4 ? 1 : 0)) {
        const columnValue = isNaN(newSQL[0][column]) ? newSQL[0][column].replace(/,/g, '') : newSQL[0][column];
        if (!isNaN(columnValue)) {
          const total = newSQL.reduce((acc, row) => {
            const value = typeof row[column] === 'string' ? parseFloat(row[column].replace(/,/g, '')) : row[column];
            return acc + (value || 0);
          }, 0);
          newRow.push(total.toFixed(2))
        } else newRow.push("-")
      }

    }

    return (
      <>
        <tr className='grandTotal' style={{ ...evaThemeLighter(120, theme), fontSize: '15px' }}>
          {newRow.map((item, index) => (
            <td
              colSpan={dataHeader.length >= 4 && index === 0 ? 2 : 1}
              style={{ textAlign: !isNaN(Number(item.replace(/,/g, ''))) ? 'right' : item?.includes('%') ? 'right' : 'left' }}
              key={index}>
              {isNaN(Number(item.replace(/,/g, ''))) ? item : Number(item.replace(/,/g, '')).toLocaleString()}
            </td>
          ))
          }
        </tr >
      </>
    )
  }

  const styleAlignRight = (value) => {
    const alignment = typeof value === 'string' ? !isNaN(Number(value.replace(/,/g, ''))) ? 'right' : value?.includes('%') ? 'right' : 'left' : 'right';
    return { textAlign: alignment }
  }

  const TreeViewData = ({parent, rowIndex}) => {
    const colCount = dataHeader.length
    const parentLabel = parent.label
    const children = parent.children
    const childrenCount = parent.children.length

    const newData = [];
    const newHeader = [` ▲ ${parentLabel}`];
    const newFooter = [`Total`];

    const percentIndexes = dataHeader.reduce((indexes, item, index) => {
      if (item?.includes('%')) {
          indexes.push(index);
      }
      return indexes;
    }, []);

    percentIndexes.forEach(index => {
      children.forEach(child => {
        const item = String(child.clabel[index - 1]);
        if(!item?.includes('%'))
        {
          const formattedValue = `${(child.clabel[index - 1] / 100).toFixed(2)}%`;
          child.clabel[index - 1] = formattedValue.replace(/-/g, '')
        }
      });
    });

    if (childrenCount > 1) {
      
      children.forEach((item) => {
          newData.push(item.clabel);
      });

      Object.keys(newData[0]).forEach((column) => {
        const columnValue = isNaN(newData[0][column]) ? newData[0][column].replace(/,/g, '') : newData[0][column];
        if (!isNaN(columnValue)) {
          const total = newData.reduce((acc, row) => {
            const value = typeof row[column] === 'string' ? parseFloat(row[column].replace(/,/g, '')) : row[column];
            return acc + (value || 0);
          }, 0);
          newHeader.push(total.toFixed(2));
          newFooter.push(total.toFixed(2));
        } else {
          newHeader.push("-");
          newFooter.push("-");
        }
      });
    }

    const renderHeader = () => {
      return (
        <>
        <tr key={rowIndex} className='parent' onClick={() => toggleSubParentVisibility(parentLabel)}>
          {!subParentVisibility[parentLabel] ? (
            <>
              <td colSpan={2} style={{ zIndex: 11, ...backgroundColorStyleLight}}>
                {' ▼'} {parentLabel}
              </td>
              <td colSpan={colCount-2} style={backgroundColorStyleLight}>
            
              </td>
            </>
          ) : (
            newHeader.map((item, index) => (
              <td className={`tdClass ${getIsAmount(item)}`} key={index} style={{ ...styleAlignRight(item), ...(rowIndex % 2 === 0 ? backgroundColorStyleLighttd : {}) }}>
                {isNaN(item) ? item : Number(item).toLocaleString()}
              </td>
            ))
          )}
        </tr>
        {renderChildren()}
        </>
      );
    };

    const renderChildren = () => {
      return (
        !subParentVisibility[parentLabel] && (
          <>
            {children.map((item, subparentIndex) => (
              <tr className='treeVierRow' key={subparentIndex} style={subparentIndex % 2 === 0 ? backgroundColorStyleLighttd : {}}>
                <td></td>
                {item.clabel && item.clabel.map((child, childIndex) => getRowData(child, childIndex))}
              </tr>
            ))}
            <tr style={evaThemeLighter(60, theme)}>
              {newFooter.map((item, index) => getRowData(item, index))}
            </tr>
          </>
        )
      );
    };
    
    const renderSingleChild = () => {
      return (
        <tr>
          <td>{parentLabel}</td>
          {children && children.map((item) => (
            item.clabel && item.clabel.map((child, childIndex) => getRowData(child, childIndex))
          ))}
        </tr>
      );
    };

    return (
      childrenCount > 1 ? renderHeader() : renderSingleChild()
    );
  }

  const getRowData = (child,childIndex) => {
    return (
      <td className={`tdClass ${getIsAmount(child)}`} key={childIndex} style={styleAlignRight(child)}>
        {isNaN(child) ? child : Number(child).toLocaleString()}
      </td>
    )
  }

  const getNewRowData = (child, childIndex, id) => {
    const cellId = id ? `${TableHeader(childIndex)}${id.split("-")[0]}` : '';

    const withFormula = cellFormulaList.filter(item => item.rowId === id);
    const formulaDetails = withFormula.find(f => f.cellId === cellId);
    const isGrandParent = id === "grand-total" || id?.includes("-grandParent-");

    return (
        <td
            className={`tdClass ${getIsAmount(child)} ${cellId}`} 
            key={childIndex}
            style={{
                ...styleAlignRight(child), // Align styles
                ...(isGrandParent && id === "grand-total" 
                    ? backgroundColorStyleLight
                    : {}// Apply conditional background color
                )
            }}
        >
            {isNaN(child) ? child : Number(child).toLocaleString()}
        </td>
    );
};

  
  const updateCellWithFormula = (treeData, cellValue, cellFormulaList) => {

    // console.log(treeData)
    // console.log(cellFormulaList)
    // console.log(cellValue)
    loopThruItems(treeData)
    // treeData && treeData.map((row) => {
    //   if(row.value) {
    //     const withFormula = cellFormulaList.filter(item => item.rowId === row.id)
    //     if(withFormula.length > 0)
    //         withFormula.length > 0 && row.value && row.value.map((val, index) => {
    //         const newVal = processNewCellValue(row, index, withFormula, cellValue, val)
    //         row.value[index] = newVal
    //     })
    //   }

    //   row.children && row.children.map((sub) => {
    //     if(sub.value) {
    //       const withFormula = cellFormulaList.filter(item => item.rowId === sub.id)
    //       if(withFormula.length > 0)
    //         withFormula.length > 0 && sub.value && sub.value.map((val, index) => {
    //           const newVal = processNewCellValue(sub, index, withFormula, cellValue, val)
    //           sub.value[index] = newVal
    //         })
    //     }

    //     sub.children && sub.children.map((child) => {
    //       if(child.value) {
    //         const withFormula = cellFormulaList.filter(item => item.rowId === child.id)
    //         if(withFormula.length > 0)
    //           withFormula.length > 0 && child.value && child.value.map((val, index) => {
    //             const newVal = processNewCellValue(child, index, withFormula, cellValue, val)
    //             child.value[index] = newVal
    //           })
    //       }

    //       child.children && child.children.map((group) => {
    //         if(group.value) {
    //           const withFormula = cellFormulaList.filter(item => item.rowId === group.id)
    //           if(withFormula.length > 0)
    //             withFormula.length > 0 && group.value && group.value.map((val, index) => {
    //               const newVal = processNewCellValue(group, index, withFormula, cellValue, val)
    //               group.value[index] = newVal
    //             })
    //         }
    //       })

    //     })
    //   })
    // })

    function loopThruItems(treeData) 
    {
      for(let i = 0; i < treeData.length; i++)
      {
        const row = treeData[i]
        // console.log(row)
        const sqlId = row.sqlId
        
        const isRowWithFormula = cellFormulaList.filter(i => i.sqlId === sqlId)
        const rowCellValue = cellValue.find(i => i.sqlId === sqlId)

        if(isRowWithFormula.length > 0) {

          // console.log(row)
          // console.log(rowCellValue)
          // console.log(isRowWithFormula)

          if(row.value) {
            isRowWithFormula.length > 0 && row.value && row.value.map((val, index) => {
              const newVal = processNewCellValue(row, index, isRowWithFormula, cellValue, val)
              row.value[index] = newVal
            })
            // console.log(row)
          }
        }

        if(row.children) loopThruItems(row.children)
      }
    }

  }
  const processNewCellValue = (group, index, withFormula, cellValue, val) => {
    const rowIndex = group.id.split("-")[0]
    const cellId = `${TableHeader(index)}${rowIndex}`
    const formulaDetails = withFormula.find((f => f.cellId === cellId))
    if(formulaDetails) 
    {
      const newRes = processCellFormula(formulaDetails.formula, cellValue, cellId)
      // console.log(newRes)
      return newRes
    }
    return val
  }
  const processCellFormula = (formula, cellValue, cellId) => {
    const parsedFormula = NewParseFormula(formula)
    // console.log(parsedFormula)
    if(!parsedFormula) return formula

    const replacedFormula = ReplaceCellIdsWithValues(parsedFormula, cellFormulaList, cellValue, cellId)
    // console.log(replacedFormula)
    const finalResult = EvaluateFormula(replacedFormula);
    // console.log(finalResult)
    return finalResult
  }
  const ShowGroup1Total = ({newTreeData}) => {
    const subTotal = calculateSubTotal({ children: newTreeData }, newTreeData);

    function calculateSubTotal(node, newTreeData) {

      // Determine the length of the values array from the first item in newTreeData
      let length = newTreeData[0].value ? newTreeData[0].value.length : newTreeData[0].children[0].value.length;
      // Initialize subtotal and containsString arrays
      let subtotal = Array(length).fill(0);
      let containsString = Array(length).fill(false);
    
    
      // Check if the node should be skipped
      if (node.id && node.id?.includes("groupTotal")) {
        // Skip this node and return the initial subtotal
        return subtotal;
      }
    
      // Base case: If the node has no children, process its values
      if (!node.children || node.children.length === 0) {
        if (node.value) {
          // Map values, converting to numbers or "-" if non-numeric
          return node.value.map(v => (isNaN(v) ? "-" : parseFloat(v) || 0));
        }
        return subtotal; // Return initial subtotal if no values are found
      }
    
      // Recursive case: Traverse each child node
      for (const child of node.children) {
        const childTotal = calculateSubTotal(child, newTreeData);
    
        // Accumulate child totals into subtotal
        for (let i = 0; i < subtotal.length; i++) {
          if (isNaN(childTotal[i])) {
            containsString[i] = true; // Mark if any child total is non-numeric
          } else {
            subtotal[i] += parseFloat(childTotal[i]) || 0; // Accumulate numeric values
          }
        }
      }
    
      // Return the final subtotal, replacing numeric values with "-" where needed
      return subtotal.map((total, index) => (containsString[index] ? "-" : total));
    }
    

    return (
      <tr className='totalRow'>
        <td className='rowHeader'>Total</td>
        {subTotal && subTotal.map((item, index) => (
          <>
            {getRowData(item, index)}
          </>
        ))}
      </tr>
    )
  }
  const NewTreeView = ({ row, newTreeData, gridHeader, gridGroupLevel , dataHeader, getRowData, toggleSubParentVisibility, subParentVisibility}) => {

    // if(row.sqlId === "3cd2-643c-85e7") 
    // console.log(row)
    const renderRow = (name, value, indentLevel, id, parentId, withTotalRow, gridHeader, gridGroupLevel) => {
      // if(parentId === "167-group-Demo Company (AU)-Expenses-New Row 167") console.log(name)
      const isGrandParent = id?.includes("-grandParent-") || false;
      const isGrandTotal = id === "grand-total";
      const isTotalRow = value && (id?.includes("-parent-") || id?.includes("-sub-"));
      
      return (
        <tr 
          className={`
            ${parentId && parentId.split("-")[1] === 'group' ? 'isGroupRow' : ''}
            ${isGrandParent ? 'grandParentRow' : ''}
            ${isGrandTotal ? 'isGrandTotalRow' : ''}
            ${id && id?.includes("-TotalRow") ? 'groupTotalRow': ''}
            ${isTotalRow ? 'totalRow' : ''}
          `}
          style={{
            // ...(isGrandParent ? evaThemeLighter(40, theme) : {}),
            ...(isGrandTotal ? evaThemeLighter(100, theme) : {})}}
        >
          {[...Array(Math.max(0, parentId && parentId.split("-")[1] === 'group' ? indentLevel - 1 : indentLevel))].map((_, i) => (
            <td 
              key={`indent-${i}`}
              style={{
                ...((indentLevel === 1 && !value) || isGrandParent ? evaThemeLighter(70, theme) : {}),
              }}
              ></td>
          ))}
    
          {renderHeader(name, value, indentLevel, id, parentId, gridHeader, gridGroupLevel)}  
    
          {value ? value.map((item, index) => (
            <React.Fragment key={`value-${index}`}>
              {getRowData(item, index, id, indentLevel, gridGroupLevel)}
            </React.Fragment>
          )) : (
            <td 
              style={{
                ...(indentLevel === 0 ? backgroundColorStyleLight : {}),
                ...(indentLevel === 1 ? evaThemeLighter(70, theme) : {}),
                // ...(indentLevel === 2 && !value ? evaThemeLighter(60, theme) : {}),
              }}
              onClick={() => toggleSubParentVisibility(id)}
              colSpan={dataHeader.length - indentLevel - 1}></td>
          )}
        </tr>
      );
    }
  
    const renderRowWithSubTotal = (row, newTreeData, gridGroupLevel, name, value, indentLevel, id, parentId, withTotalRow) => {
      
      // console.log(row)
      if(!row.children) return 

      const childrenWithTotalRow = row.children && Array.isArray(row.children) && row.children.find(i => i?.id && i.id?.includes("-TotalRow"));

      const subTotal = childrenWithTotalRow ? childrenWithTotalRow.value : calculateSubTotal(row, newTreeData, gridGroupLevel);
      const colspan = (dataHeader.length - indentLevel - 1) - subTotal.length

      return (
        <tr 
          className={`
            totalRow 
            ${parentId && parentId.split("-")[1] === 'group' ? 'isGroupRow' : ''}
          `}
        >
          {[...Array(Math.max(0, parentId && parentId.split("-")[1] === 'group' ? indentLevel - 1 : indentLevel))].map((_, i) => (
            <td key={`indent-${i}`}></td>
          ))}
    
          <td 
            className={`
              rowHeader
              ${id && id.split("-")[1] === 'group' ? 'isGroupTD' : ''} 
              ${parentId && parentId.split("-")[1] === 'group' ? 'isParentGroupTD' : ''}
            `}
            style={{ fontWeight: !value ? 'bold' : 'unset', 
              // borderLeft: !value ? '1px solid #dadada': ''
            }}
            onClick={() => { 
              toggleSubParentVisibility(id)
            }}
            colSpan={subParentVisibility?.[id] ? gridGroupLevel : gridGroupLevel - indentLevel}
          >
            {!value && (subParentVisibility?.[id] ? ' ▼':' ▲')} {name}
          </td>
          
          {subParentVisibility?.[id] && colspan > 0 && <td colSpan={colspan}></td>}
          
          {subTotal && subTotal.map((item, index) => (
            <React.Fragment key={`subtotal-${index}`}>
              {getRowData(item, index, row.id)}
            </React.Fragment>
          ))}
        </tr>
      );
    }
    
    const renderHeader = (name, value, indentLevel, id, parentId, gridHeader) => {
      let colspan = 1
      if(id === "grand-total" || id?.includes("-grandParent-")) 
      {
        return (
          <td 
            className={`
              rowHeader
              ${id && id.split("-")[1] === 'group' ? 'isGroupTD' : ''} 
              ${parentId && parentId.split("-")[1] === 'group' ? 'isParentGroupTD' : ''}
            `}
            style={{ fontWeight: !value ? 'bold' : 'unset', 
              ...(id === "grand-total" ? backgroundColorStyleLight : {})
              // borderLeft: !value ? '1px solid #dadada': ''
            }}
            // onClick={() => toggleSubParentVisibility(id)}
            colSpan={gridGroupLevel}
          >
            {!value && (subParentVisibility?.[id] ? ' ▼':' ▲')} {name}
          </td>
        )
      } 



      const isParentGroup = parentId && parentId.split("-")[1] === 'group'
      const isGroupChild = id && id.split("-")[1] === 'group'

      if(value && gridGroupLevel === 3 && indentLevel === 0) colspan = 3
      if(value && gridGroupLevel === 3 && indentLevel === 1) colspan = 2
      if(value && gridGroupLevel === 3 && indentLevel === 2 && isParentGroup) colspan = 2
      if(value && gridGroupLevel === 3 && indentLevel === 1 && isParentGroup) colspan = 3
      if(value && gridGroupLevel === 3 && indentLevel === 2 && isGroupChild) colspan = 2

      if(value && gridGroupLevel === 2 && indentLevel === 0) colspan = 2
      if(value && gridGroupLevel === 2 && indentLevel === 1 && isParentGroup) colspan = 2
      
      return (
        <td 
          className={`
            rowHeader
            ${isGroupChild ? 'isGroupTD' : ''} 
            ${isParentGroup ? 'isParentGroupTD' : ''}
          `}
          style={{
            ...(indentLevel === 0 && !value ? backgroundColorStyleLight : {}),
            ...(indentLevel === 1 && !value ? evaThemeLighter(70, theme) : {}),
            fontWeight: !value ? 'bold' : 'unset'
          }}
          // Conditionally disable onClick if !value is true
          onClick={value ? undefined : () => toggleSubParentVisibility(id)}
          colSpan={colspan}
        >
          {!value && (subParentVisibility?.[id] ? ' ▼' : ' ▲')} {name} 
          {/* {gridGroupLevel} {indentLevel} {colspan} */}
          {/* {(gridGroupLevel + (isParentGroup ? 1 : 0)) - indentLevel} */}
        </td>
      );
    }
    
    const renderChildren = (children, newTreeData, indentLevel, id, parentName, subParentVisibility, gridGroupLevel) => 
    {
 

      return (
        // subParentVisibility?.[id] && 
        children && 
        children.map((child, index) => (
          ((child.children && child.children.length > 0) || (child && child.value)) &&
          <React.Fragment key={`child-${child.id}-${index}`}>
            {subParentVisibility?.[child.id] ?
              <>
                {renderRow(child.name, child.value, indentLevel, child.id, id, row.withTotalRow)}
                {renderChildren(child.children, newTreeData, indentLevel + 1, child.id, id, subParentVisibility, indentLevel + 1)}
                {/* {subParentVisibility?.[child.id] && showSubTotal(child, newTreeData, gridGroupLevel, indentLevel + 1)} */}
              </>
              :
              <>
                {renderRowWithSubTotal(child, newTreeData, gridGroupLevel, child.name, child.value, indentLevel, child.id, id, row.withTotalRow)}
              </>
            }
          </React.Fragment>
        ))
      )
    };
    
    const showSubTotal = (row, newTreeData, gridGroupLevel, indentLevel) => {
  
      // Calculate subtotal for the given parent node
      if(!row.withTotalRow) return 
  
      const subTotal = calculateSubTotal(row, newTreeData, gridGroupLevel);
  
      return (
        row.withTotalRow ?
          <tr className='totalRow'>
            {indentLevel > 1 && <td></td>}
  
            <td className='rowHeader' 
              colSpan={indentLevel === 0 ? gridGroupLevel: indentLevel} 
              // style={{ borderLeft: '1px solid #dadada' }}
            >Total {row.name}</td>
            {subTotal && subTotal.map((item, index) => (
              <React.Fragment key={`subtotal-${index}`}>
                {getRowData(item, index, row.id)}
              </React.Fragment>
            ))}
          </tr>
        : null
      )
    }
  
    function calculateSubTotal(node, newTreeData, gridGroupLevel) {
      let length = dataHeader.length - gridGroupLevel
      let subtotal = Array(length).fill(0);
      let containsString = Array(length).fill(false);
  
      // Check if the node should be skipped
      if (node.id && (node.id?.includes("groupTotal") || node.id?.includes("-TotalRow"))) {
        // Skip this node and return the initial subtotal
        return subtotal;
      }
  
      if (!node.children || node.children.length === 0) 
      {
        if (node.value) {
          return node.value.map(v => (isNaN(v) ? "-" : parseFloat(v) || 0));
        }
        return subtotal;
      }
  
      for (const child of node.children) 
      {
        const childTotal = calculateSubTotal(child, newTreeData, gridGroupLevel);
  
        for (let i = 0; i < subtotal.length; i++) {
          if (isNaN(childTotal[i])) {
            containsString[i] = true;
          } else {
            subtotal[i] += parseFloat(childTotal[i]) || 0;
          }
        }
      }
      
      return subtotal.map((total, index) => (containsString[index] ? "-" : total));
    }
    
    return (
      <>
        {subParentVisibility?.[row.id] ?
          <>
            {renderRow(row.name, row.value, 0, row.id, row.id, row.withTotalRow, gridHeader, gridGroupLevel)}
            {row.children && renderChildren(row.children, newTreeData, 1, row.id, row.name, subParentVisibility, gridGroupLevel)}
            {/* {subParentVisibility?.[row.id] && showSubTotal(row, newTreeData, gridGroupLevel, 0)} */}
          </>
          :
          <>
            {renderRowWithSubTotal(row, newTreeData, gridGroupLevel, row.name, row.value, 0, row.id, row.id, row.withTotalRow)}
          </>
        }
    
        {gridGroupLevel !== 1 && (row.id?.includes("grand-total") || row.id?.includes("-grandParent-")) &&
          renderRow(row.name, row.value, 0, row.id, row.id, row.withTotalRow, gridHeader, gridGroupLevel)
        }
      </>
    );
    
  };
  return (
    <>
      <table className='treeView'>
        <thead style={evaThemeLighter(100, theme)}>
          <tr>
            {dataHeader !== undefined ? dataHeader.map((item) => (
              <th id={item} key={item} style={evaThemeLighter(100, theme)}>{item}</th>
            )) : null}
          </tr>
        </thead>
        
        {newTreeData && newTreeData.length > 0 ? 
          <tbody className='evaEditedGrid'>
            {newTreeData.map((item) => (
              <React.Fragment key={`newTree-${item.id}`}>
                <NewTreeView 
                  row={item}
                  newTreeData={newTreeData}
                  gridHeader={gridHeader}
                  gridGroupLevel={gridGroupLevel}
                  dataHeader={dataHeader}
                  getRowData={getNewRowData}
                  toggleSubParentVisibility={toggleSubParentVisibility}
                  subParentVisibility={subParentVisibility}
                />
              </React.Fragment>
            ))}

            {/* {gridGroupLevel === 1 &&
              <ShowGroup1Total
                newTreeData={newTreeData} 
                gridGroupLevel={gridGroupLevel}
              />
            } */}
          </tbody>
        : (
          <tbody>
            {treeData && treeData.map((parent) => (
              <React.Fragment key={`treeData-${parent.id}`}>
                <TreeViewData parent={parent} />
              </React.Fragment>
            ))}
            {!isFromPnL && <SetGrandTotal />}
          </tbody>
        )}
      </table>
    </>
  );
}


