import React, { useEffect } from 'react';

function EVAAnswer({ answer }) {
    useEffect(() => {
        // const  strAnswer = answer.split("`nl`").map((line, index) => <div key={index}>{line.replace(/\[/g, "<b>").replace(/\]/g, "</b>")}</div>)
        // console.log(strAnswer)
    }, [answer]);

    const ReformatLine = ({ line }) => {
        let formattedLine = line;

        if (line.includes("####")) {
            formattedLine = `<h3>${line.replace("####", '').trim()}</h3>`;
        } else if (line.includes("###")) {
            formattedLine = `<h2>${line.replace("###", '').trim()}</h2>`;
        } else if (line.includes("- **")) {
            formattedLine = line.replace(/- *\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        } else if (line.includes("**")) {
            formattedLine = line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        }

        return <span dangerouslySetInnerHTML={{ __html: formattedLine }} />;
    };

    return (
        <div>
            {answer && answer.split("`nl`").map((line, index) => (
                <div key={index}>
                    <ReformatLine line={line} />
                </div>
            ))}
        </div>
    );
}

export default EVAAnswer;
