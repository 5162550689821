import React, { useEffect, useState } from 'react';
import { Tree } from 'react-arborist';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { ProcessDnDTreeviewData } from 'services/EVAEditGridService';
import { TransformSQLResult } from 'services/EVAEditGridService';
import GridContent from './GridContent';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ProcessRenamingSQLData } from 'services/EVAEditGridService';
import { RecalculateWithTotal } from 'services/EVAEditGridService';
import { TableHeader } from 'services/EVAEditGridService';
import { GetCellIdValue } from 'services/EVAEditGridService';
import { GenerateUniqueID } from 'services/EVAEditGridService';

function GridEditLayout({ isWithChild, isSubWithChild, data, item, sqlresult, gridHeader, 
    saveChanges, hanldeEditGridSorting, handleNewRowList, handleDragAndDropData, 
    handleAddUpdateCellFormulaList, handleGridGroupLevel, handleToggleEditGrid, handleRenamedSQLData,
    handleHiddenRow }) 
{
    const [treeData, setTreeData] = useState([])
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [gridGroupLevel, setGridGroupLevel] = useState(null)
    const [tableHeader, setTableHeader] = useState([])
    const [sortingData, setSortingData] = useState([])

    const [newRowList, setNewRowList] = useState([])
    const [dragAndDropData, setDragAndDropData] = useState([])
    const [cellFormulaList, setCellFormulaList] = useState([])
    const [renamedSQLData, setRenamedSQLData] = useState([])
    const [hiddenRow, setHiddenRow] = useState([])

    const [selectedCellFormula, setSelectedCellFormula] = useState('')
    const [selectedCellId, setSelectedCellId] = useState('')
    const [selectedRowId, setSelectedRowId] = useState('')
    const [selectedRowSQLId, setSelectedRowSQLId] = useState('')
    const [rowIndexCount, setRowIndexCount] = useState(0)
    const [onEditCell, setOnEditCell] = useState(false)
    const [childListCellValue, setChildListCellValue] = useState([])
    const [selectedRowHeaderforFormula, setSelectedRowHeaderforFormula] = useState([])

    useEffect(() => {
        if(item && item.editedGridCellData) {
            const { gridGroupLevel, newRowList, dragAndDropData, cellFormulaList, renamedSQLData, hiddenRow } = item.editedGridCellData
            setGridGroupLevel(gridGroupLevel)
            setNewRowList(newRowList)
            setCellFormulaList(cellFormulaList)
            setTimeout(() => {
                setDragAndDropData(dragAndDropData)
                setRenamedSQLData(renamedSQLData)
            }, 100);
            setHiddenRow(hiddenRow)
        }
    }, [item])
    useEffect(() => {
        // console.log(newRowList[newRowList.length - 1])
        handleNewRowList(newRowList)
        handleSetSelectedNode(newRowList)
        function handleSetSelectedNode(id, sqlId, name)
        {
            const rowData = newRowList[newRowList.length - 1]
            if(rowData)
            {
                setSelectedNodes([rowData.id])
                setSelectedNode([rowData.id, rowData.sqlId, rowData.name, false])
            }
        }
    }, [newRowList]);  
    useEffect(() => {
        handleDragAndDropData(dragAndDropData)
    }, [dragAndDropData]);  
    useEffect(() => {
        handleGridGroupLevel(gridGroupLevel)
    }, [gridGroupLevel])
    useEffect(() => {
        handleRenamedSQLData(renamedSQLData)
    }, [renamedSQLData])
    useEffect(() => {
        handleHiddenRow(hiddenRow)
    }, [hiddenRow])
    useEffect(() => {
        handleAddUpdateCellFormulaList(cellFormulaList)
    }, [cellFormulaList])
    useEffect(() => {
        setSortingData(sqlresult)
    },[sqlresult])
    useEffect(() => {
        if(treeData && treeData.length > 0) {
            // console.log(treeData)
            hanldeEditGridSorting(treeData)
        }
    },[treeData])

    useEffect(() => {

        const calculateGroupLevel = () => {
            if (!isWithChild && !isSubWithChild) return 1;
            if (isWithChild && !isSubWithChild) return 2;
            return 3;
        };
    
        const processNewRows = (treeData) => {

            if (newRowList.length > 0) {
                return populateNewRows(newRowList);
            }
            return treeData;
        };
    
        const processDragAndDropData = (treeData) => {
            if (dragAndDropData.length > 0 && treeData.length > 0) {
                let updatedTreeData = ProcessDnDTreeviewData(dragAndDropData, treeData);
    
                const hasGroupedItems = dragAndDropData.some(g => g.parentId && g.parentId.includes("-group-"));
                if (hasGroupedItems) {
                    updatedTreeData = RecalculateWithTotal(updatedTreeData);
                }
    
                return updatedTreeData;
            }
            return treeData;
        };
    
        const processRenaming = (treeData) => {
            if (renamedSQLData && renamedSQLData.length > 0) {
                return ProcessRenamingSQLData(renamedSQLData, treeData);
            }
            return treeData;
        };

        const processHiddenRow = (hiddenRow, treeData) => {
            if(!hiddenRow) return treeData
            for(let r = 0; r < hiddenRow.length; r++)
            {
                const id = hiddenRow[r]
                for(let d = 0; d < treeData.length; d++)
                {
                    findItemToHide(treeData, id)
                }
            }
            
    
            function findItemToHide (parent, id) {
               
                for (let i = 0; i < parent.length; i++) {
                    if(parent[i].id === id) {
                        parent[i].isHidden = true;
    
                        if(parent[i].children)
                            setChildtoHide(parent[i].children)
                    }
    
                    if (parent[i].children) {
                        findItemToHide(parent[i].children, id)
                    }
                }
            }
    
            function setChildtoHide (parent) {
                
                for (let i = 0; i < parent.length; i++) {
                    parent[i].isHidden = true;
                    if (parent[i].children) {
                        setChildtoHide(parent[i].children)
                    }
                }
            }
            return treeData
        }
    
        const groupLevel = calculateGroupLevel();
        const { result, newHeader, dataIndex } = TransformSQLResult(sortingData, gridHeader, groupLevel);

        setGridGroupLevel(groupLevel);
        setRowIndexCount(dataIndex);
        setTableHeader(newHeader);
    
        let newTreeData = result;

        let cellIdValueList = GetCellIdValue([...newTreeData])

        newTreeData = processNewRows(newTreeData);
        newTreeData = processDragAndDropData(newTreeData);
        newTreeData = processRenaming(newTreeData);
        newTreeData = processGroupWithTotal(newTreeData, cellIdValueList)
        newTreeData = processGrandTotal(newTreeData, cellIdValueList, newRowList)
        cellIdValueList = GetCellIdValue(newTreeData)
        newTreeData = processGrandParentRow(newRowList, newTreeData)
        newTreeData = processHiddenRow(hiddenRow,newTreeData)
        
        setChildListCellValue(cellIdValueList)

        setTreeData(newTreeData);
    }, [sortingData, dragAndDropData, isSubWithChild, newRowList, gridGroupLevel, renamedSQLData, hiddenRow, cellFormulaList]);
    const GetCellIdValue = (newTreeData) => {
        const cellIdValue = []
       
        getFindCellValue(newTreeData)
    
        function getFindCellValue(parent)
        {
            for (let i = 0; i < parent.length; i++) {
                if(parent[i].value) {
                    parent[i].value.map((val, index) => {
                        const data = {
                            id: parent[i].id,
                            sqlId: parent[i].sqlId,
                            cellId: getCellId(parent[i].sqlId, parent, i,index),
                            cellValue: typeof val === 'string' ? 0: val
                        }
                        cellIdValue.push(data)
                    })
                }
    
                if(parent[i].children) {
                    getFindCellValue(parent[i].children)
                }
            }
        }

        function getCellId(sqlId, parent, i, index) {
            try {
                if(sqlId.includes("-TotalRow")){
                    const parts = sqlId.split('-');
                    const secondToLastItem = parts.slice(1, -1).join('-');
                    return `[${secondToLastItem}-${TableHeader(index)}]`
                }
                if(sqlId.includes("grand-total")) {
                    return `[${sqlId}-${TableHeader(index)}]`
                }
                return `${TableHeader(index)}${parent[i].id.split("-")[0]}`
            }catch(error) {
                return `${TableHeader(index)}${parent[i].id.split("-")[0]}`
            }
        }
    
        return cellIdValue
    }
    useEffect(() => {
        // console.log(childListCellValue)
    },[childListCellValue])

    const processGrandTotal = (newTreeData, cellIdValue) => {
        const toSum = []
        loopThruItems(newTreeData)
        function loopThruItems(newTreeData)
        {
            for(let i = 0; i < newTreeData.length; i++)
            {
                if(newTreeData[i].children)
                {
                    const subTotal = calculateSubTotal({ children: newTreeData[i].children }, newTreeData[i].children);
                    toSum.push(subTotal)
                } else {
                    if (newTreeData[i].value) {
                        const subTotal = calculateSubTotal(newTreeData[i] , newTreeData[i].children);
                        toSum.push(subTotal)
                    }
                }
            }
        }

        const sumColumns = (array) => {
             // Check if the input array is defined and has at least one element
            if (!array || array.length === 0 || !Array.isArray(array[0])) {
                console.error("Invalid input: The array is undefined, empty, or not a 2D array.");
                return [];
            }

            const sums = [];
          
            // Iterate over each column index
            for (let col = 0; col < array[0].length; col++) {
              let sum = 0;
              // Sum each value in the current column
              for (let row = 0; row < array.length; row++) {
                sum += array[row][col];
              }
          
              sums.push(sum);
            }
          
            return sums;
        };
          
        // Calculate the sum for each column
        const columnSums = sumColumns(toSum);

        const newRow = {
            "id": "grand-total",
            "sqlId": "grand-total",
            "name": "Grand Total",
            "value": columnSums
        }
        const newChild = {
            "id": `grand-total`,
            "sqlId": `grand-total`,
            "name": `Grand Total`,
            "value": columnSums
        }

        const isGrandTotalExisit = newRowList.find(i => i.id === newRow.id)

        if(!isGrandTotalExisit) 
        {
            setNewRowList(prev => [...prev, newRow])
        } else {
            newChild.name = isGrandTotalExisit.name
        }
       


        newTreeData.push(newChild)
        return newTreeData
        
        function calculateSubTotal(node, newTreeData) {
            // Determine the length of the values array from the first item in newTreeData
            let length = gridHeader.length - gridGroupLevel
            // Initialize subtotal and containsString arrays
            let subtotal = Array(length).fill(0);
            let containsString = Array(length).fill(false);
          
         
            // // Check if the node should be skipped
            // if (node.id && node.id.includes("groupTotal")) {
            //   // Skip this node and return the initial subtotal
            //   return subtotal;
            // }

            // Check if the node should be skipped
            if (node.id && (node.id.includes("groupTotal") 
            || (node.id.includes("-parent-") && node.value)
            || (node.id.includes("-sub-") && node.value))) {
            // Skip this node and return the initial subtotal
                return subtotal;
            } 
         
            // Base case: If the node has no children, process its values
            if (!node.children || node.children.length === 0) {
              if (node.value) {
                // Map values, converting to numbers or "-" if non-numeric
          
                return node.value.map((v, i) => {
                    // console.log(node.id, v, i)
                    const cellId = `${TableHeader(i)}${node.id.split("-")[0]}`
                    const formulaData = cellFormulaList.find(f => f.rowId === node.id && f.cellId === cellId )
                    const cellData = cellIdValue.find(i => i.id === node.id && i.cellId === cellId)
                    const amount = formulaData ? formulaData.newValue : cellData ? cellData.cellValue : 0
                    return (isNaN(amount) ? "-" : parseFloat(amount) || 0)
                });
              }
              return subtotal; // Return initial subtotal if no values are found
            }
          
            // Recursive case: Traverse each child node
            for (const child of node.children) {
              const childTotal = calculateSubTotal(child, newTreeData);
          
              // Accumulate child totals into subtotal
              for (let i = 0; i < subtotal.length; i++) {
                if (isNaN(childTotal[i])) {
                  containsString[i] = true; // Mark if any child total is non-numeric
                } else {
                  subtotal[i] += parseFloat(childTotal[i]) || 0; // Accumulate numeric values
                }
              }
            }
          
            // Return the final subtotal, replacing numeric values with "-" where needed
            return subtotal.map((total, index) => (containsString[index] ? "-" : total));
        }
    }
    const processGrandParentRow = (rows, newTreeData) => {

        rows.forEach((row) => {
            const rowData = row.id.split("-");
            const groupLevel = rowData[1];
            switch (groupLevel) {
                case "grandParent":
                    if(row.nextTo)
                    {
                        const existingIndex = newTreeData.findIndex(p => p.sqlId === row.sqlId)
                        const index = newTreeData.findIndex(i => i.sqlId === row.nextTo)

                        if (existingIndex === -1 && index !== -1) {
                            newTreeData.splice(index + 1, 0, row)
                        }else {
                            newTreeData.push(row)
                        }
                    }
                    break;
                default:
                    break;
            }
        });

        return newTreeData
    }
    const processGroupWithTotal = (newTreeData, cellIdValue) => {
        loopThruItems(newTreeData)
        function loopThruItems(newTreeData)
        {
            for(let i = 0; i < newTreeData.length; i++)
            {
                if(newTreeData[i].children)
                {
                    const subTotal = calculateSubTotal({ children: newTreeData[i].children }, newTreeData[i].children);
                    const newChild = {
                        "id": `${newTreeData[i].id}-TotalRow`,
                        "sqlId": `${newTreeData[i].sqlId}-TotalRow`,
                        "name": `Total ${newTreeData[i].name}`,
                        "value": subTotal
                    }

                    const index = newTreeData[i].children ? newTreeData[i].children.findIndex(d => d.id === newChild.id) : -1
                    if(index !== -1)
                    {
                        newTreeData[i].children.splice(index, 1)
                    }
                    newTreeData[i].children.push(newChild)
                    loopThruItems(newTreeData[i].children)
                } 
                // else {
                //     if (newTreeData[i].value) {
                //         const subTotal = calculateSubTotal(newTreeData[i] , newTreeData[i].children);
                //         toSum.push(subTotal)
                //     }
                // }
            }
        }

        const cellIdValueList = GetCellIdValue([...newTreeData])
        setChildListCellValue(cellIdValueList)
        return newTreeData
     
        function calculateSubTotal(node, newTreeData) {
            // Determine the length of the values array from the first item in newTreeData
            let length = gridHeader.length - gridGroupLevel
            // Initialize subtotal and containsString arrays
            let subtotal = Array(length).fill(0);
            let containsString = Array(length).fill(false);
          
         
            // Check if the node should be skipped
            if (node.id && node.id.includes("groupTotal")) {
              // Skip this node and return the initial subtotal
              return subtotal;
            }

         
            // Base case: If the node has no children, process its values
            if (!node.children || node.children.length === 0) {
              if (node.value) {
                // Map values, converting to numbers or "-" if non-numeric
                // console.log(node)
                return node.value.map((v, i) => {
                    // console.log(node.id, v, i)
                    const cellId = `${TableHeader(i)}${node.id.split("-")[0]}`
                    const formulaData = cellFormulaList.find(f => f.rowId === node.id && f.cellId === cellId )
                    const cellData = cellIdValue.find(i => i.id === node.id && i.cellId === cellId)
                    const amount = formulaData ? formulaData.newValue : cellData ? cellData.cellValue : 0
                    return (isNaN(amount) ? "-" : parseFloat(amount) || 0)
                });
              }
              return subtotal; // Return initial subtotal if no values are found
            }
          
            // Recursive case: Traverse each child node
            for (const child of node.children) {
              const childTotal = calculateSubTotal(child, newTreeData);
          
              // Accumulate child totals into subtotal
              for (let i = 0; i < subtotal.length; i++) {
                if (isNaN(childTotal[i])) {
                  containsString[i] = true; // Mark if any child total is non-numeric
                } else {
                  subtotal[i] += parseFloat(childTotal[i]) || 0; // Accumulate numeric values
                }
              }
            }
          
            // Return the final subtotal, replacing numeric values with "-" where needed
            return subtotal.map((total, index) => (containsString[index] ? "-" : total));
        }
    }
    const handleSaveChanges = (e) => {
        e.preventDefault()
        saveChanges()
    }
    const onMove = ({ dragIds, parentId, index }) => {
        const newRowData = { dragIds, parentId, index };
        setDragAndDropData(prev => {
            if (!Array.isArray(prev)) {
                prev = [];
            }
            return [...prev, newRowData];
        });
    };
    
    const [selectedNode, setSelectedNode] = useState(['',''])
    const [selectedNodes, setSelectedNodes] = useState([]);

    useEffect(() => {
        // console.log(selectedNode)
    },[selectedNodes, selectedNode])

    function handleTreeItemClick(e, id, sqlId, nodeName, isHidden) {
        if(e.shiftKey) {
            // console.log(id.split("-")[1])

            if(id.split("-")[1] === "child")
                setSelectedNodes(prevSelectedNodes => 
                    prevSelectedNodes.includes(id) 
                    ? prevSelectedNodes.filter(nodeId => nodeId !== id) 
                    : [...prevSelectedNodes, id]
                );
        } else {

            if(onEditCell){
                // let updatedFormula;
                // const lastChar = currentFormulaStr.slice(-1);

                // if(!id.includes("-TotalRow")) return
                // // if (['('].includes(lastChar)) {
                // //     // If the last character is an operator, append the new cell ID
                // //     updatedFormula = `${currentFormulaStr}${selectedCellId.match(/[A-Za-z]+/)[0]}${id.split("-")[0]})`;
                // //     setSelectedCellFormula(updatedFormula);
                // // }
                // if (['+', '-', '*', '/','('].includes(lastChar)) {
                //     // If the last character is an operator, append the new cell ID
                //     updatedFormula = `${currentFormulaStr}${nodeName}`;

                //     setSelectedRowHeaderforFormula(prev => [...prev, {
                //         sqlId: sqlId, 
                //         nodeName: nodeName
                //     }])
                // } else {
                //     // Otherwise, replace the last referenced cell with the new cell ID
                //     const lastOperand = currentFormulaStr.match(/([A-Z]+\d+)$/); // Match the last cell reference
                //     if (lastOperand) {
                //         updatedFormula = currentFormulaStr.replace(lastOperand[0], nodeName);
                //     } else {
                //         updatedFormula = `=${nodeName}`;
                //     }

                //     setSelectedRowHeaderforFormula(prev => [...prev, {
                //         sqlId: sqlId, 
                //         nodeName: nodeName
                //     }])
                // }

                // setSelectedCellFormula(updatedFormula);
            }else {
                setSelectedNodes([id])
                setSelectedNode([id, sqlId, nodeName, isHidden])
            }
        }
    }
   
    const handleAddRow = (e, selectedNode, isWithChild = false, isAddChild = false) => {
        e.preventDefault()
        selectedNode[0] = selectedNode[0].replace("-TotalRow","")
        const newIndex = rowIndexCount + newRowList.length + 1
        const rowData = selectedNode[0].split("-")
        const newData = [...sortingData]
        const groupLevel = selectedNode[0].split("-")[1]
        const deductionCount = gridGroupLevel
        const newRow = new Array(newData[0].length - deductionCount).fill(''); 


        switch(groupLevel)
        {
            case "child": 
                const newItem = {
                    nextTo: selectedNode[0],
                    id: gridGroupLevel === 3 ? `${newIndex}-child-${rowData[2]}-${rowData[3]}-New Row ${newIndex}`
                        : gridGroupLevel === 2 ? `${newIndex}-child-${rowData[2]}-New Row ${newIndex}`
                        : `${newIndex}-child-New Row ${newIndex}`,
                    sqlId: GenerateUniqueID(),
                    name:`New Row ${newIndex}`,
                    value: newRow
                }
                setNewRowList(prev => [...prev, newItem])
            break;
            case "sub": 
                if(isAddChild) {
                    let splitData = selectedNode[0].split('-');
                    const newChildItem = {
                        id: `${newIndex + 1}-child-${splitData.slice(2).join('-')}-New Row ${newIndex + 1}`,
                        sqlId: GenerateUniqueID(),
                        name:`New Row ${newIndex + 1}`,
                        value: newRow
                    }
                    setNewRowList(prev => [...prev, newChildItem])
                } else if (gridGroupLevel === 3) {
                    if(isWithChild)
                    {
                        const newSubItem = {
                            nextTo: selectedNode[0],
                            id: `${newIndex}-sub-${rowData[2]}-New Row ${newIndex}`,
                            sqlId: GenerateUniqueID(),
                            name:`New Row ${newIndex}`,
                            children: []
                        }
                        setNewRowList(prev => [...prev, newSubItem])
                        const newChildItem = {
                            id: `${newIndex + 1}-child-${rowData[2]}-New Row ${newIndex}-New Row ${newIndex + 1}`,
                            sqlId: GenerateUniqueID(),
                            name:`New Row ${newIndex + 1}`,
                            value: newRow
                        }
                        setNewRowList(prev => [...prev, newChildItem])
                    }else {
                        const newSubItem = {
                            nextTo: selectedNode[0],
                            id: `${newIndex}-sub-${rowData[2]}-New Row ${newIndex}`,
                            sqlId: GenerateUniqueID(),
                            name:`New Row ${newIndex}`,
                            value: newRow
                        }
                        setNewRowList(prev => [...prev, newSubItem])
                    }
                    
                } else if(gridGroupLevel === 2) {

                    if(isWithChild) {
                        const newSubItem = {
                            nextTo: selectedNode[0],
                            id: `${newIndex}-sub-New Row ${newIndex}`,
                            sqlId: GenerateUniqueID(),
                            name:`New Row ${newIndex}`,
                            children: []
                        }
                        setNewRowList(prev => [...prev, newSubItem])

                        const newChildItem = {
                            id: `${newIndex + 1}-child-New Row ${newIndex}-New Row ${newIndex + 1}`,
                            sqlId: GenerateUniqueID(),
                            name:`New Row ${newIndex + 1}`,
                            value: newRow
                        }
                        setNewRowList(prev => [...prev, newChildItem])
                    }else {
                        const newSubItem = {
                            nextTo: selectedNode[0],
                            id: `${newIndex}-sub-New Row ${newIndex}`,
                            sqlId: GenerateUniqueID(),
                            name:`New Row ${newIndex}`,
                            value: newRow
                        }
                        setNewRowList(prev => [...prev, newSubItem])
                    }
                    
                } else {
                    const newSubItem = {
                        nextTo: selectedNode[0],
                        id: `${newIndex}-sub-${rowData[2]}-New Row ${newIndex}`,
                        sqlId: GenerateUniqueID(),
                        name:`New Row ${newIndex}`,
                        value: newRow
                    }
                    setNewRowList(prev => [...prev, newSubItem])
                }
            break;
            case "parent": 
                if(isAddChild) {
                    let splitData = selectedNode[0].split('-');
                    const newChildItem = {
                        id: `${newIndex + 1}-child-${splitData.slice(2).join('-')}-New Row ${newIndex + 1}`,
                        sqlId: GenerateUniqueID(),
                        name:`New Row ${newIndex + 1}`,
                        value: newRow
                    }
                    setNewRowList(prev => [...prev, newChildItem])
                } else{
                // if(gridGroupLevel === 3) { 
                    if(isWithChild) {
                        const newParentItem = {
                            nextTo: selectedNode[0],
                            id: `${newIndex}-parent-New Row ${newIndex}`,
                            sqlId: GenerateUniqueID(),
                            name:`New Row ${newIndex}`,
                            children: []
                        }
                        setNewRowList(prev => [...prev, newParentItem])
                        const newSubItem = {
                            id: `${newIndex + 1}-sub-${newParentItem.name}-New Row ${newIndex + 1}`,
                            sqlId: GenerateUniqueID(),
                            name:`New Row ${newIndex + 1}`,
                            children: []
                        }
                        setNewRowList(prev => [...prev, newSubItem])
                        const newChildItem = {
                            id: `${newIndex + 2}-child-${newParentItem.name}-${newSubItem.name}-New Row ${newIndex + 2}`,
                            sqlId: GenerateUniqueID(),
                            name:`New Row ${newIndex + 2}`,
                            value: newRow
                        }
                        setNewRowList(prev => [...prev, newChildItem])
                    }else {
                        const newParentItem = {
                            nextTo: selectedNode[0],
                            id: `${newIndex}-parent-New Row ${newIndex}`,
                            sqlId: GenerateUniqueID(),
                            name:`New Row ${newIndex}`,
                            value: newRow
                        }
                        setNewRowList(prev => [...prev, newParentItem])
                    }
                }
                break;
            case "total": 
                const newGrandParentItem = {
                    nextTo: selectedNode[1],
                    id: `${newIndex}-grandParent-New Row ${newIndex}`,
                    sqlId: GenerateUniqueID(),
                    name:`New Row ${newIndex}`,
                    value: newRow
                }

                setNewRowList(prev => [...prev, newGrandParentItem])
            break;
            case "grandParent": 
                const addedGrandParentItem = {
                    nextTo: selectedNode[1],
                    id: `${newIndex}-grandParent-New Row ${newIndex}`,
                    sqlId: GenerateUniqueID(),
                    name:`New Row ${newIndex}`,
                    value: newRow
                }
                setNewRowList(prev => [...prev, addedGrandParentItem])
            break;
        }
    }
    const handleAddParentGroupings = (e, selectedNode, isWithTotal = false) => {
        e.preventDefault()
        const newIndex = rowIndexCount + newRowList.length
        const newData = [...sortingData]
        const newRow = new Array(newData[0].length - gridGroupLevel).fill(''); 
        const groupLevel = selectedNodes[0].split("-")[1]
        const parent = selectedNodes[0].split("-")[2]
        const sub = selectedNodes[0].split("-")[3]

        switch(groupLevel)
        {
            case "child": 
                if(selectedNodes.length > 0)
                {
                    const newChildItem = {
                        isWithTotal: isWithTotal,
                        id: `${newIndex + 1}-group-${parent}-${sub}-New Row ${newIndex + 1}`,
                        sqlId: `group-${parent}-${sub}-New Row ${newIndex + 1}`,
                        name:`New Row ${newIndex + 1}`,
                        children: []
                    }

                    if(gridGroupLevel === 3)
                    {
                        selectedNodes.map((node) => {
                            const parent = node.split("-")[2]
                            const sub = node.split("-")[3]
                            const childId = node
                            
                            const childData = treeData.find(parentItem => {
                                if (parentItem.name === parent) {
                                    const subItem = parentItem.children.find(subItem => subItem.name === sub);
                                    if (subItem) {
                                        const child =  subItem.children.find(childItem => childItem.id === childId);
                                        if(child) {
                                            newChildItem.children.push(child)
                                            return true
                                        }
                                        return false;
                                    }
                                    return false;
                                }
                                return false;
                            });
                        })
                        
                        setNewRowList(prev => [...prev, newChildItem])
                    } else if(gridGroupLevel === 2) {
                        selectedNodes.map((node) => {
                            const subName = node.split("-")[2]
                            const childId = node
                            const childData = treeData.find(sub => {
                                if (sub.name === parent) {
                                    const subItem = sub.children.find(subItem => subItem.id === node);
                                    newChildItem.children.push(subItem)
                                }
                                return false;
                            });
                        })
                        
                        setNewRowList(prev => [...prev, newChildItem])
                    } else if(gridGroupLevel === 1) {
                        selectedNodes.map((node) => {
                            const childData = treeData.find(item => {
                                if (item.id === node) {
                                    newChildItem.children.push(item)
                                }
                                return false;
                            });
                        })
                        setNewRowList(prev => [...prev, newChildItem])
                    }
                    
                }
                
            break;
            case "sub": 
            break;
            case "parent": 
            break;
        }
        setSelectedNode(['',''])
        setSelectedNodes([])
        // const newParentItem = {
        //     id: `${newIndex}-parent-New Row ${newIndex}`,
        //     name:`New Row ${newIndex}`,
        //     children: []
        // }
        // setNewRowList(prev => [...prev, newParentItem])
        // const newSubItem = {
        //     id: `${newIndex + 1}-sub-${newParentItem.name}-New Row ${newIndex + 1}`,
        //     name:`New Row ${newIndex + 1}`,
        //     children: []
        // }
        // setNewRowList(prev => [...prev, newSubItem])
        // const newChildItem = {
        //     id: `${newIndex + 2}-child-${newParentItem.name}-New Row ${newIndex + 1}-New Row ${newIndex + 2}`,
        //     name:`New Row ${newIndex + 2}`,
        //     value: newRow
        // }
        // setNewRowList(prev => [...prev, newChildItem])
    }
    const handleRevertChanges = (e) => {
        e.preventDefault()
        setAlert(
            <ReactBSAlert
                warning
                title="Revert Changes"
                onConfirm={() => {
                    revertChanges()
                    setAlert(null);
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="warning"
                cancelBtnText="Cancel"
                showCancel={true}
                confirmBtnText="Ok"
                btnSize=""
            >
                <p>Are you sure you want to revert changes?</p>
            </ReactBSAlert>
        );
    }
    const handleFormulaWarning = (message) => {
        setAlert(
            <ReactBSAlert
                warning
                title="Formula Error"
                onConfirm={() => {
                    setAlert(null);
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="warning"
                cancelBtnText="Cancel"
                showCancel={true}
                confirmBtnText="Ok"
                btnSize=""
            >
                <p>{message}</p>
            </ReactBSAlert>
        );
    }
    const revertChanges = () => {
        setOnEditCell(false)
        setDragAndDropData([])
        setNewRowList([])
        setCellFormulaList([])
        setRenamedSQLData([])
        setHiddenRow([])
    }
    const handleExitPage = (e) => {
        e.preventDefault()
        handleToggleEditGrid()
    }
    const populateNewRows = (rows) => {
        const res = TransformSQLResult(sortingData, gridHeader, gridGroupLevel);
        let newTreeData = res.result;
        if(rows.length === 0) return newTreeData
        setRowIndexCount(res.dataIndex)

        rows.forEach((row) => {
            const rowData = row.id.split("-");
            const groupLevel = rowData[1];
            const parent = rowData[2];
            const subName = rowData[3];
            const parentData = newTreeData.find(item => item.name === parent);

            // console.log(newTreeData)
         
            // // Start searching from the top level of myData

            // Recursively search the data structure to find the right spot for insertion
            function findAndInsert(children, newData) {
                // Check if newData is already present in the structure
                const isAlreadyPresent = (items, id) => {
                    for (let item of items) {
                        if (item.id === id) return true;
                        if (item.children && isAlreadyPresent(item.children, id)) return true;
                    }
                    return false;
                };

                // If newData is already in the structure, return without inserting
                if (isAlreadyPresent(children, newData.id)) {
                    return false;
                }

                for (let i = 0; i < children.length; i++) {
                    // Check if the current child's id matches newData.nextTo
                    if (children[i].id === newData.nextTo) {
                        // Insert the newData after the found child
                        children.splice(i + 1, 0, newData);
                        return true; // Stop further searching once inserted
                    }
                    // If the current child has its own children, continue searching recursively
                    if (children[i].children) {
                        const found = findAndInsert(children[i].children, newData);
                        if (found) return true;
                    }
                }
                return false;
            }

            // console.log(row)
            // console.log(groupLevel)

            switch (groupLevel) {
                case "child":
                    if (parentData) {
                        
                        if(row.nextTo)
                        {
                            for (let i = 0; i < newTreeData.length; i++) {
                                if(newTreeData[i].children)
                                    if (findAndInsert(newTreeData[i].children, row)) {
                                        break;
                                    }
                            }
                        } else {
                            if (subName === row.name) {
                                const existingChild = parentData.children.find(child => child.id === row.id);
                                if (!existingChild) {
                                    parentData.children.push(row);
                                }
                            } else {
                                const subData = parentData.children && parentData.children.find(sub => sub.name === subName);
                                if (subData) {
                                    const existingChild = subData.children && subData.children.find(child => child.id === row.id);
                                    if (!existingChild) {
                                        if (subData.children)
                                            subData.children.push(row);
                                    }
                                } else if (gridGroupLevel === 1) {
                                    newTreeData.push(row);
                                } else if (gridGroupLevel === 2) {
                                    const existingChild = parentData.children.find(child => child.id === row.id);
                                    if (!existingChild) {
                                        parentData.children.push(row);
                                    }
                                }
                            }
                        }
                        
                    } else if (gridGroupLevel === 1) {
                        newTreeData.push(row);
                    }
                    break;
                case "sub":
                    
                    if (parentData)
                    {
                        if(row.nextTo)
                        {
                            for (let i = 0; i < newTreeData.length; i++) {
                                if(newTreeData[i].children)
                                    if (findAndInsert(newTreeData[i].children, row)) {
                                        break;
                                    }
                            }
                        } else {
                            const existingSub = parentData.children.find(sub => sub.id === row.id);
                            if (!existingSub) {
                                parentData.children.push(row);
                            }
                        }
                    }

                    if(gridGroupLevel === 2)
                    {
                        if(row.nextTo)
                        {
                            const existingIndex = newTreeData.findIndex(p => p.id === row.id)
                            const index = newTreeData.findIndex(p => p.id === row.nextTo)

                            if (existingIndex === -1 && index !== -1) {
                                newTreeData.splice(index + 1, 0, row)
                            }

                            for (let i = 0; i < newTreeData.length; i++) {
                                if(newTreeData[i].children)
                                    if (findAndInsert(newTreeData[i].children, row)) {
                                        break;
                                    }
                            }
                        } else {
                            newTreeData.push(row);
                        }
                    }
                        
                    break;
                case "parent":
                    if(row.nextTo)
                    {
                        const existingIndex = newTreeData.findIndex(p => p.id === row.id)
                        const index = newTreeData.findIndex(p => p.id === row.nextTo)

                        if (existingIndex === -1 && index !== -1) {
                            newTreeData.splice(index + 1, 0, row)
                        }
                    } else {
                        const existingParent = newTreeData.find(item => item.id === row.id);
                        if (!existingParent) {
                            newTreeData.push(row);
                        }
                    }
                   
                    break;
                case "group":
                    if(gridGroupLevel === 3) {
                        const firstId = row.children[0].id
                        insertRowAboveFirstId(newTreeData, firstId, row)

                        row.children.map((child) => {
                            const parent = child.id.split("-")[2]
                            const sub = child.id.split("-")[3]
                            const childId = child.id
                            removeChildById(newTreeData, childId);
                        })

                        function removeChildById(data, targetId) {
                            for (let parent of data) {
                                if (parent.children && parent.children.length > 0) {
                                    for (let sub of parent.children) {
                                        if (sub.children && sub.children.length > 0) {
                                            const index = sub.children.findIndex(child => child.id === targetId);
                                            if (index !== -1) {
                                                sub.children.splice(index, 1);
                                                return; 
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        function insertRowAboveFirstId(data, firstId, row) {
                            for (let parent of data) {
                                if (parent.children && parent.children.length > 0) {
                                    for (let sub of parent.children) {
                                        if (sub.children && sub.children.length > 0) {
                                            const index = sub.children.findIndex(child => child.id === firstId);
                                            if (index !== -1) {
                                                sub.children.splice(index, 0, row); // Insert row above firstId
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        

                    } else if(gridGroupLevel === 2) {
                        const firstId = row.children[0].id
                        insertRowAboveFirstId(newTreeData, firstId, row)

                        row.children.map((child) => {
                            const childId = child.id
                            removeChildById(newTreeData, childId);
                        })

                        function insertRowAboveFirstId(data, firstId, row) {
                            for (let parent of data) {
                                if (parent.children && parent.children.length > 0) {
                                    const index = parent.children.findIndex(child => child.id === firstId);
                                    if (index !== -1) {
                                        parent.children.splice(index, 0, row);
                                        return;
                                    }
                                }
                            }
                        }
                        function removeChildById(data, targetId) {
                            for (let parent of data) {
                                if (parent.children && parent.children.length > 0) {
                                    const index = parent.children.findIndex(child => child.id === targetId);
                                    if (index !== -1) {
                                        parent.children.splice(index, 1);
                                        return;
                                    }
                                }
                            }
                        }

                    } else if(gridGroupLevel === 1) {
                        const firstId = row.children[0].id
                        const index = newTreeData.findIndex(child => child.id === firstId);
                        newTreeData.splice(index, 0, row);

                        row.children.map((child) => {
                            const index = newTreeData.findIndex(c => c.id === child.id);
                            newTreeData.splice(index, 1);
                        })
                    }

                    if(row.isWithTotal) {
                        const totalIndex = row.children.findIndex(c => c.id === row.id.replace("-group-", "-groupTotal-"))
                        if(totalIndex !== -1)
                        {
                            row.children.splice(totalIndex, 1)
                        }

                        const totalValue = row.children.reduce((acc, child) => {
                            return acc.map((sum, index) => 
                                typeof child.value[index] === 'number' ? sum + child.value[index] : sum
                            );
                        }, Array(row.children[0].value.length).fill(0));
        
                        const totalChild = {
                            id: row.id.replace("-group-", "-groupTotal-"),
                            name: `Total ${row.name}`,
                            value: totalValue 
                        };

                        row.children.push(totalChild)
                    }
                    break;
               
                default:
                    break;

            }
        });
                        
        return newTreeData;
    };
    const handleCellClick = (cellId, rowId, sqlId) => {

        let newCellId = cellId

        if (onEditCell) {
            let updatedFormula;
            const lastChar = currentFormulaStr.slice(-1);

            if(sqlId.includes("-TotalRow")) {
                const parts = sqlId.split('-');
                const secondToLastItem = parts.slice(1, -1).join('-');
                console.log(secondToLastItem);
                newCellId = `[${secondToLastItem}-${getFirstLetters(cellId)}]`
            }

            if(sqlId.includes("grand-total")) {
                newCellId = `[${sqlId}-${TableHeader(cellId)}]`
            }

            function getFirstLetters(str) {
                const match = str.match(/^[A-Za-z]+/);
                return match ? match[0] : '';
            };

            if (['+', '-', '*', '/','('].includes(lastChar)) {
                // If the last character is an operator, append the new cell ID
          
                updatedFormula = `${currentFormulaStr}${newCellId}`;
            } else {
                // Otherwise, replace the last referenced cell with the new cell ID
                const lastOperand = currentFormulaStr.match(/([A-Z]+\d+)$/); // Match the last cell reference
                if (lastOperand) {
                    updatedFormula = currentFormulaStr.replace(lastOperand[0], newCellId);
                } else {
                    updatedFormula = `${newCellId}`;
                }
            }
            

            setSelectedCellFormula(updatedFormula);
        }
    };
    const handleCellDoubleClick = (cellId, rowId, sqlId) => {
        if(onEditCell) return  
        setSelectedRowSQLId(sqlId)
        setSelectedRowId(rowId)
        setSelectedCellId(cellId)
        handleToggleCellEdit(true)
        if(cellFormulaList.length === 0) {
            setSelectedCellFormula(`${cellId}`)
        }else {
            const formulaItem = cellFormulaList.find(f => f.rowId === rowId && f.cellId === cellId)
            if(formulaItem) setSelectedCellFormula(formulaItem.formula)
            else setSelectedCellFormula(`${cellId}`)
        }
    };
    const handleToggleCellEdit = (value) => {
        if(!value) {
            setSelectedRowSQLId('')
            setSelectedCellId('')
            setSelectedRowId('')
            currentFormulaStr = ''
            setSelectedCellFormula('')
        }
        setOnEditCell(value)
    }
    const handleSaveFormula = (cellId, rowId, formula, sqlId, applyToAllFormula = false) => {
        // try{

            let currentFormula = formula.trim()

            setSelectedRowSQLId('')
            setSelectedCellId('')
            setSelectedRowId('')
            currentFormulaStr = ''
            setSelectedCellFormula('')

            if(currentFormula === '' || currentFormula.length === 0) 
                currentFormula = '0'

            if(currentFormula === '' || currentFormula.length === 0)
            {
                if(applyToAllFormula)
                {
                    const newFormulaList = cellFormulaList.filter(f => f.sqlId !== sqlId)
                    setCellFormulaList(newFormulaList)
                    console.log(newFormulaList)
                } else {
                    const newFormulaList = cellFormulaList.filter(f => f.rowId !== rowId || f.cellId !== cellId)
                    console.log(newFormulaList)
                    setCellFormulaList(newFormulaList)
                }
            } else {

                if (currentFormula.includes('(') || currentFormula.includes(')')) {
                    handleFormulaWarning("formula format with '(' ')' is currently not yet supported")
                    return
                }

                if(applyToAllFormula)
                {
                    processapplyToAllColumnFormula(cellId, rowId, currentFormula, sqlId)
                }else {
                    const parsedFormula = NewParseFormula(currentFormula)
                    
                    const data = []
                    const uniqueFormula = parsedFormula && parsedFormula.map(i => {
                        const childVal = childListCellValue.find(item => item.cellId === i)
                        if(childVal)
                            data.push({sqlId :childVal.sqlId, cellId: i })
                    })
    
                    const param = {
                        sqlId: sqlId,
                        rowId: rowId,
                        cellId: cellId,
                        formula: currentFormula,
                        cellIdwithSQLId: data,
                        newValue: processFormula(currentFormula, cellFormulaList, childListCellValue, cellId, sqlId),
                        originalValue: getCellOldValue(cellId)
                    }

                    setCellFormulaList(prevParams => {
                        const existingIndex = prevParams.findIndex(p =>
                            p.cellId === param.cellId
                        );
                        if (existingIndex !== -1) {
                            const updatedParams = [...prevParams];
                            updatedParams[existingIndex] = param;
                            return updatedParams;
                        } else {
                            return [...prevParams, param];
                        }
                    })
                }
            }
        // } catch (error) {
        //     console.error(`Error processing formula: ${error.message}`);
        // }
    }   
    const processapplyToAllColumnFormula = (cellId, rowId, currentFormula, sqlId) => {
        const columnCount = gridHeader.length - gridGroupLevel

        for(let i = 0; i < columnCount; i++)
        {
            const cellId = `${TableHeader(i)}${rowId.split("-")[0]}`
            const parsedFormula = NewParseFormula(currentFormula)
            // console.log(parsedFormula)
            const data = []
            let newFormula = ""

            const uniqueFormula = parsedFormula && parsedFormula.map(f => {
                const childVal = childListCellValue.find(item => item.cellId === f)

                if(childVal) {
                    // console.log(childVal)
                    // console.log(childVal.cellId.replace("-A", `-${TableHeader(i)}`))
                    if(validateBrackets(childVal.cellId))
                    {
                        data.push({id :childVal.id, sqlId :childVal.sqlId, cellId: childVal.cellId.replace("-A]", `-${TableHeader(i)}]`), columnIndex: i})
                        newFormula += childVal.cellId.replace("-A]", `-${TableHeader(i)}]`)
                    } else {
                        data.push({id :childVal.id, sqlId :childVal.sqlId, cellId: childVal.cellId.replace("A", `${TableHeader(i)}`), columnIndex: i})
                        newFormula += childVal.cellId.replace("A", `${TableHeader(i)}`)
                    }
                    // console.log({id :childVal.id, sqlId :childVal.sqlId, cellId: childVal.cellId.replace("A", `-${TableHeader(i)}`), columnIndex: i, OrigCellId: childVal.cellId})
                   
                } else {
                    data.push({operator: f})
                    newFormula += f
                }
            })
            function validateBrackets(str)
            {
                const regex = /^\[.*\]$/;
                return regex.test(str);
            };
            
            const param = {
                sqlId: sqlId,
                rowId: rowId,
                cellId: cellId,
                formula: newFormula,
                cellIdwithSQLId: data,
                newValue: processFormula(newFormula, cellFormulaList, childListCellValue, cellId, sqlId),
                originalValue: getCellOldValue(cellId)
            }
            // console.log(param)
            setCellFormulaList(prevParams => {
                const existingIndex = prevParams.findIndex(p =>
                    p.cellId === param.cellId
                );
                if (existingIndex !== -1) {
                    const updatedParams = [...prevParams];
                    updatedParams[existingIndex] = param;
                    return updatedParams;
                } else {
                    return [...prevParams, param];
                }
            })
        }
    }
    const getCellOldValue = (selectedCellId) => {
        const cellData = childListCellValue.find(c => c.cellId === selectedCellId)
        return cellData ? cellData.cellValue : 0
    }
    const processFormula = (formula, cellFormulaList, childListCellValue, selectedCellId, sqlId) => {
    
        if(!childListCellValue) return ''


        // console.log({formula, cellFormulaList, childListCellValue, selectedCellId, sqlId})

        // if(!formula.startsWith('=')) 
        // {
        //     return isNaN(formula) ? formula : parseFloat(formula)
        // }else{
        // console.log({sqlId, formula})
        const parsedFormula = NewParseFormula(formula)

        if(sqlId === 'eb8c-e36c-46e7')
            console.log(parsedFormula)

        if(!parsedFormula) return formula
        // if(parsedFormula.length === 1) return parsedFormula[0]

        const replacedFormula = ReplaceCellIdsWithValues(parsedFormula, cellFormulaList, childListCellValue, selectedCellId, selectedRowHeaderforFormula)
        if(sqlId === 'eb8c-e36c-46e7')
            console.log(replacedFormula)
        
        const finalResult = EvaluateFormula(replacedFormula);
        if(sqlId === 'eb8c-e36c-46e7')
            console.log(finalResult)

        return finalResult

            
        // }
    }
    const NewParseFormula = (formula) => {
        const sumMatch = formula.match(/=sum\((.*)\)/i);
        const avgMatch = formula.match(/=avg\((.*)\)/i);
    
        if (sumMatch) {
            const [_, parent] = sumMatch[1].match(/([A-Z]+\d+)/);
            return { function: 'sum', parent: parent.trim() };
        } else if (avgMatch) {
            const [_, parent] = avgMatch[1].match(/([A-Z]+\d+)/);
            return { function: 'avg', parent: parent.trim() };
        }
    
        // Updated regex to handle text labels within brackets, cell references, and operators
        // const regex = /(\[[^\]]+\])|([A-Z]+\d+)|([\+\-\*\/])|(-?\d+(\.\d+)?)/g;
        const regex = /(\[[^\]]+\])|([A-Z]+\d+)|([\+\-\*\/])|(-?\d+(\.\d+)?)/g;
        const matches = formula.match(regex);
    
        // Filter out empty matches
        const result = matches ? matches.filter(match => match.trim()) : [];
    
        return result;
    };
    // const NewParseFormula = (formula) => {
    //     const sumMatch = formula.match(/=sum\((.*)\)/i);
    //     const avgMatch = formula.match(/=avg\((.*)\)/i);
        
    //     if (sumMatch) {
    //         const [_, parent] = sumMatch[1].match(/([A-Z]+\d+)/);
    //         return { function: 'sum', parent: parent.trim() };
    //     } else if (avgMatch) {
    //         const [_, parent] = avgMatch[1].match(/([A-Z]+\d+)/);
    //         return { function: 'avg', parent: parent.trim() };
    //     }
    
    //     // Updated regex to handle text labels, cell references, and operators
    //     const regex = /([A-Z]+\d+)|([\+\-\*\/])|(-?\d+(\.\d+)?)|([A-Za-z\s]+)/g;
    //     const matches = formula.match(regex);
    
    //     // Filter out empty matches
    //     const result = matches ? matches.filter(match => match.trim()) : [];
    
    //     return result;
    // };
    const ReplaceCellIdsWithValues = (parsedFormula, cellFormulaList, childListCellValue, selectedCellID, selectedRowHeaderforFormula) => {
        return parsedFormula.map(token => {
            const newToken = token.trim()
            if (/[\+\-\*\/]/.test(newToken) && newToken.length === 1) {
                return newToken; // If the token is an operator or a number, return it as is
            } else {
                
                const rowHeader = selectedRowHeaderforFormula.find(i => i.nodeName === newToken)
                if(rowHeader)
                {
                    const cell = childListCellValue.find(cell => cell.sqlId === rowHeader.sqlId);
                    if(cell) return cell.cellValue
                }
               
                const cellFormula = cellFormulaList.length > 0 ? cellFormulaList.filter(f => f.cellId === newToken) : []
                const cell = childListCellValue.find(cell => cell.cellId === newToken);
              

                if(cellFormula.length > 0 && selectedCellID !== newToken)
                {
                    return cellFormula[0].newValue
                }
              
                if(cell && cellFormula.length > 0 && selectedCellID !== newToken)
                {     
                    if(cellFormula[0].originalValue !== cell.cellValue) 
                        return cell.cellValue
                    else 
                        return cellFormula[0].newValue
                } else {
                    if (cell) {
                        return cell.cellValue;
                    } else {
                        if(isNaN(newToken))
                            return newToken
                        else 
                            return newToken
                    }
                }
            }
        });
    };
    const EvaluateFormula = (replacedFormula) => {
        let isPercentage = false

        if ((replacedFormula[0] === '-' || replacedFormula[0] === '+') && typeof replacedFormula[1] === 'number' && replacedFormula.length === 2) {
            replacedFormula[1] = replacedFormula[1] * -1;
            replacedFormula.shift();
        }

        const convertedFormula = replacedFormula.map(item => {
            if (typeof item === 'string' && item.includes('%')) {
                isPercentage = true
                return parseFloat(item.replace('%', '').trim())
            }
            return item;
        });
        const expression = convertedFormula.join('');

        let result;
        try {
            result = eval(expression);
        } catch (error) {
            // console.error('Error evaluating formula:', error);
            return (`${error}`).replace("ReferenceError: ", "");
        }

        // Convert the result back to a percentage string
        if(isPercentage) 
            return `${result.toFixed(2)} %`
        else
            return result;
    };
    let currentFormulaStr = ''
    const getCurrentFormula = (currentFormula) => {
        if(onEditCell)
            currentFormulaStr = currentFormula
    }
    const handleHideRow = (e, selectedNode) => {
        e.preventDefault();
        
        setHiddenRow(prev => {
            if (!Array.isArray(prev)) {
                prev = [];
            }

            if(prev.includes(selectedNode[0])) return prev.filter(id => id !== selectedNode[0])
            else return [...prev, selectedNode[0]];
        })
    }
    const [alert, setAlert] = useState(false);
    
    const RenderSettings = ({ selectedNode, selectedCellId, selectedRowId, selectedRowSQLId, selectedCellFormula, onEditCell, 
        handleToggleCellEdit, handleSaveFormula, getCurrentFormula, cellFormulaList, isGridSubWithChild, isGridWithChild, isSubWithChild, 
        handleHideRow }) => {
        const [applyToAllFormula, setApplyToAllFormula] = useState(false);
        const [isWithChild, setIsWithChild] = useState(false);
        const [isWithTotal, setIsWithTotal] = useState(false);
        const [itemName, setItemName] = useState(null);
        const [newUpdates, setNewUpdates] = useState(0)
        const [currentFormula, setCurrentFormula] = useState(selectedCellFormula)
        

        //#region add/remove row
        useEffect(() => {
            setItemName(selectedNode[2]);
            // console.log(selectedNode)
        }, [selectedNode, newUpdates]);
    
        const groupLevel = selectedNode[0] 
        ? selectedNode[0].includes("-") 
            ? selectedNode[0]
                .split("-")[1]
                .charAt(0).toUpperCase() + selectedNode[0].split("-")[1].slice(1).toLowerCase()
            : '' 
        : ''; 
    
        // console.log(selectedNode)
        // console.log(selectedNodes)

        const handleChangeItemName = (event) => {
            setItemName(event.target.value);
        };
        const handleRenameNewRow = (e, selectedNode) => {
            e.preventDefault();
            const newData = [...newRowList]
            const newRowToUpdate = newData.find(row => row.id === selectedNode[0])
            setSelectedNode([selectedNode[0],selectedNode[1], itemName, selectedNode[3]])
            if(newRowToUpdate){
                newRowToUpdate.name = itemName
                setNewRowList(newData)
                setNewUpdates(prev => prev + 1)
            } else {
                processRenameSQLData(selectedNode, itemName)
            }
        };
        const processRenameSQLData = (selectedNode, itemName) => {
            const param = {
                id: selectedNode[0],
                oldName: selectedNode[1],
                newName: itemName
            }
            setRenamedSQLData(prev => {
                if (!Array.isArray(prev)) {
                    prev = [];
                }
                return [...prev, param];
            })
        }
        const handleRemoveRow = (e, selectedNode) => {
            e.preventDefault();

            const newData = [...newRowList]
            const groupLevel = selectedNode[0].split("-")[1]
            let updatedRowList = newData.filter(row => row.id !== selectedNode[0])
            let newDragAndDropData = dragAndDropData && dragAndDropData.filter(item => item.dragIds[0] !== selectedNode[0])
            newDragAndDropData = newDragAndDropData.length > 0 && newDragAndDropData.filter(item => item.parentId !== selectedNode[0])

            if(groupLevel === "child" || groupLevel === "sub")
            {
                updatedRowList = removeChildById(updatedRowList, selectedNode[0]);
                updatedRowList = updatedRowList.filter(row => row.id.split("-")[3] !== selectedNode[1])

                if(newDragAndDropData && newDragAndDropData.length > 0)
                newDragAndDropData = newDragAndDropData.length > 0 && newDragAndDropData.filter(item => item.dragIds[0].split("-")[3] !== selectedNode[1])
            } else if(groupLevel === "parent") 
            {
                updatedRowList = updatedRowList.filter(row => row.id.split("-")[2] !== selectedNode[1])
                if (newDragAndDropData && newDragAndDropData.length > 0) {
                    newDragAndDropData = newDragAndDropData.filter(item => {
                        const dragIdPart = item.dragIds[0]?.split("-")[2];
                        const parentIdPart = selectedNode[0]?.split("-")[2];

                        return dragIdPart !== selectedNode[1] && (!item.parentId || !item.parentId.includes(`sub-${parentIdPart}`));
                    });
                }
            }

            setDragAndDropData(newDragAndDropData)
            setNewRowList(updatedRowList)
            const newCellFormulaList = cellFormulaList.filter(i => i.rowId !== selectedNode[0])
            setCellFormulaList(newCellFormulaList)
            setSelectedNode(['',''])
        };
        const removeChildById = (rowList, childIdToRemove) => {

            return rowList.map(row => {
                if (row.children) {
                    // Filter out the child with the matching ID
                    row.children = row.children.filter(child => child.id !== childIdToRemove);
                }
                return row;
            })
            // .filter(row => {
            //     // Remove the parent or sub if it had only one child and that child was removed
            //     return !(row.children && row.children.length === 0);
            // });
        };
        
        //#endregion

        useEffect(() => {
            getCurrentFormula(currentFormula)
        },[currentFormula])

         useEffect(() => {
            // console.log(selectedCellFormula)
        },[selectedCellFormula])

        const handleFormulaChange = (event) => {
            setCurrentFormula(event.target.value);
        }
        const handleCancelEdit = () => {
            handleToggleCellEdit(false)
        }
        const onHandleSaveFormula = () => {
            setCurrentFormula('')
            getCurrentFormula('')
            handleToggleCellEdit(false)
            handleSaveFormula(selectedCellId, selectedRowId, currentFormula, selectedRowSQLId, applyToAllFormula)
        }
        const onHandleHideRow = (e, selectedNode, hiddenRow) => {
            e.preventDefault();
            selectedNode[0] = selectedNode[0].replace("-TotalRow","")
            setSelectedNode([selectedNode[0], selectedNode[1], selectedNode[2], hiddenRow === undefined ? true : !hiddenRow])
            handleHideRow(e, selectedNode)
        }

        return (
            <div className='gridSetting'>
                <Form>
                    {!onEditCell ?
                    <>
                        <FormGroup>
                            <Label>Item Name:</Label>
                            <Input 
                                value={itemName}
                                disabled={selectedNode[0].includes("-TotalRow")}
                                onChange={handleChangeItemName}
                            />

                            {selectedNode[2] !== itemName  &&
                                <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 10 }}>
                                <Button color="primary" type="button" size="sm" onClick={e => handleRenameNewRow(e, selectedNode)}>
                                    Save Name
                                </Button>
                                </div>
                            }
                        </FormGroup>
                        <FormGroup>
                        {(groupLevel === "Parent" || groupLevel === "Sub") && (
                            <>
                                <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                                    <div className="checkbox-option">
                                        <input
                                        type="checkbox"
                                        id="subWithChild"
                                        name="dashboardType"
                                        checked={isWithChild}
                                        onChange={() => setIsWithChild(!isWithChild)}
                                        />
                                        <label htmlFor="subWithChild" style={{ marginLeft: 10 }}>with child row?</label>
                                    </div>
                                    <Button
                                        color="primary"
                                        type="button"
                                        size="sm"
                                        onClick={e => handleAddRow(e, selectedNode, isWithChild)}
                                    >
                                        Add {groupLevel} Row
                                    </Button>
                                </div>
                                <Button
                                    color="info"
                                    type="button"
                                    size="sm"
                                    onClick={e => handleAddRow(e, selectedNode, false, true)}
                                    >
                                    Add Child Row
                                </Button>

                                {(selectedNode && selectedNode[0] && !selectedNode[0].includes("New Row")) && (
                                selectedNode[3] !== undefined &&  selectedNode[3] ? (
                                    <Button
                                    color="success"
                                    size="sm"
                                    type="button"
                                    onClick={e => onHandleHideRow(e, selectedNode, selectedNode[3])}
                                    >
                                    Show {groupLevel} Row
                                    </Button>
                                ) : (
                                    <Button
                                    color="danger"
                                    size="sm"
                                    outline
                                    type="button"
                                    onClick={e => onHandleHideRow(e, selectedNode, selectedNode[3])}
                                    >
                                    Hide {groupLevel} Row
                                    </Button>
                                )
                                )}
                            </>
                            )}


                            {groupLevel === "Child" && selectedNodes.length <= 1 && (
                                <>
                                    <Button color="primary" type="button" size="sm" onClick={e => handleAddRow(e, selectedNode)}>
                                        Add {groupLevel} Row
                                    </Button>

                                    {selectedNode[3] !== undefined && selectedNode[3] ? 
                                    <Button color="success" size="sm" type="button" onClick={e => onHandleHideRow(e, selectedNode, selectedNode[3])}>
                                        Show {groupLevel} Row
                                    </Button>
                                    :
                                    <Button color="danger" size="sm" outline type="button" onClick={e => onHandleHideRow(e, selectedNode, selectedNode[3])}>
                                        Hide {groupLevel} Row
                                    </Button>}
                                </>
                            )}

                            {(selectedNode[0].includes("grand-total") || selectedNode[0].includes("grandParent")) && selectedNodes.length <= 1 && (
                                <>
                                    <Button color="primary" type="button" size="sm" onClick={e => handleAddRow(e, selectedNode)}>
                                        Add Grand Parent Row
                                    </Button>
                                </>
                            )}

                            {(selectedNode[0] && selectedNode[0].includes("New Row"))  && groupLevel !== "Grouptotal" && selectedNodes.length === 1 &&(
                                <Button color="danger" size="sm" outline type="button" onClick={e => handleRemoveRow(e, selectedNode)}>
                                    Remove {groupLevel} Row
                                </Button>
                            )}
                        </FormGroup>
                        <FormGroup>
                            {selectedNodes.length > 1 &&
                                <div style={{ display: 'flex', gap: 10 }}>
                                    <Button color="success" type="button" size="sm" onClick={e => handleAddParentGroupings(e, selectedNode, isWithTotal)}>
                                        Add Group Row
                                    </Button>
                                </div>
                            }
                        </FormGroup>
                    </>
                    :
                    <>
                        <FormGroup>
                            <Label>Cell Id:</Label>
                            <Input 
                                value={selectedCellId}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Formula:</Label>
                            <Input 
                                type="textarea"
                                value={currentFormula}
                                onChange={handleFormulaChange}
                            />
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 10, gap: 0 }}>
                               
                                <Button color="danger" size="sm" outline type="button" onClick={handleCancelEdit}>
                                    Cancel
                                </Button>
                                <Button color="primary" type="button" size="sm" onClick={onHandleSaveFormula}>
                                    Save
                                </Button>
                                <div className="checkbox-option">
                                    <input
                                        type="checkbox"
                                        id="applyToAllFormula"
                                        name="dashboardType"
                                        checked={applyToAllFormula}
                                        onChange={() => setApplyToAllFormula(!applyToAllFormula)}
                                    />
                                    <label htmlFor="applyToAllFormula" style={{ marginRight: 10 }}>Apply to All Column?</label>
                                </div>
                            </div>
                        </FormGroup>
                    </>
                    }
                </Form>
                <div>
                    <div className='btmButton'>
                        <Button color="danger" type="button" size="sm" onClick={e => handleExitPage(e)}>
                            Exit
                        </Button>

                        {(dragAndDropData.length > 0 || newRowList.length > 0 || cellFormulaList.length > 0) &&
                            <Button color="danger" type="button" outline size="sm" onClick={e => handleRevertChanges(e)}>
                                Revert Changes
                            </Button>
                        }
                        <Button color="primary" type="button" size="sm" onClick={e => handleSaveChanges(e)}>
                            Save Changes
                        </Button>
                    </div>
                </div>
            </div>
        );
    };
    function Node({ node, style, dragHandle }) {
        return (
          <div style={style} ref={dragHandle} className='treeItemContainer'>
            <div 
                onClick={(e) => handleTreeItemClick(e, node.data.id, node.data.sqlId, node.data.name, node.data.isHidden)} 
                className={`treeItem ${node.data.id} ${
                node.data.id.includes('-sub-') ? 'sub-item' :
                node.data.id.includes('-parent-') ? 'parent-item' :
                node.data.id.includes('-grandParent-') ? 'grandParent-item' :
                node.data.id.includes('-child-') ? 'child-item' :
                node.data.id.includes('-group-') ? 'group-item' :
                node.data.id.includes('-groupTotal-') ? 'groupTotal-item' : ''} 
                ${node.data.isHidden ? 'rowHidden' : ''}
                ${node.data.id.includes('-TotalRow') ? 'tree-total-row' : ''}
                ${selectedNodes.includes(node.data.id) ? 'selectedItem' : ''}
                ${node.data.id === selectedNode[0] ? 'selectedItem' : ''} 
                ${node.data.id.includes("New Row") ? 'addedRow' : ''}`}>
                {node.data.name}
            </div>
          </div>
        );
    }
    const canDrag = (node) => {
        // Prevent dragging for nodes with '-TotalRow-' in their id
        return !node.data.id.includes('-TotalRow-') || !node.data.id.includes('grand-total');
    };
    return (
        
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%'}}>
            <div className='editGridContent'>
                {alert}
                <div className='editGridContainer' style={{ height: rowIndexCount * 30}}>
                    {treeData && treeData.length > 0 && (
                        <Tree
                            data={treeData}
                            width={400}
                            height={rowIndexCount * 37 + 500}
                            indent={24}
                            rowHeight={37}
                            overscanCount={1}
                            paddingTop={30}
                            paddingBottom={10}
                            padding={10}
                            overflow={'hidden'}
                            // marginBottom={5}
                            onMove={onMove}
                            canDrag={canDrag}
                        >
                            {Node}
                        </Tree>
                    )}
                </div>
                <div className='tableContainer'>
                    <GridContent
                        tableHeader={tableHeader}
                        treeData={treeData}
                        isWithChild={isWithChild}
                        selectedCellId={selectedCellId}
                        onEditCell={onEditCell}
                        cellFormulaList={cellFormulaList}
                        childListCellValue={childListCellValue}
                        gridGroupLevel={gridGroupLevel}
                        selectedCellFormula={selectedCellFormula}
                        handleCellDoubleClick={handleCellDoubleClick}
                        handleCellClick={handleCellClick}
                        processFormula={processFormula}
                    />
                </div>
            </div>
            <RenderSettings 
                selectedNode={selectedNode}
                selectedCellId={selectedCellId} 
                selectedRowId={selectedRowId}
                selectedRowSQLId={selectedRowSQLId}
                selectedCellFormula={selectedCellFormula}
                onEditCell={onEditCell}
                cellFormulaList={cellFormulaList}
                isGridWithChild={isWithChild}
                isGridSubWithChild={isSubWithChild}
                isSubWithChild={isSubWithChild}
                handleToggleCellEdit={handleToggleCellEdit}
                handleSaveFormula={handleSaveFormula}
                setSelectedCellFormula={setSelectedCellFormula}
                getCurrentFormula={getCurrentFormula}
                handleHideRow={handleHideRow}
                handleTreeItemClick={handleTreeItemClick}
            />
        </div>
    );
}
export default GridEditLayout;
