const ParseFormula = (formula) => {

    if (!formula.startsWith('=')) {
        const numberMatch = formula.match(/=(\d+\.?\d*)/); // Match numbers after '='
      
        if (numberMatch) {
            return { number: parseFloat(numberMatch[1]) };
        } else {
            // Handle letter references or other cases
            return { text: formula.trim() };
        }
    }

    const regex = /\[([^\]]+)\]/; // Regex to extract the part within brackets
    const match = formula.match(regex);

    if (match) {
        const components = match[1].split("."); // Split the components by comma
        
        // console.log(components)
        if (components.length < 4) {
            throw new Error("Invalid formula format. Expected format: [parent.sub.column.childIndex]");
        }

        const operationMatch = formula.match(/[\+\-\*\/]/); // Extract the operation

        if (operationMatch) {
            const operation = operationMatch[0]; // Extract the operation
            const additionalValue = parseFloat(formula.split(operation)[1].trim()); // Extract the additional value
            
            return {
                parent: components[0].trim(),
                sub: components[1].trim(),
                column: components[3].trim(),
                rowHead: components[2].trim(),
                operation: operation,
                additionalValue: additionalValue
            };
        } else {
            // Return only the components if no operation is found
            return {
                parent: components[0].trim(),
                sub: components[1].trim(),
                column: components[3].trim(),
                rowHead: components[2].trim(),
            };
        }
    } else {
        throw new Error("Invalid formula format. Expected format: =[parent.sub.column.childIndex]");
    }
}

const CalculateCellBaseValue = (sqlresult, parent, sub, columnIndex, rowHead, gridHeader) => {
    const parentSub = sqlresult.filter(item => item[0] === parent && item[1] === sub && item[2] === rowHead)
    return parentSub[0][gridHeader.indexOf(columnIndex)]
}

const RemoveCellChildFormula = (selectedCellID, formula, cellFormulaParam) => {
    const newData = cellFormulaParam.filter(formula => formula.cellID !== selectedCellID)
    return newData
}
const IsLastCharOperator = (str) => {
    const lastChar = str.charAt(str.length - 1);
    return ['+', '-', '*', '/', '='].includes(lastChar);
}
// const NewParseFormula = (formula) => {
//     console.log(formula)
//     if (!formula.startsWith('=')) {
//         const numberMatch = formula.match(/=(\d+\.?\d*)/); // Match numbers after '='
      
//         if (numberMatch) {
//             return { number: parseFloat(numberMatch[1]) };
//         } else {
//             // Handle letter references or other cases
//             return { text: formula.trim() };
//         }
//     }

//     const regex = /\[([^\]]+)\]/g; // Regex to extract all parts within brackets
//     const matches = [...formula.matchAll(regex)]; // Get all matches

//     if (matches.length === 0) {
//         throw new Error("Invalid formula format. Expected format: =[parent.sub.column.childIndex]");
//     }

//     const componentsList = matches.map(match => {
//         const components = match[1].split("."); // Split the components by dot

//         if (components.length < 4) {
//             throw new Error("Invalid formula format. Expected format: [parent.sub.column.childIndex]");
//         }

//         return {
//             parent: components[0].trim(),
//             sub: components[1].trim(),
//             column: components[3].trim(),
//             rowHead: components[2].trim(),
//         };
//     });

//     const operationMatches = formula.split(regex).filter(part => /[+\-*/]/.test(part));

//     if (operationMatches.length === 0) {
//         return { components: componentsList };
//     }

//     const operations = operationMatches.map(operation => operation.trim());

//     return {
//         components: componentsList,
//         operations: operations,
//     };
// }
const TransformSQLResult = (data, gridHeader, gridGroupLevel) => {
    const result = [];
    const newHeader = [...gridHeader]
    let dataIndex = 0

    // console.log({data, gridHeader, gridGroupLevel})

    if(gridGroupLevel === 3) {
        const parentMap = {};
        let sqlIndex = 0
    
        data.forEach((item) => {
            sqlIndex++
            const parentName = item[0];
            const subName = item[1];
            const childDetailName = item[2];
            const childDetailValue = item.slice(3); // Assuming you want to insert this value
    
            // Initialize the parent entry if not already present
            dataIndex++
            if (!parentMap[parentName]) {
                parentMap[parentName] = { dataIndex, parent: parentName, subItems: [] };
            }
    
            // Find or create the subItem for the current parent
            let subItem = parentMap[parentName].subItems.find(sub => sub.sub === subName);
            dataIndex++
            if (!subItem) {
                subItem = { dataIndex, sub: subName, childDetails: [] };
                parentMap[parentName].subItems.push(subItem);
            }
    
            // Add the childDetail to the subItem's childDetails array
            dataIndex++
            subItem.childDetails.push({
                dataIndex,
                name: childDetailName,
                value: childDetailValue
            });
        });

        // Transform the parentMap into the desired result array format
        for (const parent in parentMap) {
            const parentEntry = parentMap[parent];
            result.push({
                id: `${parentEntry.dataIndex}-parent-${parentEntry.parent}`,
                sqlId: `parent-${parentEntry.parent}`,
                name: parentEntry.parent,
                withTotalRow: true,
                children: parentEntry.subItems.map(subItem => ({
                    id: `${subItem.dataIndex}-sub-${parentEntry.parent}-${subItem.sub}`,
                    sqlId: `sub-${parentEntry.parent}-${subItem.sub}`,
                    name: subItem.sub,
                    withTotalRow: true,
                    children: subItem.childDetails.map(detail => ({
                        id: `${detail.dataIndex}-child-${parentEntry.parent}-${subItem.sub}-${detail.name}`,
                        sqlId: `child-${parentEntry.parent}-${subItem.sub}-${detail.name}`,
                        name: detail.name,
                        value: detail.value
                    }))
                }))
            });
        }
        
        return { result, newHeader: newHeader.splice(3), dataIndex  };
    }else if(gridGroupLevel === 2)
    {
        const parentMap = {};
        let sqlIndex = 0
        data.forEach((item) => {
            sqlIndex++
            const parentName = item[0];
            const childDetailName = item[1];
            const childDetailValue = item.slice(2); // Assuming you want to insert this value
            
            // Initialize the parent entry if not already present
            dataIndex++
            if (!parentMap[parentName]) {
                parentMap[parentName] = { dataIndex, parent: parentName, subItems: [] };
            }

            // Add the childDetail to the subItem's childDetails array
            dataIndex++
            parentMap[parentName].subItems.push({
                dataIndex,
                name: childDetailName,
                value: childDetailValue
            });
        });

        // Transform the parentMap into the desired result array format
        for (const parent in parentMap) {
            const parentEntry = parentMap[parent];
            result.push({
                id: `${parentEntry.dataIndex}-sub-${parentEntry.parent}`,
                sqlId: `sub-${parentEntry.parent}`,
                name: parentEntry.parent,
                withTotalRow: true,
                children: parentEntry.subItems.map(subItem => ({
                    id: `${subItem.dataIndex}-child-${parentEntry.parent}-${subItem.name}`,
                    sqlId: `child-${parentEntry.parent}-${subItem.name}`,
                    name: subItem.name,
                    value: subItem.value
                }))
            });
        }
        
        return { result, newHeader: newHeader.splice(2), dataIndex  };
    }else if(gridGroupLevel === 1) {
        data.map((item, index) => {
            dataIndex++
            const newItem = {
                id: `${dataIndex}-child-${item[0]}`,
                sqlId: `child-${item[0]}`,
                name: item[0],
                value: item.slice(1)
            }
            result.push(newItem)
        })

        return { result, newHeader: newHeader.splice(1), dataIndex };
    }
    return { result: [], newHeader: newHeader.splice(1), dataIndex: 0 };
};

const ProcessDnDTreeviewData = (dragAndDropData, treeData) => {
    let newData = [...treeData]

    dragAndDropData.map((data) => {
        const { dragIds, parentId, index } = data

        const findNode = (id, nodes) => {
            for (let node of nodes) {
                if (node.sqlId === id) return node;
                if (node.children) {
                    const found = findNode(id, node.children);
                    if (found) return found;
                }
            }
            return null;
        };

        const removeNode = (id, nodes) => {
            return nodes.filter(node => {
                if (node.sqlId === id) return false;
                if (node.children) node.children = removeNode(id, node.children);
                return true;
            });
        };

        const moveNode = (nodes, nodeToMove, parentId, index) => {
            if (parentId) {
                for (let node of nodes) {
                    if (node.sqlId === parentId) {
                        node.children.splice(index, 0, nodeToMove);
                    } else if (node.children) {
                        moveNode(node.children, nodeToMove, parentId, index);
                    }
                }
            } else {
                nodes.splice(index, 0, nodeToMove);
            }
        };

        const updatedData = JSON.parse(JSON.stringify(newData)); // Deep clone data
        
        const nodeToMove = dragIds.map(id => findNode(id, updatedData)).filter(Boolean)[0];
      
        const parentNode = findNode(parentId, updatedData);
        const nodeId = nodeToMove?.sqlId;

        // console.log("Node to Move:", nodeToMove);
        // console.log("Parent Node:", parentNode);

        // const nodeToMoveLevels = CountLevels(nodeToMove);
        // // console.log({nodeToMove, nodeToMoveLevels});
        const parentNodeLevels = parentNode
        // console.log({parentNode, parentNodeLevels});

        // console.log({nodeToMoveLevels, parentNode: parentNode?.children})
        
        if ((!parentNodeLevels && nodeToMove && !nodeToMove.id.includes("-groupTotal-")) ||
            (nodeToMove && nodeToMove.id.includes("-child-") && parentNode && parentNode.id.includes("-sub-")) ||
            (nodeToMove && nodeToMove.id.includes("-sub-") && parentNode && parentNode.id.includes("-parent-")) ||
            (nodeToMove && nodeToMove.id.includes("-child-") && parentNode && parentNode.id.includes("-parent-") ||
            nodeToMove && nodeToMove.id.includes("-group-") || 
            (parentNode && parentNode.id.includes("-group-") && nodeToMove && nodeToMove.id.includes("-child-")))
        ) {
            // Remove the node from the previous location
            const nodesAfterRemoval = removeNode(nodeId, updatedData);
        
            // Move node to the new location
            moveNode(nodesAfterRemoval, nodeToMove, parentId, index);
            newData = nodesAfterRemoval;
        } else {
            // Handle the case where the node cannot be moved due to parent-child level mismatch or missing nodes
            // console.warn("Node cannot be moved due to level mismatch or missing data.");
        }
        
    });
    
    return newData
}
const TableHeader = (index) => {
    const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
    let columnHeader = "";

    while (index >= 0) {
        columnHeader = alphabet[index % 26] + columnHeader;
        index = Math.floor(index / 26) - 1;
    }

    return columnHeader;
};
const PopulateNewRows = (rows, sortingData, gridHeader, gridGroupLevel) => {
    let _gridHeader = Array.isArray(gridHeader) ? [...gridHeader] : [];
    const res = TransformSQLResult(sortingData, _gridHeader, gridGroupLevel);
    let newTreeData = res.result;
    if(rows && rows.length === 0 || rows === undefined) return newTreeData
    // setRowIndexCount(res.dataIndex)

    rows.forEach((row) => {
        // if(row.id !== 'grand-total')
        console.log(row)

        const rowData = row.id.split("-");
        const groupLevel = rowData[1];

        if(groupLevel === 'group')
        {
            
            if(row.nextTo)
            {
                const existingIndex = newTreeData.findIndex(p => p.sqlId === row.sqlId)
                const index = newTreeData.findIndex(p => p.sqlId === row.nextTo)

                if (existingIndex === -1 && index !== -1)
                    newTreeData.splice(index + 1, 0, row)

                row.children.map((child) => {
                    if(child.sqlId.includes("-TotalRow")) return
                    const index = newTreeData.findIndex(c => c.sqlId === child.sqlId);
                    newTreeData.splice(index, 1);
                })
            } else {
                const firstId = row.children.length > 0 && row.children[0].sqlId

                if(firstId)
                    insertRowAboveFirstId(newTreeData, firstId, row)

                row && row.children && row.children.map((child) => {
                    const childId = child.sqlId
                    removeChildById(newTreeData, childId);
                })
            }

            function removeChildById(data, targetId) {
                for (let parent of data) {
                    if (parent.children && parent.children.length > 0) {
                        const index = parent.children.findIndex(child => child.sqlId === targetId);
                        if (index !== -1) {
                            parent.children.splice(index, 1);
                            return; 
                        }
                        
                        for (let sub of parent.children) {
                            if (sub.children && sub.children.length > 0) {
                                const index = sub.children.findIndex(child => child.sqlId === targetId);
                                if (index !== -1) {
                                    sub.children.splice(index, 1);
                                    return; 
                                }
                            }
                        }
                    }
                }
            }

            function insertRowAboveFirstId(data, firstId, row) {
                for (let parent of data) {
                    if (parent.children && parent.children.length > 0) {
                        const index = parent.children.findIndex(child => child.sqlId === firstId);
                        if (index !== -1) {
                            parent.children.splice(index, 0, row); // Insert row above firstId
                            return;
                        }

                        for (let sub of parent.children) {
                            if (sub.children && sub.children.length > 0) {
                                const index = sub.children.findIndex(child => child.sqlId === firstId);
                                if (index !== -1) {
                                    sub.children.splice(index, 0, row); // Insert row above firstId
                                    return;
                                }
                            }
                        }
                    }
                }
            }
        }
        
        if(row.parent) 
        {
            const parentId = row.parent.replace("-TotalRow", "")
            loopThruItems(newTreeData)

            function loopThruItems(newTreeData) {
                for(let i = 0; i < newTreeData.length; i++) {
                    const parent = newTreeData[i]
                    if(parent.sqlId === parentId) {
                        const existingIndex = parent.children.findIndex(p => p.sqlId === row.sqlId)

                        if (existingIndex === -1 && parent.children) {
                            parent.children.push(row)
                        }
                    }

                    if(parent.children) loopThruItems(parent.children)
                }
            }
        }

        if(row.nextTo) {
            const nextTo = row.nextTo.replace("-TotalRow", "")
            loopThruItems(newTreeData)

            function loopThruItems(newTreeData) {
                for(let i = 0; i < newTreeData.length; i++) {
                    const parent = newTreeData[i]
                    if(parent.sqlId === nextTo) {
                        const existingIndex = newTreeData.findIndex(p => p.sqlId === row.sqlId)
                        const index = newTreeData.findIndex(p => p.sqlId === nextTo)

                        if (existingIndex === -1 && index !== -1) {
                            newTreeData.splice(index + 1, 0, row)
                        }
                    }

                    if(parent.children) loopThruItems(parent.children)
                }
            }
        } else {
            const existingIndex = newTreeData.findIndex(p => p.sqlId === row.sqlId)
            if(existingIndex !== -1)
                newTreeData.push(row)
        }
    });
    return newTreeData;
};
// const PopulateNewRows = (rows, sortingData, gridHeader, gridGroupLevel) => {
//     let _gridHeader = Array.isArray(gridHeader) ? [...gridHeader] : [];
//     const res = TransformSQLResult(sortingData, _gridHeader, gridGroupLevel);
//     let newTreeData = res.result;
//     if(rows.length === 0) return newTreeData
//     // setRowIndexCount(res.dataIndex)
    
//     rows.forEach((row) => {
//         const rowData = row.id.split("-");
//         const groupLevel = rowData[1];
//         const parent = rowData[2];
//         const subName = rowData[3];
//         const parentData = newTreeData.find(item => item.name === parent);

//         // console.log(newTreeData)
//         if(row.sqlId !== "grand-total")
//         {
//             // console.log(gridGroupLevel)
//             // console.log(row)
//             // console.log(parentData)
//             // console.log(groupLevel)
//         }
//         // // Start searching from the top level of myData

//         // Recursively search the data structure to find the right spot for insertion
//         function findAndInsert(children, newData) {

//             // Check if newData is already present in the structure
//             const isAlreadyPresent = (items, id) => {
//                 for (let item of items) {
//                     if (item.sqlid === id) return true;
//                     if (item.children && isAlreadyPresent(item.children, id)) return true;
//                 }
//                 return false;
//             };

//             // If newData is already in the structure, return without inserting
//             if (isAlreadyPresent(children, newData.id)) {
//                 return false;
//             }

//             for (let i = 0; i < children.length; i++) {
//                 // Check if the current child's id matches newData.nextTo
//                 if (children[i].sqlId === newData.nextTo) {
//                     // Insert the newData after the found child
//                     children.splice(i + 1, 0, newData);
//                     return true; // Stop further searching once inserted
//                 }
//                 // If the current child has its own children, continue searching recursively
//                 if (children[i].children) {
//                     const found = findAndInsert(children[i].children, newData);
//                     if (found) return true;
//                 }
//             }
//             return false;
//         }

//         switch (groupLevel) {
//             case "child":
//                 if (parentData) {

//                     if(row.nextTo)
//                     {
//                         loopthruItems(newTreeData)

//                         function loopthruItems(newTreeData)
//                         {
//                             for (let i = 0; i < newTreeData.length; i++) {
//                                 const item = newTreeData[i]
                                

//                                 if(item.children)
//                                 {
//                                     if(parentData.sqlId === item.sqlId)
//                                     {    
//                                         const index = item.children.findIndex(i => i.sqlId === row.nextTo)
//                                         if(index !== -1) 
//                                         {
//                                             item.children.splice(index + 1, 0, row)
//                                         }
//                                     }
//                                     loopthruItems(item.children)
//                                 }
//                             }
//                         }
//                     } else {
//                         if (subName === row.name) {
//                             const existingChild = parentData.children.find(child => child.id === row.id);
//                             if (!existingChild) {
//                                 parentData.children.push(row);
//                             }
//                         } else {
//                             const subData = parentData.children && parentData.children.find(sub => sub.name === subName);
//                             if (subData) {
//                                 const existingChild = subData.children && subData.children.find(child => child.id === row.id);
//                                 if (!existingChild) {
//                                     if (subData.children)
//                                         subData.children.push(row);
//                                 }
//                             } else if (gridGroupLevel === 1) {
//                                 newTreeData.push(row);
//                             } else if (gridGroupLevel === 2) {
//                                 const existingChild = parentData.children.find(child => child.id === row.id);
//                                 if (!existingChild) {
//                                     parentData.children.push(row);
//                                 }
//                             }
//                         }
//                     }
                    
//                 } else if (gridGroupLevel === 1) {
//                     if(row.nextTo)
//                     {
//                         const index = newTreeData.findIndex(i => i.sqlId === row.nextTo)
//                         if(index !== -1) 
//                         {
//                             newTreeData.splice(index + 1, 0, row);
//                         }
//                     } else 
//                     newTreeData.push(row);
//                 }
//                 break;
//             case "sub":

//                 if (parentData)
//                 {
//                     if(row.nextTo)
//                     {
//                         for (let i = 0; i < newTreeData.length; i++) {
//                             if(newTreeData[i].children)
//                                 if (findAndInsert(newTreeData[i].children, row)) {
//                                     break;
//                                 }
//                         }
//                     } else {
//                         const existingSub = parentData.children.find(sub => sub.id === row.id);
//                         if (!existingSub) {
//                             parentData.children.push(row);
//                         }
//                     }
//                 }

//                 if(gridGroupLevel === 2)
//                 {
//                     if(row.nextTo)
//                     {
//                         const existingIndex = newTreeData.findIndex(p => p.sqlId === row.id)
//                         const index = newTreeData.findIndex(p => p.sqlId === row.nextTo)

//                         if (existingIndex === -1 && index !== -1) {
//                             newTreeData.splice(index + 1, 0, row)
//                         }

//                         for (let i = 0; i < newTreeData.length; i++) {
//                             if(newTreeData[i].children)
//                                 if (findAndInsert(newTreeData[i].children, row)) {
//                                     break;
//                                 }
//                         }
//                     } else {
//                         newTreeData.push(row);
//                     }
//                 }
                    
//                 break;
//             case "parent":
//                 if(row.nextTo)
//                 {
//                     const existingIndex = newTreeData.findIndex(p => p.id === row.id)
//                     const index = newTreeData.findIndex(p => p.id === row.nextTo)

//                     if (existingIndex === -1 && index !== -1) {
//                         newTreeData.splice(index + 1, 0, row)
//                     }
                    
//                 } else {
//                     const existingParent = newTreeData.find(item => item.id === row.id);
//                     if (!existingParent) {
//                         newTreeData.push(row);
//                     }
//                 }
               
//                 break;
//             case "group":
//                 if(gridGroupLevel === 3) {
//                     const firstId = row.children[0].sqlId
//                     insertRowAboveFirstId(newTreeData, firstId, row)

//                     row.children.map((child) => {
//                         const parent = child.id.split("-")[2]
//                         const sub = child.id.split("-")[3]
//                         const childId = child.sqlId
//                         removeChildById(newTreeData, childId);
//                     })

//                     function removeChildById(data, targetId) {
//                         for (let parent of data) {
//                             if (parent.children && parent.children.length > 0) {
//                                 for (let sub of parent.children) {
//                                     if (sub.children && sub.children.length > 0) {
//                                         const index = sub.children.findIndex(child => child.sqlId === targetId);
//                                         if (index !== -1) {
//                                             sub.children.splice(index, 1);
//                                             return; 
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     function insertRowAboveFirstId(data, firstId, row) {
//                         for (let parent of data) {
//                             if (parent.children && parent.children.length > 0) {
//                                 for (let sub of parent.children) {
//                                     if (sub.children && sub.children.length > 0) {
//                                         const index = sub.children.findIndex(child => child.sqlId === firstId);
//                                         if (index !== -1) {
//                                             sub.children.splice(index, 0, row); // Insert row above firstId
//                                             return;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 } else if(gridGroupLevel === 2) {

//                     const firstId = row.children[0].sqlId
//                     insertRowAboveFirstId(newTreeData, firstId, row)

//                     row.children.map((child) => {
//                         const childId = child.sqlId
//                         removeChildById(newTreeData, childId);
//                     })

//                     function insertRowAboveFirstId(data, firstId, row) {
//                         for (let parent of data) {
//                             if (parent.children && parent.children.length > 0) {
//                                 const index = parent.children.findIndex(child => child.sqlId === firstId);
//                                 if (index !== -1) {
//                                     parent.children.splice(index, 0, row);
//                                     return;
//                                 }
//                             }
//                         }
//                     }
//                     function removeChildById(data, targetId) {
//                         for (let parent of data) {
//                             if (parent.children && parent.children.length > 0) {
//                                 const index = parent.children.findIndex(child => child.sqlId === targetId);
//                                 if (index !== -1) {
//                                     parent.children.splice(index, 1);
//                                     return;
//                                 }
//                             }
//                         }
//                     }

//                 } else if (gridGroupLevel === 1) {
//                     if(row.nextTo)
//                     {
//                         const existingIndex = newTreeData.findIndex(p => p.sqlId === row.sqlId)
//                         const index = newTreeData.findIndex(p => p.sqlId === row.nextTo)

//                         if (existingIndex === -1 && index !== -1) {
//                             newTreeData.splice(index + 1, 0, row)
//                         }

//                     } else {
//                         const firstId = row.children[0].sqlId
//                         const index = newTreeData.findIndex(child => child.sqlId === firstId);

//                         newTreeData.splice(index, 0, row);
//                     }

//                     row.children.map((child) => {
//                         if(child.sqlId.includes("-TotalRow")) return
//                         const index = newTreeData.findIndex(c => c.sqlId === child.sqlId);
//                         newTreeData.splice(index, 1);
//                     })
//                 }

//                 if(row.isWithTotal) {
//                     const totalIndex = row.children.findIndex(c => c.sqlId === row.sqlId.replace("-group-", "-groupTotal-"))
//                     if(totalIndex !== -1)
//                     {
//                         row.children.splice(totalIndex, 1)
//                     }

//                     const totalValue = row.children.reduce((acc, child) => {
//                         return acc.map((sum, index) => 
//                             typeof child.value[index] === 'number' ? sum + child.value[index] : sum
//                         );
//                     }, Array(row.children[0].value.length).fill(0));
    
//                     const totalChild = {
//                         id: row.sqlId.replace("-group-", "-groupTotal-"),
//                         name: `Total ${row.name}`,
//                         value: totalValue 
//                     };

//                     row.children.push(totalChild)
//                 }
//                 break;
//             default:
//                 break;
//         }
//     });
//     return newTreeData;
// };
const NewParseFormula = (formula) => {
    const sumMatch = formula.match(/=sum\((.*)\)/i);
    const avgMatch = formula.match(/=avg\((.*)\)/i);

    if (sumMatch) {
        const [_, parent] = sumMatch[1].match(/([A-Z]+\d+)/);
        return { function: 'sum', parent: parent.trim() };
    } else if (avgMatch) {
        const [_, parent] = avgMatch[1].match(/([A-Z]+\d+)/);
        return { function: 'avg', parent: parent.trim() };
    }

    // Updated regex to handle text labels within brackets, cell references, and operators
    const regex = /(\[[^\]]+\])|([A-Z]+\d+)|([\+\-\*\/])|(-?\d+(\.\d+)?)/g;
    const matches = formula.match(regex);

    // Filter out empty matches
    const result = matches ? matches.filter(match => match.trim()) : [];

    return result;
};
const ReplaceCellIdsWithValues = (parsedFormula, cellFormulaList, childListCellValue, selectedCellID) => {
    return parsedFormula.map(token => {
        const newToken = token.trim()
        if (/[\+\-\*\/]/.test(newToken) && newToken.length === 1) {
            return newToken; // If the token is an operator or a number, return it as is
        } else {
            const cellFormula = cellFormulaList.length > 0 ? cellFormulaList.filter(f => f.cellId === newToken) : []
            const cell = childListCellValue.find(cell => cell.cellId === newToken);
          
            if(cellFormula.length > 0 && selectedCellID !== newToken)
            {
                return cellFormula[0].newValue
            }
          
            if(cell && cellFormula.length > 0 && selectedCellID !== newToken)
            {     
                if(cellFormula[0].originalValue !== cell.cellValue) 
                    return cell.cellValue
                else 
                    return cellFormula[0].newValue
            } else {
                if (cell) {
                    return cell.cellValue;
                } else {
                    if(isNaN(newToken))
                        return 0
                    else 
                        return newToken
                }
            }
        }
    });
};
const EvaluateFormula = (replacedFormula) => {
    let isPercentage = false

    if ((replacedFormula[0] === '-' || replacedFormula[0] === '+') && typeof replacedFormula[1] === 'number' && replacedFormula.length === 2) {
        replacedFormula[1] = replacedFormula[1] * -1;
        replacedFormula.shift();
    }

    const convertedFormula = replacedFormula.map(item => {
        if (typeof item === 'string' && item.includes('%')) {
            isPercentage = true
            return parseFloat(item.replace('%', '').trim())
        }
        return item;
    });
    const expression = convertedFormula.join('');

    let result;
    try {
        result = eval(expression);
    } catch (error) {
        // console.error('Error evaluating formula:', error);
        return (`${error}`).replace("ReferenceError: ", "");
    }

    // Convert the result back to a percentage string
    if(isPercentage) 
        return `${result.toFixed(2)} %`
    else
        return result;
};
const RecalculateWithTotal = (newData) => {
    // console.log(newData)

    findAllWithTotal(newData)

    function findAllWithTotal(parent) {
        for (let i = 0; i < parent.length; i++) {

            if(parent[i].isWithTotal)
            {
                const totalIndex = parent[i].children.findIndex(c => c.id === parent[i].id.replace("-group-", "-groupTotal-"))
                if(totalIndex !== -1)
                {
                    parent[i].children.splice(totalIndex, 1)
                }

                const totalValue = parent[i].children.reduce((acc, child) => {
                    return acc.map((sum, index) => 
                        typeof child.value[index] === 'number' ? sum + child.value[index] : sum
                    );
                }, Array(parent[i].children[0].value.length).fill(0));

                const totalChild = {
                    id: parent[i].id.replace("-group-", "-groupTotal-"),
                    name: `Total ${parent[i].name}`,
                    value: totalValue 
                };

                parent[i].children.push(totalChild)
            }
            
            
            if(parent[i].children && parent[i].children.length > 0) 
            {
                findAllWithTotal(parent[i].children)
            }
        }
    }

    return newData
}
const ProcessRenamingSQLData = (renamedSQLData, treeData) => {
    for(let d = 0; d < renamedSQLData.length; d++)
    {
        const id = renamedSQLData[d].id
        const newName = renamedSQLData[d].newName
        findItemToRename(treeData, id, newName)
    }

    function findItemToRename (parent, id, newName) 
    {
        for (let i = 0; i < parent.length; i++) {
                         
            if(parent[i].sqlId === id) {

                parent[i].name = newName
            }

            if(parent[i].children && parent[i].children.length > 0) 
            {
                findItemToRename(parent[i].children, id, newName)
            }
        }
    }
    return treeData
}
const ProcessRemoveRow = (treeData, hiddenRow) => {
    if(!hiddenRow) return treeData
    for(let r = 0; r < hiddenRow.length; r++)
    {
      const id = hiddenRow[r]
      const index = treeData ? treeData.findIndex(d => d.sqlId === id) : -1

      if(index !== -1)
      {
        treeData.splice(index, 1)
      }

      for(let d = 0; d < treeData.length; d++)
      {
        if(treeData[d].children)
        {
          const index = treeData[d].children ? treeData[d].children.findIndex(d => d.sqlId === id) : -1
          if(index !== -1)
          {
            treeData[d].children.splice(index, 1)
          }
          findItemToRemove(treeData[d].children, id)
        }
       
      }
    }

    function findItemToRemove (parent, id) 
    {
      for(let d = 0; d < parent.length; d++)
      {
        if(parent[d].children)
        {
          const index = parent[d].children ? parent[d].children.findIndex(d => d.sqlId === id) : -1
          if(index !== -1)
          {
            parent[d].children.splice(index, 1)
          }
          findItemToRemove(parent[d].children, id)
        }
      }
    }

    return treeData
}
const GetCellIdValue = (newTreeData) => {
    const cellIdValue = []
       
    getFindCellValue(newTreeData)

    function getFindCellValue(parent)
    {
        for (let i = 0; i < parent.length; i++) {
            if(parent[i].value) {
                parent[i].value.map((val, index) => {
                    const data = {
                        id: parent[i].id,
                        sqlId: parent[i].sqlId,
                        cellId: getCellId(parent[i].sqlId, parent, i,index),
                        cellValue: typeof val === 'string' ? 0: val
                    }
                    cellIdValue.push(data)
                })
            }

            if(parent[i].children) {
                getFindCellValue(parent[i].children)
            }
        }
    }

    function getCellId(sqlId, parent, i, index) {
        try {
            if(sqlId.includes("-TotalRow"))
            {
                const parts = sqlId.split('-');
                const secondToLastItem = parts.slice(1, -1).join('-');
                return `[${secondToLastItem}-${TableHeader(index)}]`
            }
            if(sqlId.includes("grand-total")) {
                return `[${sqlId}-${TableHeader(index)}]`
            }
            return `${TableHeader(index)}${parent[i].id.split("-")[0]}`
        }catch(error) {
            return `${TableHeader(index)}${parent[i].id.split("-")[0]}`
        }
    }

    return cellIdValue
}
function GenerateUniqueID()
{
    let uuid = '';
    const characters = 'abcdef0123456789';
  
    for (let i = 0; i < 14; i++) {
      if (i === 4 || i === 9) {
        uuid += '-';
      } else {
        uuid += characters[Math.floor(Math.random() * characters.length)];
      }
    }
    return uuid;
}
function CalculateSubTotal(node, newTreeData, cellIdValue, gridHeader, gridGroupLevel, cellFormulaList) {
    // Determine the length of the values array from the first item in newTreeData
    let length = gridHeader ? gridHeader.length - gridGroupLevel : 0
    // Initialize subtotal and containsString arrays
    let subtotal = Array(length).fill(0);
    let containsString = Array(length).fill(false);
  
 
    // Check if the node should be skipped
    if (node.id && node.id.includes("groupTotal")) {
      // Skip this node and return the initial subtotal
      return subtotal;
    }

 
    // Base case: If the node has no children, process its values
    if (!node.children || node.children.length === 0) {
      if (node.value) {
        // Map values, converting to numbers or "-" if non-numeric
        // console.log(node)
        return node.value.map((v, i) => {
            // console.log(node.id, v, i)
            const cellId = `${TableHeader(i)}${node.id.split("-")[0]}`
            const formulaData = cellFormulaList && cellFormulaList.find(f => f.rowId === node.id && f.cellId === cellId )
            const cellData = cellIdValue && cellIdValue.find(i => i.id === node.id && i.cellId === cellId)
            const amount = formulaData ? formulaData.newValue : cellData ? cellData.cellValue : 0
            return (isNaN(amount) ? "-" : parseFloat(amount) || 0)
        });
      }
      return subtotal; // Return initial subtotal if no values are found
    }
  
    // Recursive case: Traverse each child node
    for (const child of node.children) {
      const childTotal = CalculateSubTotal(child, newTreeData);
  
      // Accumulate child totals into subtotal
      for (let i = 0; i < subtotal.length; i++) {
        if (isNaN(childTotal[i])) {
          containsString[i] = true; // Mark if any child total is non-numeric
        } else {
          subtotal[i] += parseFloat(childTotal[i]) || 0; // Accumulate numeric values
        }
      }
    }
  
    // Return the final subtotal, replacing numeric values with "-" where needed
    return subtotal.map((total, index) => (containsString[index] ? "-" : total));
}
const ProcessGrandTotal = (newTreeData, cellIdValue, newRowList, gridHeader, gridGroupLevel, cellFormulaList) => {
    const toSum = []
        loopThruItems(newTreeData)
        function loopThruItems(newTreeData)
        {
            for(let i = 0; i < newTreeData.length; i++)
            {
                if(newTreeData[i].children)
                {
                    
                    const subTotal = calculateSubTotal({ children: newTreeData[i].children }, newTreeData[i].children);
                    toSum.push(subTotal)
                } else {
                    if (newTreeData[i].value) {
                        const subTotal = calculateSubTotal(newTreeData[i] , newTreeData[i].children);
                        toSum.push(subTotal)
                    }
                }
            }
        }

        function childCounter(row, childCount) {
            for (let i = 0; i < row.length; i++) 
            {
                const parent = row[i];
                if(parent.value && !parent.id.includes('-TotalRow'))
                {
                    childCount++;
                }

                if (parent.children) {
                    // Recursively count children and update childCount
                    childCount = childCounter(parent.children, childCount);
                }
            }
            // Return the accumulated child count
            return childCount;
        }

        const sumColumns = (array) => {
             // Check if the input array is defined and has at least one element
            if (!array || array.length === 0 || !Array.isArray(array[0])) {
                console.error("Invalid input: The array is undefined, empty, or not a 2D array.");
                return [];
            }

            const sums = [];
            let rowCount = 0
            // Iterate over each column index
            for (let col = 0; col < array[0].length; col++) {
              let sum = 0;
              // Sum each value in the current column
              for (let row = 0; row < array.length; row++) {
                sum += array[row][col];
              }

              sums.push(sum);
              rowCount++
            }
            // console.log(rowCount)
            return sums;
        };

        let childCount = childCounter(newTreeData, 0);

        // Calculate the sum for each column
        const columnSums = sumColumns(toSum);

        const newRow = {
            "id": "grand-total",
            "sqlId": "grand-total",
            "name": "Grand Total",
            "value": reformatSumPercentage(columnSums, childCount)
        }

        function reformatSumPercentage (newSub, loopCount)
        {
            const result = newSub.map((item, i) => {
                if(gridHeader[i + gridGroupLevel].includes("%")) return `${(item / loopCount).toFixed(2)} %`
                else return item
            })
            return result
        }

        newTreeData.push(newRow)
        return newTreeData
        
        function calculateSubTotal(node, newTreeData) {
            // Determine the length of the values array from the first item in newTreeData
            let length = gridHeader.length - gridGroupLevel
            // Initialize subtotal and containsString arrays
            let subtotal = Array(length).fill(0);
            let containsString = Array(length).fill(false);
          
         
            // // Check if the node should be skipped
            // if (node.id && node.id.includes("groupTotal")) {
            //   // Skip this node and return the initial subtotal
            //   return subtotal;
            // }

            // Check if the node should be skipped
            if (node.id && (node.id.includes("groupTotal") 
            || (node.id.includes("-parent-") && node.value)
            || (node.id.includes("-sub-") && node.value))) {
            // Skip this node and return the initial subtotal
                return subtotal;
            } 
         
            // Base case: If the node has no children, process its values
            if (!node.children || node.children.length === 0) {
              if (node.value) {
                // Map values, converting to numbers or "-" if non-numeric
          
                return node.value.map((v, i) => {
                    // console.log(node.id, v, i)
                    const cellId = `${TableHeader(i)}${node.id.split("-")[0]}`
                    const formulaData = cellFormulaList.find(f => f.rowId === node.id && f.cellId === cellId )
                    const cellData = cellIdValue.find(i => i.id === node.id && i.cellId === cellId)
                    const amount = formulaData ? formulaData.newValue : cellData ? cellData.cellValue : 0
                    return (isNaN(amount) ? "-" : parseFloat(amount) || 0)
                });
              }
              return subtotal; // Return initial subtotal if no values are found
            }
          
            // Recursive case: Traverse each child node
            for (const child of node.children) {
              const childTotal = calculateSubTotal(child, newTreeData);
          
              // Accumulate child totals into subtotal
              for (let i = 0; i < subtotal.length; i++) {
                if (isNaN(childTotal[i])) {
                  containsString[i] = true; // Mark if any child total is non-numeric
                } else {
                  subtotal[i] += parseFloat(childTotal[i]) || 0; // Accumulate numeric values
                }
              }
            }
          
            // Return the final subtotal, replacing numeric values with "-" where needed
            return subtotal.map((total, index) => (containsString[index] ? "-" : total));
        }
}
const ProcessGrandParentRow = (rows, newTreeData) => {
    rows.forEach((row) => {
        const rowData = row.id.split("-");
        const groupLevel = rowData[1];
        switch (groupLevel) {
            case "grandParent":
                if(row.nextTo)
                {
                    const existingIndex = newTreeData.findIndex(p => p.sqlId === row.sqlId)
                    const index = newTreeData.findIndex(i => i.sqlId === row.nextTo)

                    if (existingIndex === -1 && index !== -1) {
                        newTreeData.splice(index + 1, 0, row)
                    }else {
                        newTreeData.push(row)
                    }
                }
                break;
            default:
                break;
        }
    });

    return newTreeData
}
const ProcessGroupWithTotal = (newTreeData, cellIdValue, gridHeader, gridGroupLevel, cellFormulaList) => {
    loopThruItems(newTreeData, 0)
    function loopThruItems(newTreeData, depthLevel)
    {
        for(let i = 0; i < newTreeData.length; i++)
        {
            const row = newTreeData[i]
         
            if(row.children)
            {
                let childCount = childCounter(row.children, 0);
                const newSub = calculateSubTotal({ children: row.children }, row.children);
                // console.log(row.name, { row, newSub, loopCount, childCount, depthLevel });

                const newChild = {
                    "id": `${row.id}-TotalRow`,
                    "sqlId": `${row.sqlId}-TotalRow`,
                    "name": `Total ${row.name}`,
                    "value": reformatSumPercentage(newSub, childCount)
                }

                const index = row.children ? row.children.findIndex(d => d.id === newChild.id) : -1
                if(index !== -1)
                {
                    row.children.splice(index, 1)
                }

                row.children.push(newChild)
                loopThruItems(row.children, depthLevel + 1)
            } 
        }
    }

    function childCounter(row, childCount) {
        for (let i = 0; i < row.length; i++) 
        {
            const parent = row[i];
            if(parent.value && !parent.id.includes('-TotalRow'))
            {
                childCount++;
            }

            if (parent.children) {
                // Recursively count children and update childCount
                childCount = childCounter(parent.children, childCount);
            }
        }
        // Return the accumulated child count
        return childCount;
    }

    function reformatSumPercentage (newSub, loopCount)
    {
        const result = newSub.map((item, i) => {
            if(gridHeader[i + gridGroupLevel].includes("%")) return `${(item / loopCount).toFixed(2)} %`
            else return item
        })
        return result
    }

    return newTreeData
 
    function calculateSubTotal(node, newTreeData) {
        // Determine the length of the values array from the first item in newTreeData
        let length = gridHeader.length - gridGroupLevel
        // Initialize subtotal and containsString arrays
        let subtotal = Array(length).fill(0);
        let containsString = Array(length).fill(false);
      
     
        // Check if the node should be skipped
        if (node.id && node.id.includes("groupTotal")) {
          // Skip this node and return the initial subtotal
          return subtotal;
        }
     
        // Base case: If the node has no children, process its values
        if (!node.children || node.children.length === 0) {
          if (node.value) {
            // Map values, converting to numbers or "-" if non-numeric
            // console.log(node)
            return node.value.map((v, i) => {
                // console.log(node.id, v, i)
                const cellId = `${TableHeader(i)}${node.id.split("-")[0]}`
                const formulaData = cellFormulaList.find(f => f.rowId === node.id && f.cellId === cellId )
                const cellData = cellIdValue.find(i => i.id === node.id && i.cellId === cellId)
                const amount = formulaData ? formulaData.newValue : cellData ? cellData.cellValue : 0
                return (isNaN(amount) ? "-" : parseFloat(amount) || 0)
            });
          }
          return subtotal; // Return initial subtotal if no values are found
        }
      
        // Recursive case: Traverse each child node
        for (const child of node.children) {
            const childTotal = calculateSubTotal(child, newTreeData);

            // Accumulate child totals into subtotal
            if(childTotal.newSub)
            {
                for (let i = 0; i < subtotal.length; i++) {
                    if (isNaN(childTotal.newSub[i])) {
                    containsString[i] = true; // Mark if any child total is non-numeric
                    } else {
                    subtotal[i] += parseFloat(childTotal.newSub[i]) || 0; // Accumulate numeric values
                    }
                }
            } else {
                for (let i = 0; i < subtotal.length; i++) {
                    if (isNaN(childTotal[i])) {
                    containsString[i] = true; // Mark if any child total is non-numeric
                    } else {
                    subtotal[i] += parseFloat(childTotal[i]) || 0; // Accumulate numeric values
                    }
                }
            }
           
        }
      
        // Return the final subtotal, replacing numeric values with "-" where needed
        return subtotal.map((total, index) => (containsString[index] ? "-" : total));
    }
}

const UpdateCellWithFormula = (treeData, cellValue, cellFormulaList) => {

    loopThruItems(treeData)
    
    function loopThruItems(treeData) 
    {
      for(let i = 0; i < treeData.length; i++)
      {
        const row = treeData[i]
        // console.log(row)
        const sqlId = row.sqlId
        
        const isRowWithFormula = cellFormulaList.filter(i => i.sqlId === sqlId)
        const rowCellValue = cellValue.find(i => i.sqlId === sqlId)

        if(isRowWithFormula.length > 0) 
        {
          if(row.value) {
            isRowWithFormula.length > 0 && row.value && row.value.map((val, index) => {
              const newVal = processNewCellValue(row, index, isRowWithFormula, cellValue, val, cellFormulaList)
              row.value[index] = newVal
            })
            // console.log(row)
          }
        }

        if(row.children) loopThruItems(row.children)
      }
    }

    return treeData
}
const processNewCellValue = (group, index, withFormula, cellValue, val, cellFormulaList) => {
    const rowIndex = group.id.split("-")[0]
    const cellId = `${TableHeader(index)}${rowIndex}`
    const formulaDetails = withFormula.find((f => f.cellId === cellId))
    if(formulaDetails) 
    {
      const newRes = processCellFormula(formulaDetails.formula, cellValue, cellId, cellFormulaList)
      return newRes
    }
    return val
}
const processCellFormula = (formula, cellValue, cellId, cellFormulaList) => {
    const parsedFormula = NewParseFormula(formula)
    // console.log(parsedFormula)
    if(!parsedFormula) return formula
    // if(parsedFormula.length === 1) return parsedFormula[0]

    const replacedFormula = ReplaceCellIdsWithValues(parsedFormula, cellFormulaList, cellValue, cellId)
    // console.log(replacedFormula)
    const finalResult = EvaluateFormula(replacedFormula);
    // console.log(finalResult)
    return finalResult
}
const ProcessEditedGridCellData = (item, sqlresult, gridHeader) => {
    if(item && item.editedGridCellData)
    {
        const { gridGroupLevel, newRowList, dragAndDropData, cellFormulaList, renamedSQLData, hiddenRow, renamedHeaderList } = item.editedGridCellData
        
        const newGridHeader = ProcessColumnRename(renamedHeaderList, gridHeader)

        let treeData = []
        treeData = TransformSQLResult(sqlresult, newGridHeader, gridGroupLevel)
        treeData = PopulateNewRows(newRowList, sqlresult, newGridHeader, gridGroupLevel)

        if(dragAndDropData.length > 0) 
        {
            treeData = ProcessDnDTreeviewData(dragAndDropData, treeData)
            const isWithGroup = dragAndDropData.filter(g => g.parentId && g.parentId.includes("-group-"))
            if(isWithGroup.length > 0)
            {
                treeData = RecalculateWithTotal(treeData)
            }
        }

        let cellValue = GetCellIdValue(treeData)

        treeData = ProcessGroupWithTotal(treeData, cellValue, newGridHeader, gridGroupLevel, cellFormulaList)
    
        const processRenaming = (treeData) => {
            if (renamedSQLData && renamedSQLData.length > 0) {
                return ProcessRenamingSQLData(renamedSQLData, treeData);
            }
            return treeData;
        };

        treeData = ProcessGrandTotal(treeData, cellValue, newRowList, newGridHeader, gridGroupLevel, cellFormulaList)
        cellValue = GetCellIdValue(treeData)
        treeData = ProcessGrandParentRow(newRowList, treeData)
        treeData = processRenaming(treeData);
        treeData = ProcessRemoveRow(treeData, hiddenRow)
        if(cellFormulaList) {
            cellValue = GetCellIdValue(treeData)
            treeData = UpdateCellWithFormula(treeData, cellValue, cellFormulaList)
        }
        return treeData
    }
    return null
}
const ProcessColumnRename = (renamedHeaderList, gridHeader) => {
    if (renamedHeaderList?.length === 0) return gridHeader
    const newColumnHeader = [...gridHeader]

    renamedHeaderList && renamedHeaderList.map((item, index) => {
        newColumnHeader[item.colIndex] =  item.newHeader
    })

    return newColumnHeader
}
const ProcessSQLGroupWithTotal = (newTreeData, columnHeader, gridGroupLevel) => {
    loopThruItems(newTreeData, 0)

    function loopThruItems(newTreeData, depthLevel)
    {
        for(let i = 0; i < newTreeData.length; i++)
        {
            const row = newTreeData[i]
         
            if(row.children)
            {
                
                let childCount = childCounter(row.children, 0);

                const newSub = calculateSubTotal({ children: row.children }, row.children);
                const newChild = {
                    "id": `${row.id}-TotalRow`,
                    "sqlId": `${row.sqlId}-TotalRow`,
                    "name": `Total ${row.name}`,
                    "value": reformatSumPercentage(newSub, childCount)
                }
                const index = row.children ? row.children.findIndex(d => d.id === newChild.id) : -1
                if(index !== -1)
                {
                    row.children.splice(index, 1)
                }

                row.children.push(newChild)
                loopThruItems(row.children, depthLevel + 1)
            } 
        }
    }

    function childCounter(row, childCount) {
        for (let i = 0; i < row.length; i++) 
        {
            const parent = row[i];
            if(parent.value && !parent.id.includes('-TotalRow'))
            {
                childCount++;
            }

            if (parent.children) {
                // Recursively count children and update childCount
                childCount = childCounter(parent.children, childCount);
            }
        }
        // Return the accumulated child count
        return childCount;
    }

    function reformatSumPercentage(newSub, loopCount) {
        const result = newSub.map((item, i) => {
            const headerItem = columnHeader[i + gridGroupLevel];
            
            if (headerItem?.includes("%")) {
                return `${(item / loopCount).toFixed(2)} %`;
            } else {
                return item;
            }
        });
        return result;
    }
    
    return newTreeData
 
    function calculateSubTotal(node, newTreeData) {

        // console.log({ node, newTreeData })
        // Determine the length of the values array from the first item in newTreeData
        let length = columnHeader.length - gridGroupLevel
        // Initialize subtotal and containsString arrays
        let subtotal = Array(length).fill(0);
        let containsString = Array(length).fill(false);
      
     
        // Check if the node should be skipped
        if (node.id && node.id.includes("groupTotal")) {
          // Skip this node and return the initial subtotal
          return subtotal;
        }
     
        // Base case: If the node has no children, process its values
        if (!node.children || node.children.length === 0) {
          if (node.value) {
            // Map values, converting to numbers or "-" if non-numeric
            return node.value.map((v, i) => {
                // console.log(node.id, v, i)
                const amount =  node.value[i]
                return (isNaN(amount) ? "-" : parseFloat(amount) || 0)
            });
          }
          return subtotal; // Return initial subtotal if no values are found
        }
      
        // Recursive case: Traverse each child node
        for (const child of node.children) {
            const childTotal = calculateSubTotal(child, newTreeData);

            // Accumulate child totals into subtotal
            if(childTotal.newSub)
            {
                for (let i = 0; i < subtotal.length; i++) {
                    if (isNaN(childTotal.newSub[i])) {
                    containsString[i] = true; // Mark if any child total is non-numeric
                    } else {
                    subtotal[i] += parseFloat(childTotal.newSub[i]) || 0; // Accumulate numeric values
                    }
                }
            } else {
                for (let i = 0; i < subtotal.length; i++) {
                    if (isNaN(childTotal[i])) {
                    containsString[i] = true; // Mark if any child total is non-numeric
                    } else {
                    subtotal[i] += parseFloat(childTotal[i]) || 0; // Accumulate numeric values
                    }
                }
            }
           
        }
        // console.log(subtotal)
        // Return the final subtotal, replacing numeric values with "-" where needed
        return subtotal.map((total, index) => (containsString[index] ? "-" : total));
    }
}
export { ParseFormula, RemoveCellChildFormula, CalculateCellBaseValue, IsLastCharOperator, TransformSQLResult,
    ProcessDnDTreeviewData, TableHeader, PopulateNewRows, NewParseFormula, ReplaceCellIdsWithValues, 
    EvaluateFormula, RecalculateWithTotal, ProcessRenamingSQLData, ProcessRemoveRow, GetCellIdValue, 
    GenerateUniqueID, CalculateSubTotal, ProcessGrandTotal, ProcessGrandParentRow, ProcessGroupWithTotal,
    UpdateCellWithFormula, ProcessEditedGridCellData, ProcessSQLGroupWithTotal }