import { MainContext } from 'context/mainContext';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import { ChromePicker } from 'react-color'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { defaultControl } from 'services/EVAVisualService';

function VisualPieChartView({ gridHeader, selectedViewOption, sqlresult, item, treeViewData, headerToMultiSelect, handleSaveVisual, templateItemVisual, onEditVisual, handleCloseEditVisual, onEditName }) {
    const {
        bgColor,
        textColor
    } = useContext(MainContext);

    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [selectedDataList, setSelectedDataList] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([{ label: '', data: '', color: defaultControl(0), isOpen: false }]);
    const [chartDataSet, setChartDataSet] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState('#ffffff');
    const [colors, setColors] = useState([]);
    const [chartHeader, setChartHeader] = useState([])
    const [selectedGraphHeaderOption, setSelectedGraphHeaderOption] = useState(null)
    const [pieItem, setPieItem] = useState(null)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [gridGroupLevel, setGridGroupLevel] = useState(null)
    const [newUpdatesCount, setNewUpdatesCount] = useState(0)

    let chartData = {
        labels: [],
        datasets: [ {
            label: '',
            data: [],
            backgroundColor: [],
            hoverOffset: 10,
        }],
    }
    const onHandleSaveVisual = () => {
        const param = {
            selectedGroup: selectedGroup,
            selectedGroups: selectedGroups,
            chartHeader: chartHeader,
            selectedLabel: selectedLabel,
            selectedGraphHeaderOption: selectedGraphHeaderOption,
            selectedData: selectedData,
            pieItem: pieItem,
            selectedViewOption: selectedViewOption
        }
        handleSaveVisual(param)
    }
    useEffect(() => {
        if(!templateItemVisual) return
       
        const { 
            selectedGroup,
            selectedGroups,
            chartHeader,
            selectedLabel,
            selectedData,
            // pieItem,
            selectedFooter,
            headerToMultiSelect,
            selectedViewOption
        } = templateItemVisual

        if(selectedViewOption !== 'Pie Chart' || selectedViewOption === undefined) return
        if(item && item.editedGridCellData) setGridGroupLevel(item.editedGridCellData.gridGroupLevel)

        const label = getPieChartLabel(selectedLabel?.id)
        // console.log(label)
        const pieItem = getPieChartLabelGroup(label)
        // console.log(pieItem)
        // if(selectedLabel && selectedData)
        // {
        //     const data = getPieChartData(selectedData.id, pieItem)
        //     setSelectedDataList(data)
        // }

        setTimeout(() => {
            if(gridGroupLevel)
                handleSelectDataChange(selectedData.label)
        }, 200);
        


        setPieItem(pieItem)
        setSelectedLabel(selectedLabel)
        setSelectedData(selectedData)
    }, [templateItemVisual, gridGroupLevel])

    useEffect(() => {
        if(item && item.editedGridCellData) setGridGroupLevel(item.editedGridCellData.gridGroupLevel)
    }, [item])

    useEffect(() => {
        let label
        let pieItem
        if(selectedLabel)
        {
            label = getPieChartLabel(selectedLabel.id)
            pieItem = getPieChartLabelGroup(label)
        }

        if(selectedLabel && selectedData)
        {
            const data = getPieChartData(selectedData.id, pieItem)
            console.log(data)
            setSelectedDataList(data)
        }

        // console.log(selectedGroup)
        // console.log(selectedLabel)
        // console.log(selectedData)
    },[selectedGroups, selectedLabel, selectedData, selectedGraphHeaderOption, gridGroupLevel])

    useEffect(() => {
        const colors = []

        if(pieItem)
        pieItem.forEach((item) => {
            colors.push(item.color)
        })

        if(colors.length > 0 && chartDataSet)
        {
            setChartDataSet(null)
            const newDataSet = chartDataSet
            newDataSet.datasets[0].backgroundColor = colors
            setTimeout(() => {
                setChartDataSet(newDataSet)
            }, 100);
        }

        console.log(pieItem)
        // onHandleSaveVisual()
    },[pieItem])

    useEffect(() => {
        console.log(chartDataSet)
        // console.log(chartHeader)
    },[chartDataSet, pieItem])

    useEffect(() => {
    }, [newUpdatesCount]);

    const getPieChartLabel = (id) => {
        const label = []
        const chartLabel = []
        if(gridGroupLevel) {
            let currentIndentLevel = 0;
            // console.log(selectedGraphHeaderOption)
            if(selectedGraphHeaderOption)
            { 
                loopThruItemsWithFilter(id, treeViewData)
            } else {
                loopThruItems(id, treeViewData);
            }

            function loopThruItems(indentLevel, treeViewData) {
                const rowsToSum = [];
                
                for (let i = 0; i < treeViewData.length; i++) {
                    const row = treeViewData[i];

                    if (currentIndentLevel === id) {
                        if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                            const param = {id: row.sqlId, name: row.name}
                            chartLabel.push(row.name)
                            label.push(param)
                        }
                    }

                    if (row.children) {
                        currentIndentLevel++;
                        const childRowsToSum = loopThruItems(indentLevel, row.children);
                        rowsToSum.push(...childRowsToSum); // Accumulate results from child nodes
                        currentIndentLevel--;
                    }
                }
                return rowsToSum;
            }

            function loopThruItemsWithFilter(indentLevel, treeViewData) {
                for (let i = 0; i < treeViewData.length; i++) {
                    const row = treeViewData[i];
                    if(row.name === selectedGraphHeaderOption)
                    {
                        // console.log(row)
                        if(row.children) {
                            currentIndentLevel++;
                            loopThruParentChild(indentLevel, row.children)
                            currentIndentLevel--;
                        }
                    

                        function loopThruParentChild (indentLevel, parent)
                        {
                            for (let i = 0; i < parent.length; i++) { 
                                const row = parent[i];
                                if (currentIndentLevel === id) {
                                    if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                                        const param = {id: row.sqlId, name: row.name}
                                        chartLabel.push(row.name)
                                        label.push(param)
                                    }
                                }

                                if (row.children) {
                                    currentIndentLevel++;
                                    loopThruParentChild(indentLevel, row.children);
                                    currentIndentLevel--;
                                }
                            }
                        }
                    }

                    if (row.children) {
                        currentIndentLevel++;
                        loopThruItemsWithFilter(indentLevel, row.children);
                        currentIndentLevel--;
                    }
                }
            }
            chartData.labels = chartLabel
            return label
        } else {
            sqlresult.forEach(element => {
                if(selectedGraphHeaderOption)
                {
                    if(!label.includes(element[id]) && element[chartHeader.fieldIndex] === selectedGraphHeaderOption)
                    chartLabel.push(element[id])
                    label.push(element[id])
                } else {
                    if(!label.includes(element[id]))
                    chartLabel.push(element[id])
                    label.push(element[id])
                }
            });
            chartData.labels = chartLabel
            return label
        }
    }
    const getPieChartLabelGroup = (label) => 
    {
        const group = [];
        label.forEach((element, index) => {
            group.push({ 
                data: element, 
                color: pieItem && pieItem[index] && pieItem[index].color ? pieItem[index].color : defaultControl(index), 
                isOpen: false
            });
        });
        setPieItem(group)
        return group
    };
    const getPieChartData = (id, pieitem) => {
        const data = []
        const color = []

        if(selectedGraphHeaderOption || selectedGroup)
        {
            pieitem && pieitem.length > 0 && pieitem.forEach((item, index) => 
            {
                let totalAmount = 0
                if(gridGroupLevel) {
                   
                    loopThruItems(treeViewData)
                    function loopThruItems(treeViewData) {
                        for (let i = 0; i < treeViewData.length; i++) {
                            const row = treeViewData[i];
                            if(row.sqlId === item.data.id) {
                                if (!row.id.includes("-TotalRow") && 
                                    row.id !== "grand-total" && 
                                    !row.id.includes("-grandParent-")) 
                                {
                                    if(row.value)
                                    {
                                        totalAmount += row.value[id - gridGroupLevel]
                                    }

                                    if (row.children) {
                                        loopThruParentChild(row.children);
                                    }
                                }
                            }
                            if (row.children) {
                                loopThruItems(row.children);
                            }
                        }
                    }

                    function loopThruParentChild(parent) {
                        for (let i = 0; i < parent.length; i++) {
                            const row = parent[i];
                            if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                                if(row.value)
                                {
                                    totalAmount += row.value[id - gridGroupLevel]
                                }

                                if (row.children) {
                                    loopThruParentChild(row.children);
                                }
                            }
                        }
                    }
                    

                    data.push(totalAmount)
                    color.push(pieItem && pieItem[index] && pieItem[index].color ? pieItem[index].color : defaultControl(index));
                } else {
                    sqlresult.forEach((element, index) => {
                        if(item.data === element[selectedLabel.id])
                        {
                            if (typeof element[id] === 'string') 
                                if (element[id].includes('%')) 
                                    if(!isNaN(parseFloat(element[id].replace('%', '')).toFixed(2)))
                                        element[id] = parseFloat(element[id].replace('%', '')).toFixed(2)
                            totalAmount+= element[id]
                        }
                    });
                    data.push(totalAmount)
                    color.push(pieitem && pieitem[index] && pieitem[index].color ? pieitem[index].color : defaultControl(index));
                }
            })

            // console.log(data)
            chartData.datasets = [{
                label: '',
                data: data,
                backgroundColor: color,
                hoverOffset: 4,
            }]
        } else {
            // console.log(pieitem)
            pieitem && pieitem.length > 0 && pieItem.forEach((item, index) => 
            {
                console.log(item)
                let totalAmount = 0

                if(gridGroupLevel) {
                   
                    loopThruItems(treeViewData)
                    function loopThruItems(treeViewData) {
                        for (let i = 0; i < treeViewData.length; i++) {
                            const row = treeViewData[i];
                            if(row.sqlId === item.data.id) {
                                if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                                    if(row.value)
                                    {
                                        totalAmount += row.value[id - gridGroupLevel]
                                    }

                                    if (row.children) {
                                        loopThruParentChild(row.children);
                                    }
                                }
                            }
                            if (row.children) {
                                loopThruItems(row.children);
                            }
                        }
                    }

                    function loopThruParentChild(parent) {
                        for (let i = 0; i < parent.length; i++) {
                            const row = parent[i];
                            if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                                if(row.value)
                                {
                                    totalAmount += row.value[id - gridGroupLevel]
                                }

                                if (row.children) {
                                    loopThruParentChild(row.children);
                                }
                            }
                        }
                    }
                    

                    data.push(totalAmount)
                    color.push(pieItem && pieItem[index] && pieItem[index].color ? pieItem[index].color : defaultControl(index));
                } else {
                    sqlresult.forEach((element, index) => {
                        if(item.data === element[selectedLabel.id])
                        {
                            if (!data.includes(element[id])) {
                                if (typeof element[id] === 'string') 
                                            if (element[id].includes('%')) 
                                                if(!isNaN(parseFloat(element[id].replace('%', '')).toFixed(2)))
                                                    element[id] = parseFloat(element[id].replace('%', '')).toFixed(2)
    
                                totalAmount+= element[id]
                            }
                        }
                    });
                    data.push(totalAmount)
                    color.push(pieItem && pieItem[index] && pieItem[index].color ? pieItem[index].color : defaultControl(index));
                }
            })

            chartData.datasets = [{
                label: '',
                data: data,
                backgroundColor: color,
                hoverOffset: 4,
            }]
        }


        // setColors(color)
        console.log(chartData)
        // console.log({ pieItem, selectedLabel })
      
        setChartDataSet(null)
        setTimeout(() => {
            setChartDataSet(chartData)
            setNewUpdatesCount(prev => prev + 1)
        }, 100);
        return data
    }
    const handleSelectGroupChange = event => {
        if(event.target.value !== 'Select Group By')
        {
            setSelectedGroup(event.target.value);
            handleSelectGroupList(event.target.value)
        }else{
            setSelectedGraphHeaderOption(null)
            setSelectedGroup(null)
            setChartHeader({fieldIndex: 0, header : []})
        }
    };
    const handleSelectGroupList = (value) => {
        const res = headerToMultiSelect.filter((e) => e.name === value);
        const header = gridGroupLevel ? getChartHeader2(res[0].id) : getChartHeader(res[0].id)

        setChartHeader({fieldIndex: res[0].id, header : header})
    }
    const getChartHeader = (id) => {
        const label = []
        sqlresult.forEach(element => {
            if(!label.includes(element[id]))
            label.push(element[id])
        });
        return label
    }
    const getChartHeader2 = (id) => {
        const label = []
        let currentIndentLevel = 0;
        loopThruItems(id, treeViewData);

        function loopThruItems(indentLevel, treeViewData) {
            const rowsToSum = [];
            
            for (let i = 0; i < treeViewData.length; i++) {
                const row = treeViewData[i];

                if (currentIndentLevel === id) {
                    if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                        if(!label.includes(row.name))
                        label.push(row.name)
                    }
                }

                if (row.children) {
                    currentIndentLevel++;
                    const childRowsToSum = loopThruItems(indentLevel, row.children);
                    rowsToSum.push(...childRowsToSum);
                    currentIndentLevel--;
                }
            }
            return rowsToSum;
        }
        return label
    }
    const handleSelectLabelChange = (value) => {
        if(value === "") 
            setSelectedLabel({ id: "", label: "" });
        else {
            const res = headerToMultiSelect.filter((e) => e.name === value);
            setSelectedLabel({ id: res[0].id, label: value });
        }
    };
    const handleSelectDataChange = (value) => {
        const res = headerToMultiSelect.filter((e) => e.name === value);
        if(res) setSelectedData({ id: res[0].id, label: value });
    };
    const toggleColorPicker = (index, value) => {
        setPieItem(prevGroups => {
            const updatedGroups = [...prevGroups];
            updatedGroups[index]['isOpen'] = value;
            return updatedGroups;
        });
    };
    const handleRemoveFormGroup = index => {
        setSelectedGroups(prevGroups => prevGroups.filter((group, i) => i !== index));
    };
    const handleSelectChange = (index, type, value) => {
        setSelectedGroups(prevGroups => {
          const updatedGroups = [...prevGroups];
          updatedGroups[index][type] = value;
          return updatedGroups;
        });
    };
    const handleChangeComplete = (newColor, index) => {
        setPieItem(prevGroups => {
            const updatedGroups = [...prevGroups];
            updatedGroups[index]['color'] = newColor.hex;
            return updatedGroups;
        });
    };
    const handleAddFormGroup = () => {
        setSelectedGroups(prevGroups => [...prevGroups, { label: '', data: '', color: getRandomLightColor(), isOpen: false }]);
    };
    const handleSelectGraphHeader = (item) => {
        setSelectedGraphHeaderOption(item)
    }
    function getRandomLightColor() {
        // Generate random values for red, green, and blue components
        const r = Math.floor(Math.random() * 128) + 128; // Ranges from 128 to 255 (brighter colors)
        const g = Math.floor(Math.random() * 128) + 128;
        const b = Math.floor(Math.random() * 128) + 128;
    
        // Format the RGB values as a CSS color string
        const color = `rgb(${r}, ${g}, ${b})`;
    
        return color;
    }

    return (
        <>
            {(onEditVisual || onEditName) &&
            <>
            <FormGroup>
               
                    <Col sm='12' md='4'>
                    <Row>
                        <Label htmlFor="selectGroup">Select Group By (Optional)</Label>
                        <Input type="select" id="selectGroup" value={selectedGroup} onChange={handleSelectGroupChange}>
                        <option value={null}>Select Group By</option>
                        {/* {gridHeader.map(group => (
                            <option key={group} value={group}>
                                {group}
                            </option>
                        ))} */}
                        {gridGroupLevel ? 
                            <>
                                {gridHeader.map((group, index) => (
                                    index < gridGroupLevel && 
                                    <option key={group} value={group}>
                                        {group}
                                    </option>
                                ))}
                            </> : <>
                                {gridHeader.map((group, index) => (
                                    <option key={group} value={group}>
                                        {group}
                                    </option>
                                ))}
                            </>
                        }
                        </Input>
                    </Row>
                    </Col>
              
            </FormGroup>
            <FormGroup>
               
                    <Col sm='12' md='4'>
                    <Row>
                        <Label htmlFor="selectGroup">Select Label</Label>
                        <Input 
                            type="select" 
                            id="selectGroup" 
                            value={selectedLabel ? selectedLabel.label : ''}
                            onChange={e => handleSelectLabelChange(e.target.value)}
                        >
                            <option value="">Select Label</option>
                            {gridGroupLevel ? 
                                <>
                                    {gridHeader.map((group, index) => (
                                        index < gridGroupLevel && 
                                        <option key={group} value={group}>
                                            {group}
                                        </option>
                                    ))}
                                </> : <>
                                    {gridHeader.map((group, index) => (
                                        <option key={group} value={group}>
                                            {group}
                                        </option>
                                    ))}
                                </>
                            }
                            {/* {gridHeader.map((group) => (
                                <option key={group} value={group}>
                                    {group}
                                </option>
                            ))} */}
                        </Input>
                    </Row>
                    </Col>
                    <Col sm='12' md='4'>
                        <Row>
                            <Label htmlFor="selectGroup">Select Data</Label>
                            <Input 
                                type="select" 
                                id="selectGroup" 
                                value={selectedData ? selectedData.label : ''}
                                onChange={e => handleSelectDataChange(e.target.value)}
                            >
                                <option value="">Select Data</option>
                                {gridGroupLevel ? 
                                    <>
                                        {gridHeader.map((group, index) => (
                                            index >= gridGroupLevel && 
                                            <option key={`${group}${index}`} value={group}>
                                                {group}
                                            </option>
                                        ))}
                                    </> : <>
                                        {gridHeader.map((group, index) => (
                                            <option key={`${group}${index}`} value={group}>
                                                {group}
                                            </option>
                                        ))}
                                    </>
                                }
                                {/* {gridHeader.map((group) => (
                                    <option key={group} value={group}>
                                        {group}
                                    </option>
                                ))} */}
                            </Input>
                        </Row>
                    </Col>
                
            </FormGroup>
            <div className='pieItemContainer'>
                {pieItem && pieItem.map((data, index) => (
                    <div className='pieItemContent'>
                        <div className='pieItemLabel'>{data.data && data.data.name}</div>
                        <div className='pieItemColor'style={{ backgroundColor: data.color, border: 'none' }} onClick={e => toggleColorPicker(index, true)}></div>
                        {data.isOpen &&
                            <div style={{ position: "absolute", zIndex: "99999", paddingTop: 35 }}>
                                <div
                                    key={index}
                                    id={index}
                                    style={{
                                        position: "fixed",
                                        top: "10px",
                                        right: "0px",
                                        bottom: "0px",
                                        left: "0px",
                                    }}
                                    onClick={e => toggleColorPicker(index, false)}
                                />
                                <ChromePicker color={data.color} onChangeComplete={(newColor) => handleChangeComplete(newColor, index)} />
                            </div>
                        }
                    </div>
                ))}
            </div>
            <FormGroup>
                <Col sm='12'>
                    <Row>
                        <div 
                            className='addNewVisualData' 
                            style={{ background: '#088bf4', border: '1px solid #088bf4', color: '#fff', width: 100, marginTop: 10, marginRight: 10 }}
                            onClick={onHandleSaveVisual}
                        >Save Visual</div>
                        <div 
                            className='addNewVisualData' 
                            style={{ border: '1px solid #333', color: '#333', width: 100, marginTop: 10, marginRight: 0 }}
                            onClick={handleCloseEditVisual}
                        >Cancel</div>
                    </Row>
                </Col>
            </FormGroup>
            </>}
            <div className='templateVisualGraphHeaderContent'>
                {chartDataSet &&
                    <>
                        <div className='templateVisualGraphHeaderContainer'>
                            {chartHeader && chartHeader.header && chartHeader.header.map((item) => (
                            <div 
                                className='templateVisualGraphHeaderItem' 
                                style={selectedGraphHeaderOption === item ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                                onClick={() => handleSelectGraphHeader(item)}
                            >{item}</div>
                            ))}
                        </div>
                        <div style={{ maxHeight: screenHeight  - 300 }}>
                            <Pie
                                data={chartDataSet}
                            />
                        </div>
                       
                    </>
                }
            </div>

        </>   
    )
}

export default VisualPieChartView