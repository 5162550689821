import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap';

function DashboardTemplateItemCardView({index, item, treeViewData, sqlresult, templateItemVisual, layouts, onFullScreenOnly}) {
    const {
        bgColor,
        textColor,
    } = useContext(MainContext);
    const [cardItem, setCardItem] = useState([])
    const [cardWidth, setCardWidth] = useState(null)
    const [fontSizeFactor, setFontSizeFactor] = useState(null)
    const [gridGroupLevel, setGridGroupLevel] = useState(null)

    useEffect(() => {
        const { cardItem, headerToMultiSelect } = templateItemVisual
        if(layouts !== undefined) setFontSizeFactor(layouts.lg[index].w * 7)
        else setFontSizeFactor(60)

        if(cardItem && cardItem.length > 0)
        {
            loadCards(cardItem, headerToMultiSelect)
            const getPercentage = 100 / cardItem.length
            const cardWidth = getPercentage > 20 ? getPercentage : 20

            setCardWidth(cardWidth)
        }
        
    })

    useEffect(() => {
        if(item && item.editedGridCellData) setGridGroupLevel(item.editedGridCellData.gridGroupLevel)
    }, [item])

    const loadCards = (cardItem, headerToMultiSelect) => {
     
        cardItem.forEach((card) => {
            // console.log(card)
            let totalAmount = 0;
            let isPercentage = false;
            let percentageSum = 0;
            let percentageCount = 0;
            
            if(item.editedGridCellData) {
                let currentIndentLevel = 0;
                const rowsToSum = loopThruItems(card.columnIndex, treeViewData);
                const totalAmount = calculateAllwithValue(rowsToSum);
                card.sum = totalAmount;

                function loopThruItems(indentLevel, treeViewData) {
                    const rowsToSum = [];
                    
                    for (let i = 0; i < treeViewData.length; i++) {
                        const row = treeViewData[i];
    
                        if (currentIndentLevel === card.columnIndex) {
                            if (card.columnItem !== "") {
                                if (card.columnItem === row.sqlId) { 
                                    if (!row.id.includes("-TotalRow") && (row.id !== "grand-total")) 
                                    {
                                        console.log("Pushing row:", row); // Debugging line
                                        rowsToSum.push(row);
                                    } else {
                                        if(card.columnItem === "grand-total") rowsToSum.push(row);
                                        else {
                                            console.log("card excluded:", card);
                                            console.log("Row excluded:", row); // Debugging line
                                        }
                                    }
                                }
                            } else {
                                if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                                    rowsToSum.push(row);
                                }
                            }
                        }
    
                        if (row.children) {
                            currentIndentLevel++;
                            const childRowsToSum = loopThruItems(indentLevel, row.children);
                            rowsToSum.push(...childRowsToSum); // Accumulate results from child nodes
                            currentIndentLevel--;
                        }
                    }
                    return rowsToSum;
                }

                function calculateAllwithValue(rowsToSum) {
                    console.log(rowsToSum);
                    let sum = 0;
                    
                    function loopThruItems(rowsToSum) {
                        for (let i = 0; i < rowsToSum.length; i++) {
                            const row = rowsToSum[i];
                            if (row.value && (!row.id.includes("-TotalRow")) && 
                                (currentIndentLevel === 0 || !row.id.includes("-grandParent-"))) {
                                    if(isNaN(row.value[card.columnIndexToSum]))
                                        sum += 0
                                    else    
                                        sum += parseFloat(row.value[card.columnIndexToSum]);
                            }
    
                            if (row.children) {
                                loopThruItems(row.children);
                            }
                        }
                    }
                    
                    loopThruItems(rowsToSum); // Run the calculation on the collected rows
                    console.log(sum);
                    return sum;
                }
                
            }else {
                const processItem = (item) => {
                    const res = headerToMultiSelect.filter((e) => e.name === card.columnToSum);
                    if (res.length > 0) {
                        const value = item[res[0].id];
                        if (typeof value === 'string' && value.includes('%')) {
                            isPercentage = true;
                            const numericValue = Number(parseFloat(value.replace('%', '')));
                            if (!isNaN(numericValue)) {
                                percentageSum += numericValue;
                                percentageCount++;
                            }
                        } else if (typeof value === 'number') {
                            totalAmount += value;
                        }
                    }
                };
        
                if (card.columnItem !== "") {
                    sqlresult.forEach((item) => {
                        if (item[card.id] === card.columnItem) {
                            processItem(item);
                        }
                    });
                } else {
                    sqlresult.forEach((item) => {
                        processItem(item);
                    });
                }
        
                if (isPercentage && percentageCount > 0) {
                    card.sum = (percentageSum / percentageCount).toFixed(1) + '%';
                } else {
                    card.sum = totalAmount.toFixed(2);
                }
            }
            
        });

        setCardItem(cardItem);
    }
    const formatAmount = (number) => {
        const isNegative = number < 0;
        const absNumber = Math.abs(number);
        
        if (isNaN(number) && !String(number).includes("%")) return 0;
        if (typeof number === 'string' && number.includes("%")) return number;
    
        if (absNumber >= 1e9) {
            return (isNegative ? "-" : "") + (absNumber / 1e9).toFixed(1) + "B";
        } else if (absNumber >= 1e6) {
            return (isNegative ? "-" : "") + (absNumber / 1e6).toFixed(1) + "M";
        } else if (absNumber >= 1e3) {
            return (isNegative ? "-" : "") + (absNumber / 1e3).toFixed(1) + "K";
        } else {
            return (isNegative ? "-" : "") + Math.round(absNumber);
        }
    };
    const titleSize = (fontSizeFactor) => {
        return fontSizeFactor / 3  >= 20 ? 20 : fontSizeFactor / 3 <= 15 ? 15 : fontSizeFactor / 3
    }
    const splitTextIntoLines = (card, maxLength) => {

         
        let text = ""
        if(gridGroupLevel) {
            const item = card.columnItemList.find(i => i.id === card.columnItem)
            text = item ? item.row : card.column 
        }else {
            text = typeof card.columnItem === 'string' && card.columnItem !== ''
            ? card.columnItem
            : ''
        }   

        if (text.length <= maxLength) return text;
    
        const lines = [];
        for (let i = 0; i < text.length; i += maxLength) {
            lines.push(text.substring(i, i + maxLength));
        }
    
        return lines.join('\n');
    }
    return (
        <div className='cardContent'>
            {cardItem.map((card, index) => (
                <div
                    key={index}
                    style={{ width: `calc(${cardWidth}% - 0px)`, padding: '0px 5px', minWidth: 170, minHeight: 150, marginBottom: 10, maxWidth: 400 }}
                >
                    <div className='cardContainer'>
                        <div className='cardAmount' style={{ fontSize: fontSizeFactor > 100 ? 100 : fontSizeFactor < 50 ? 50 : fontSizeFactor }}>
                            {formatAmount(card.sum)}
                        </div>
                        <div className='cardTitle' style={{ fontSize: titleSize(fontSizeFactor) }}>
                            {/* {card.columnItem !== '' ? card.columnItem : card.column} */}

                            {card.columnToSum}
                        </div>
                        <div className='cardTitleDescription'>
                            {/* {card.columnItem !== '' ? card.columnItem : card.column} */}

                            {splitTextIntoLines( card, 25 )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default DashboardTemplateItemCardView