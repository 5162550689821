import React, { useCallback, useEffect, useState } from 'react'
import { evaThemeLighter, withHeaderFilter } from '../../services/EVAResponseService';
import { TransformSQLResult, PopulateNewRows } from 'services/EVAEditGridService';
import classNames from 'classnames';
import { ProcessDnDTreeviewData } from 'services/EVAEditGridService';
import { TableHeader } from 'services/EVAEditGridService';
import { NewParseFormula } from 'services/EVAEditGridService';
import { ReplaceCellIdsWithValues } from 'services/EVAEditGridService';
import { EvaluateFormula } from 'services/EVAEditGridService';
import { RecalculateWithTotal } from 'services/EVAEditGridService';
import { ProcessRenamingSQLData } from 'services/EVAEditGridService';
import { ProcessRemoveRow } from 'services/EVAEditGridService';
import { GetCellIdValue } from 'services/EVAEditGridService';
import { toLength } from 'lodash';
import { ProcessGrandTotal } from 'services/EVAEditGridService';
import { ProcessGrandParentRow } from 'services/EVAEditGridService';

export default function TreeView({ item, theme, sqlresult, gridHeader, treeViewData, toggleAll, isFromPnL, groupLevel }) {

  const [subParentVisibility, setSubParentVisibility] = useState({});
  const [childVisibility, setChildVisibility] = useState({});
  const [dataHeader, setDataHeader] = useState([])
  const [treeData, setTreeData] = useState([])
  const [newTreeData, setNewTreeData] = useState([])
  const [headerFilter, setHeaderFilter] = useState([])
  const [newSQL, setNewSQL] = useState([])
  const [activeHeaderIndex, setActiveHeaderIndex] = useState(0)
  const [editedGridCellData, setEditedGridCellData] = useState(null)
  const [gridGroupLevel, setGridGroupLevel] = useState(null)
  const [newRowList, setNewRowList] = useState([])
  const [dragAndDropData, setDragAndDropData] = useState([])
  const [cellFormulaList, setCellFormulaList] = useState([])
  const [renamedSQLData, setRenamedSQLData] = useState([])
  const [hiddenRow, setHiddenRow] = useState([])

  const toggleVisibility = (label, setState) => {

    setState((prevVisibility) => ({
      ...prevVisibility,
      [label]: !prevVisibility[label]
    }));
  };

  const toggleSubParentVisibility = (label) => {
    toggleVisibility(label, setSubParentVisibility);
  };

  useEffect(() => {
    // console.log(subParentVisibility)
  },[subParentVisibility])

  function getIsAmount(cl) 
  {
    // Check if cl is a valid value and convert it to a string
    const newcl = cl ? JSON.stringify(cl).replace(/,/g, "").replace(/\$/g, "") : "";
  
    if (cl === "Total Amount") {
      return "isTotalAmount";
    }
  
    // Parse newcl as a number
    const newNumber = parseFloat(newcl);
  
    // Check if newNumber is a valid number
    if (!Number.isNaN(newNumber)) {
      return "isAmount";
    }
  
    return "";
  }

  useEffect(() => {
    setDataHeader(gridHeader)
    setNewTreeData(treeViewData)
    setGridGroupLevel(groupLevel)

    if(item && item.editedGridCellData)
    {
      setGridGroupLevel(item.editedGridCellData.gridGroupLevel)
      setNewTreeData(treeViewData)
    }

  },[sqlresult])

  useEffect(() => {
    function getInitialParentSubGroup(newTreeData) {
      const result = {};
    
      function traverseTree(data) {
        for (let i = 0; i < data.length; i++) {
          const currentItem = data[i];
          const currentId = currentItem?.id;
          
          if (
            (currentItem.children && currentItem.children.length > 0) || 
            gridGroupLevel === 1 || 
            currentId?.includes("-parent-") || 
            currentId?.includes("-child-") || 
            currentId?.includes("-sub-") || 
            currentId?.includes("-TotalRow")
          ) {
            result[currentId] = !toggleAll;
          }
    
          if (currentItem.children && currentItem.children.length > 0) {
            traverseTree(currentItem.children);
          }
        }
      }
    
      traverseTree(newTreeData);
      return result;
    }
    

    if(newTreeData.length > 0)
    {
      const initialState = getInitialParentSubGroup(newTreeData);
      // console.log(initialState)
      setSubParentVisibility(initialState)
    }

  }, [newTreeData])

  useEffect(() => {
    const initialState = treeViewData.reduce((acc, parent) => {
      acc[parent.label] = toggleAll;
      return acc;
    }, {});

    setSubParentVisibility(initialState)
  }, [gridHeader]);

  const backgroundColorStyleLighttd = evaThemeLighter(10, theme, '#333')
  const backgroundColorStyleLight = evaThemeLighter(90, theme)

  const styleAlignRight = (value) => {
    const alignment = typeof value === 'string' ? !isNaN(Number(value.replace(/,/g, ''))) ? 'right' : value?.includes('%') ? 'right' : 'left' : 'right';
    return { textAlign: alignment }
  }

  const getNewRowData = (child, childIndex, id) => {
    const cellId = id ? `${TableHeader(childIndex)}${id.split("-")[0]}` : '';

    const withFormula = cellFormulaList.filter(item => item.rowId === id);
    const formulaDetails = withFormula.find(f => f.cellId === cellId);
    const isGrandParent = id === "grand-total" || id?.includes("-grandParent-");

    return (
        <td
            className={`tdClass ${getIsAmount(child)} ${cellId}`} 
            key={childIndex}
            style={{
                ...styleAlignRight(child), // Align styles
                ...(isGrandParent && id === "grand-total" 
                    ? backgroundColorStyleLight
                    : {}// Apply conditional background color
                )
            }}
        >
            {isNaN(child) ? child : parseFloat(child).toFixed(2).toLocaleString()}
        </td>
    );
  };

  const NewTreeView = ({ row, newTreeData, gridHeader, gridGroupLevel , dataHeader, getRowData, toggleSubParentVisibility, subParentVisibility}) => {

    const renderRow = (name, value, indentLevel, id, parentId, withTotalRow, gridHeader, gridGroupLevel) => {
      // if(parentId === "167-group-Demo Company (AU)-Expenses-New Row 167") console.log(name)
      const isGrandParent = id?.includes("-grandParent-") || false;
      const isGrandTotal = id === "grand-total";
      const isTotalRow = value && (id?.includes("-parent-") || id?.includes("-sub-"));
      
      return (
        <tr 
          className={`
            ${parentId && parentId.split("-")[1] === 'group' ? 'isGroupRow' : ''}
            ${isGrandParent ? 'grandParentRow' : ''}
            ${isGrandTotal ? 'isGrandTotalRow' : ''}
            ${id && id?.includes("-TotalRow") ? 'groupTotalRow': ''}
            ${isTotalRow ? 'totalRow' : ''}
          `}
          style={{
            // ...(isGrandParent ? evaThemeLighter(40, theme) : {}),
            ...(isGrandTotal ? evaThemeLighter(100, theme) : {})}}
        >
          {[...Array(Math.max(0, parentId && parentId.split("-")[1] === 'group' ? indentLevel - 1 : indentLevel))].map((_, i) => (
            <td 
              key={`indent-${i}`}
              style={{
                ...((indentLevel === 1 && !value) || isGrandParent ? evaThemeLighter(70, theme) : {}),
              }}
              ></td>
          ))}
    
          {renderHeader(name, value, indentLevel, id, parentId, gridHeader, gridGroupLevel)}  
    
          {value ? value.map((item, index) => (
            <React.Fragment key={`value-${index}`}>
              {getRowData(item, index, id, indentLevel, gridGroupLevel)}
            </React.Fragment>
          )) : (
            <td 
              style={{
                ...(indentLevel === 0 ? backgroundColorStyleLight : {}),
                ...(indentLevel === 1 ? evaThemeLighter(70, theme) : {}),
                // ...(indentLevel === 2 && !value ? evaThemeLighter(60, theme) : {}),
              }}
              onClick={() => toggleSubParentVisibility(id)}
              colSpan={dataHeader.length - indentLevel - 1}></td>
          )}
        </tr>
      );
    }
  
    const renderRowWithSubTotal = (row, newTreeData, gridGroupLevel, name, value, indentLevel, id, parentId, withTotalRow) => {
      
      // console.log(row)
      if(!row.children) return 

      const childrenWithTotalRow = row.children && Array.isArray(row.children) && row.children.find(i => i?.id && i.id?.includes("-TotalRow"));

      const subTotal = childrenWithTotalRow ? childrenWithTotalRow.value : calculateSubTotal(row, newTreeData, gridGroupLevel);
      const colspan = (dataHeader.length - indentLevel - 1) - subTotal.length

      return (
        <tr 
          className={`
            totalRow 
            ${parentId && parentId.split("-")[1] === 'group' ? 'isGroupRow' : ''}
          `}
        >
          {[...Array(Math.max(0, parentId && parentId.split("-")[1] === 'group' ? indentLevel - 1 : indentLevel))].map((_, i) => (
            <td key={`indent-${i}`}></td>
          ))}
    
          <td 
            className={`
              rowHeader
              ${id && id.split("-")[1] === 'group' ? 'isGroupTD' : ''} 
              ${parentId && parentId.split("-")[1] === 'group' ? 'isParentGroupTD' : ''}
            `}
            style={{ fontWeight: !value ? 'bold' : 'unset', 
              // borderLeft: !value ? '1px solid #dadada': ''
            }}
            onClick={() => { 
              toggleSubParentVisibility(id)
            }}
            colSpan={subParentVisibility?.[id] ? gridGroupLevel : gridGroupLevel - indentLevel}
          >
            {!value && (subParentVisibility?.[id] ? ' ▼':' ▲')} {name}
          </td>
          
          {subParentVisibility?.[id] && colspan > 0 && <td colSpan={colspan}></td>}
          
          {subTotal && subTotal.map((item, index) => (
            <React.Fragment key={`subtotal-${index}`}>
              {getRowData(item, index, row.id)}
            </React.Fragment>
          ))}
        </tr>
      );
    }
    
    const renderHeader = (name, value, indentLevel, id, parentId, gridHeader) => {
      let colspan = 1
      if(id === "grand-total" || id?.includes("-grandParent-")) 
      {
        return (
          <td 
            className={`
              rowHeader
              ${id && id.split("-")[1] === 'group' ? 'isGroupTD' : ''} 
              ${parentId && parentId.split("-")[1] === 'group' ? 'isParentGroupTD' : ''}
            `}
            style={{ fontWeight: !value ? 'bold' : 'unset', 
              ...(id === "grand-total" ? backgroundColorStyleLight : {})
              // borderLeft: !value ? '1px solid #dadada': ''
            }}
            // onClick={() => toggleSubParentVisibility(id)}
            colSpan={gridGroupLevel}
          >
            {!value && (subParentVisibility?.[id] ? ' ▼':' ▲')} {name}
          </td>
        )
      } 



      const isParentGroup = parentId && parentId.split("-")[1] === 'group'
      const isGroupChild = id && id.split("-")[1] === 'group'

      if(value && gridGroupLevel === 3 && indentLevel === 0) colspan = 3
      if(value && gridGroupLevel === 3 && indentLevel === 1) colspan = 2
      if(value && gridGroupLevel === 3 && indentLevel === 2 && isParentGroup) colspan = 2
      if(value && gridGroupLevel === 3 && indentLevel === 1 && isParentGroup) colspan = 3
      if(value && gridGroupLevel === 3 && indentLevel === 2 && isGroupChild) colspan = 2

      if(value && gridGroupLevel === 2 && indentLevel === 0) colspan = 2
      if(value && gridGroupLevel === 2 && indentLevel === 1 && isParentGroup) colspan = 2
      
      return (
        <td 
          className={`
            rowHeader
            ${isGroupChild ? 'isGroupTD' : ''} 
            ${isParentGroup ? 'isParentGroupTD' : ''}
          `}
          style={{
            ...(indentLevel === 0 && !value ? backgroundColorStyleLight : {}),
            ...(indentLevel === 1 && !value ? evaThemeLighter(70, theme) : {}),
            fontWeight: !value ? 'bold' : 'unset'
          }}
          // Conditionally disable onClick if !value is true
          onClick={value ? undefined : () => toggleSubParentVisibility(id)}
          colSpan={colspan}
        >
          {!value && (subParentVisibility?.[id] ? ' ▼' : ' ▲')} {name} 
          {/* {gridGroupLevel} {indentLevel} {colspan} */}
          {/* {(gridGroupLevel + (isParentGroup ? 1 : 0)) - indentLevel} */}
        </td>
      );
    }
    
    const renderChildren = (children, newTreeData, indentLevel, id, parentName, subParentVisibility, gridGroupLevel) => 
    {
      return (
        // subParentVisibility?.[id] && 
        children && 
        children.map((child, index) => (
          ((child.children && child.children.length > 0) || (child && child.value)) &&
          <React.Fragment key={`child-${child.id}-${index}`}>
            {subParentVisibility?.[child.id] ?
              <>
                {renderRow(child.name, child.value, indentLevel, child.id, id, row.withTotalRow)}
                {renderChildren(child.children, newTreeData, indentLevel + 1, child.id, id, subParentVisibility, indentLevel + 1)}
                {/* {subParentVisibility?.[child.id] && showSubTotal(child, newTreeData, gridGroupLevel, indentLevel + 1)} */}
              </>
              :
              <>
                {renderRowWithSubTotal(child, newTreeData, gridGroupLevel, child.name, child.value, indentLevel, child.id, id, row.withTotalRow)}
              </>
            }
          </React.Fragment>
        ))
      )
    };
    
    const showSubTotal = (row, newTreeData, gridGroupLevel, indentLevel) => {
      // Calculate subtotal for the given parent node
      if(!row.withTotalRow) return 
  
      const subTotal = calculateSubTotal(row, newTreeData, gridGroupLevel);
  
      return (
        row.withTotalRow ?
          <tr className='totalRow'>
            {indentLevel > 1 && <td></td>}
  
            <td className='rowHeader' 
              colSpan={indentLevel === 0 ? gridGroupLevel: indentLevel} 
              // style={{ borderLeft: '1px solid #dadada' }}
            >Total {row.name}</td>
            {subTotal && subTotal.map((item, index) => (
              <React.Fragment key={`subtotal-${index}`}>
                {getRowData(item, index, row.id)}
              </React.Fragment>
            ))}
          </tr>
        : null
      )
    }
  
    function calculateSubTotal(node, newTreeData, gridGroupLevel) {
      let length = dataHeader.length - gridGroupLevel
      let subtotal = Array(length).fill(0);
      let containsString = Array(length).fill(false);
  
      // Check if the node should be skipped
      if (node.id && (node.id?.includes("groupTotal") || node.id?.includes("-TotalRow"))) {
        // Skip this node and return the initial subtotal
        return subtotal;
      }
  
      if (!node.children || node.children.length === 0) 
      {
        if (node.value) {
          return node.value.map(v => (isNaN(v) ? "-" : parseFloat(v) || 0));
        }
        return subtotal;
      }
  
      for (const child of node.children) 
      {
        const childTotal = calculateSubTotal(child, newTreeData, gridGroupLevel);
  
        for (let i = 0; i < subtotal.length; i++) {
          if (isNaN(childTotal[i])) {
            containsString[i] = true;
          } else {
            subtotal[i] += parseFloat(childTotal[i]) || 0;
          }
        }
      }
      
      return subtotal.map((total, index) => (containsString[index] ? "-" : total));
    }
    
    return (
      <>
        {subParentVisibility?.[row.id] ?
          <>
            {renderRow(row.name, row.value, 0, row.id, row.id, row.withTotalRow, gridHeader, gridGroupLevel)}
            {row.children && renderChildren(row.children, newTreeData, 1, row.id, row.name, subParentVisibility, gridGroupLevel)}
            {/* {subParentVisibility?.[row.id] && showSubTotal(row, newTreeData, gridGroupLevel, 0)} */}
          </>
          :
          <>
            {renderRowWithSubTotal(row, newTreeData, gridGroupLevel, row.name, row.value, 0, row.id, row.id, row.withTotalRow)}
          </>
        }
    
        {gridGroupLevel !== 1 && (row.id?.includes("grand-total") || row.id?.includes("-grandParent-")) &&
          renderRow(row.name, row.value, 0, row.id, row.id, row.withTotalRow, gridHeader, gridGroupLevel)
        }
      </>
    );
  };
  return (
    <>
      <table className='treeView'>
        <thead style={evaThemeLighter(100, theme)}>
          <tr>
            {dataHeader !== undefined ? dataHeader.map((item) => (
              <th id={item} key={item} style={evaThemeLighter(100, theme)}>{item}</th>
            )) : null}
          </tr>
        </thead>
        
        {newTreeData && newTreeData.length > 0 &&
          <tbody className='evaEditedGrid'>
            {newTreeData.map((item) => (
              <React.Fragment key={`newTree-${item.id}`}>
                <NewTreeView 
                  row={item}
                  newTreeData={newTreeData}
                  gridHeader={gridHeader}
                  gridGroupLevel={gridGroupLevel}
                  dataHeader={dataHeader}
                  getRowData={getNewRowData}
                  toggleSubParentVisibility={toggleSubParentVisibility}
                  subParentVisibility={subParentVisibility}
                />
              </React.Fragment>
            ))}
          </tbody>
        }
        {/* // : (
        //   <tbody>
        //     {treeData && treeData.map((parent, index) => (
        //       <React.Fragment key={`treeData-${index}`}>
        //         <TreeViewData parent={parent} />
        //       </React.Fragment>
        //     ))}
        //     {!isFromPnL && <SetGrandTotal />}
        //   </tbody>
        // )} */}
      </table>
    </>
  );
}


