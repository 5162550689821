import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Button,
  Spinner,
  UncontrolledTooltip,
  TabContent,
  Nav,
  Modal,
  Form,
  Col,
  Row,

} from "reactstrap";
import classnames from "classnames";
import { Route, Switch } from "react-router";
import ThirdPartyAppStaffTable from "components/Table/ThirdPartyAppStaffTable";
import { CustomFieldGroup } from "customComponents/Form";
import { CustomToggleField } from "customComponents/Form";

import ReactBSAlert from "react-bootstrap-sweetalert";
import { MainContext } from "context/mainContext";
import "./style.css";

// react component used to create sweet alerts

import AddThirdParty from "./serviceModal";
import AddServiceInfo from "./thirdPartyModal";
import BlockUI from "components/Misc/BlockUI";
import CustomTab from "customComponents/customTab";
import { dataFilter, dataFilterNameOnly } from "utils/utilityFunctions";
import CustomHeader from "customComponents/customHeader";
import CustomSpinner from "components/Misc/CustomSpinner";
import CustomThirdParty from "customComponents/customThirdParty";
import ClientsInfo from "../../../components/ClientInfo/ClientInfo";
import { useHistory } from "react-router";
import EditStaffListModal from "./EditStaffListModal";
import ThirdPartyAppTimeSheetTable from "components/Table/ThirdPartyAppTimeSheetTable";
import ThirdPartyAppTimeSheetTableV2 from "components/Table/ThirdPartyAppTimeSheetTableV2";
import CsvUploadModal from "./CSVUploadModal";
import ClientThirdPartyCard from "components/Cards/clientThirdPartyCard";
import CsvWIPUploadModal from "./CSVUploadModal";
import CSVJobCostModal from "./CSVJobCostModal";
import CSVJobTaskModal from "./CSVJobTaskModal";
import CSVIncomeTaxModal from "./CSVIncomeTaxModal";
import WmbStaffListModal from "./WMBStaffListModal";
import ThirdPartyAppWmbStaffTable from "components/Table/ThirdPartyAppWmbStaffTable";
import WmbCSVUploadModal from "./WmbCSVUploadModal";
import WmbCSVJobCostModal from "./WmbCSVJobCostModal";
import WmbCSVJobTaskModal from "./WmbCSVJobTaskModal";
import WmbCSVIncomeTaxModal from "./WmbCSVIncomeTaxModal";
import ThirdPartyAppActionStepStaffTable from "components/Table/ThirdPartyAppActionStepStaffTable";


const ThirdPartyApplications = () => {
  const history = useHistory();
  const [showSubscribeButton, setShowSubscribeButton] = useState(false);
  // const [selectedClient, setSelectedClient] = useState([{}]);
  // eslint-disable-next-line
  const [accountingFirm, setAccountingFirm] = useState({});
  const [wipHistory, setWipHistory] = useState({});
  const [jobCostHistory, setJobCostHistory] = useState({});
  const [wipDb, setWipDb] = useState({});
  const [jobCostDb, setJobCostDb] = useState({});
  const [wmbWipDb, setWmbWipDb] = useState({});
  const [wmbJobCostDb, setWmbJobCostDb] = useState({});
  const [incomeTaxDb, setIncomeTaxDb] = useState({});
  const [wmbIncomeTaxDb, setWmbIncomeTaxDb] = useState({});
  const [jobTaskDb, setJobTaskDb] = useState({});
  const [wmbJobTaskDb, setWmbJobTaskDb] = useState({});
  const [staffRateDb, setStaffRateDb] = useState({});


  const {
    textColor,
    bgColor,
    selectedClientID,
    setSelectedClientID,
    selectedClient,
    setSelectedClient,
    userDetails,
    firmDetails,
    getDetails
  } = useContext(MainContext);
  const [showBlockUI, setShowBlockUI] = useState(false);
  // const [filterString, setFilterString] = useState('')
  const [subscribed, setSubscribed] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [workspace, setWorkSpace] = useState({});
  // const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  // eslint-disable-next-line
  const [client, setClient] = useState({});
  const [alert, setAlert] = useState(null);
  const [error, setError] = useState("");
  // eslint-disable-next-line
  const [tabs, setTabs] = useState(1);
  const [user, setUser] = useState([]);
  const [selectedData, setSelectedData] = useState()
  const [modal, setModal] = useState({
    title: "Add Integrations",
    // title: "Add 3rd Party Application",
    show: false,
    record: {},
    action: "create",
    format: "default",
    details: {},
    logo: "",
  });
  // const [authorizeModal, setAuthorizeModal] = useState({
  //   title: '',
  //   show: false,
  //   url: '',
  // })
  const [staffDetailsModal, setStaffDetailsModal] = useState({
    show: false,
    record: {},
    type: "open",
  });
  const [staffListModal, setStaffListModal] = useState({
    show: false,
    data: [],
    options: {
      noDataText: <Spinner />,
    },
  });
  const [wmbStaffListModal, setWmbStaffListModal] = useState({
    show: false,
    data: [],
    options: {
      noDataText: <Spinner />,
    },
  });
  const [timeSheetModal, setTimeSheetModal] = useState({
    show: false,
    data: [],
    options: {
      noDataText: <Spinner />,
    },
  });
  const [isStaffDetailsModalOpen, setIsStaffDetailsModalOpen] = useState(false)
  const [isStaffListModalOpen, setIsStaffListModalOpen] = useState(false)
  const [isWmbStaffListModalOpen, setWmbIsStaffListModalOpen] = useState(false)
  const [isTimeSheetModalOpen, setIsTimeSheetModalOpen] = useState(false)
  const [serviceModal, setServiceModal] = useState({
    show: false,
    data: [],
  });
  const [filteredServiceModalData, setFilteredServiceModal] = useState([])
  const [withEditedData, setwithEditedData] = useState([]);
  const setwithEditedDataHandler = (val) => {
    setwithEditedData(val)
  }
  const setIsTimeSheetModalOpenHandler = (val) => {
    setIsTimeSheetModalOpen(val)
  }
  const errorStyle =
    error !== "" ? { border: "1px solid #fb6340 " } : { border: "" };
  const errorMessage =
    error !== "" ? (
      <span className="text-warning text-sm">
        <i className="fas fa-exclamation-circle mr-2 text-warning" />
        {error}
      </span>
    ) : null;

  let allThirdPartyRoute = "/client/thirdpartyapplication";
  let reconnectionRoute = "/client/thirdpartyapplication/reconnection";
  let pendingRefreshRoute = "/client/thirdpartyapplication/pendingrefresh";


  const integrationDetails = (record) => {
    let details = {}
    // console.log(record)
    details.dw = 0;
    if (process.env.REACT_APP_IS_STAGING === 'true') {
      if (record.Details?.dbDestination === 'DW') {
        details.csID = 1;
        details.dbName = "Data Warehouse SQL Pool";
        details.tbName = `EtaniDemo_${record?.Service.id === 55 ? 'ACTIONSTEP' : 'XEROPRACTICEMANAGER'}_CAPACITYDEV`;
        details.sTbName = `EtaniDemo_${record?.Service.id === 55 ? 'ACTIONSTEP' : 'XEROPRACTICEMANAGER'}_Staff`
        details.taskRateTbName = `EtaniDemo_${record?.Service.id === 55 ? 'ACTIONSTEP' : 'XEROPRACTICEMANAGER'}_TasksList`
        details.dw = '1';
        details.integrationType = record?.Service.id === 55 ? 'actionstep' : null
      } else {
        details.csID = 1;
        details.dbName = "EtaniDemoData";
        details.tbName = `${record?.Service.id === 55 ? 'ACTIONSTEP' : 'XEROPRACTICEMANAGER'}_CAPACITYDEV`;
      }
    } else {
      const csId = record.Details?.Legacy?.id ? record.Details?.Legacy?.id : record.id;
      const clientID = selectedClient?.Details?.Legacy?.id ? selectedClient?.Details?.Legacy?.id : selectedClient?.id;
      details.csID = csId + "";
      details.dbName = firmDetails.AccountingFirmID;
      // This is to match database name on DW and MSSQL
      let firmCode = firmDetails.Code.includes("-") ? firmDetails.Code.replace("-", "") : firmDetails.Code
      if (record.Details?.dbDestination === 'DW') {
        if (record?.Service.id === 55 && firmDetails.id === 124) {
          details.taskRateTbName = `NEW_CLIENT1_ACTIONSTEP_TasksList`;
          details.tbName = `NEW_CLIENT1_ACTIONSTEP_CAPACITYDEV`;
          details.sTbName = `NEW_CLIENT1_ACTIONSTEP_Users`;
        } else {
          details.taskRateTbName = `${firmCode}_CLIENT${clientID}_${record?.Service.id === 55 ? 'ACTIONSTEP' : 'XEROPRACTICEMANAGER'}_TasksList`;
          details.tbName = `${firmCode}_CLIENT${clientID}_${record?.Service.id === 55 ? 'ACTIONSTEP' : 'XEROPRACTICEMANAGER'}_CAPACITYDEV`;
          details.sTbName = `${firmCode}_CLIENT${clientID}_${record?.Service.id === 55 ? 'ACTIONSTEP' : 'XEROPRACTICEMANAGER'}_Staff`;
        }
        details.dbName = "etani-data-warehouse";
        details.clientId = `${firmCode}_CLIENT${clientID}`;
        // details.clientId = `${firmCode}_CLIENT${clientID}`;
        details.firmId = firmDetails.id + "";
        details.dw = '1';
        details.integrationType = record?.Service.id === 55 ? 'actionstep' : null
      } else {
        details.tbName = `${record?.Service.id === 55 ? 'ACTIONSTEP' : 'XEROPRACTICEMANAGER'}_CAPACITYDEV`;
      }
    }
    // console.log(details)
    return details;
  }

  const fetchAPIs = async () => {
    let url = window.location.pathname.split("/");
    let firstClient = [];
    let allClients = [];
    let sessionSelectedClientID = sessionStorage.getItem('selectedClientID')
    if (userDetails && userDetails.User && userDetails.User.UserAccess === 1) {
      await fetch(`/api/client/getAll`)
        .then((res) => res.json())
        .then((data) => {
          if (data && !data.error) {
            // console.log(data)
            // let unsignedClient = data.filter((clnt) => clnt.Status === 6);
            // allClients = unsignedClient
            setClients(data);
            firstClient = data[0];
            // console.log(firstClient)
          } else {
            return;
          }
        })
        .then(() => {
          if (firstClient) {
            // console.log(selectedClientID)
            // setSelectedClientID(selectedClientID ? selectedClientID :
            //   url.length < 4 ? firstClient.ClientID : url[url.length - 1]
            // );
            fetch(
              `/api/client/showDetails/${sessionSelectedClientID ? sessionSelectedClientID : url.length < 4 ? firstClient.ClientID : url[url.length - 1]
              }`
            )
              .then((res) => res.json())
              .then((data) => {
                if (data && !data.error) {
                  setSelectedClient(data);
                  // console.log(data);
                  let clientDetails = data
                  fetch(`/api/clientService/getAllRecords/${data.id}`)
                    .then((res) => res.json())
                    .then((data) => {
                      if (data && !data.error) {
                        // console.log('clientService', data)
                        let workspace = {};

                        if (data.error) {
                          // console.log(data.error)
                          data = [];
                        }

                        for (let i = 0; i < data.length; i++) {
                          if (data[i].Service.id === 3) {
                            workspace = data[i];
                            if (!clientDetails.Details.ShowPowerBIPage) {
                              delete data[i]
                            }
                            break;
                          }
                        }

                        setUser(data);
                        setFilterData(data);
                        setWorkSpace(workspace);
                        setLoading(false);
                        // console.log(workspace);
                      } else {
                        return warningAlert(data);
                      }
                    });

                  fetch(`/api/customReport/getPowerBIReports/${data.id}`)
                    .then((res) => res.json())
                    .then((res) => {
                      let showSubscribeButton = true;
                      let subscribed = false;

                      if (res.error) {
                        warningAlert(res);
                        showSubscribeButton = false;
                      } else {
                        //console.log("no error", showSubscribeButton);
                        subscribed = res.subscribed;
                      }
                      // console.log(res)
                      setSubscribed(subscribed);
                      setShowSubscribeButton(!showSubscribeButton);
                      // console.log(res);
                    });
                } else {
                  setLoading(false);
                  // console.log('ERROR',data)
                  return warningAlert(data);
                }
              })
              .then(() => {
                fetch("/api/service/getAllRecords", {
                  method: "GET",
                  credentials: "include",
                })
                  .then((response) => response.json())
                  .then((service) => {
                    if (service.error) {
                      return warningAlert(service);
                      // service = {}
                    }
                    serviceModal.data = service;
                    setServiceModal(serviceModal);
                    setFilteredServiceModal(service)
                    // console.log(serviceModal);
                  });
              })
              .catch((error) => {
                warningAlert(error);
                setLoading(false);
              });
          } else {
            history.push(`/client/thirdpartyapplication`);
            setLoading(false);
          }
        })
        .catch((e) => {
          let data = [];

          setFilterData(data);

          console.log(e);
          setLoading(false);
          warningAlert(e);
        });
    } else {
      try {
        const allClientRecord = await fetch(`/api/client/getAllRecords`);
        const allClientRecordResult = await allClientRecord.json();
        if (allClientRecordResult) {
          setClients(allClientRecordResult);
          firstClient = allClientRecordResult[0];

          if (firstClient) {
            const clientDetails = await fetch(`/api/client/showDetails/${sessionSelectedClientID ? sessionSelectedClientID : url.length < 4 ? firstClient.ClientID : url[url.length - 1]}`);
            const clientDetailsResult = await clientDetails.json();

            if (clientDetailsResult && clientDetailsResult.error) {
              setLoading(false)
              return warningAlert(clientDetailsResult);
            }

            if (clientDetailsResult.message === "Client not found") {
              setSelectedClientID(firstClient.ClientID);
              return history.push(`/auth/page-not-found`);
            }
            setSelectedClient(clientDetailsResult);
            const clientService = await fetch(`/api/clientService/getAllRecords/${clientDetailsResult.id}`)
            const clientServiceResult = await clientService.json()

            if (clientServiceResult && clientServiceResult.error) {
              data = [];
              return warningAlert(clientServiceResult);
            }
            let workspace = {};
            for (let i = 0; i < clientServiceResult.length; i++) {
              if (clientServiceResult[i].Service.id === 3) {
                workspace = clientServiceResult[i];
                if (!clientDetailsResult.Details.ShowPowerBIPage) {
                  delete clientServiceResult[i]
                }
                break;
              }
            }
            const sortedClientService = clientServiceResult.map(clientService => {
              // if(clientService.Task.length) {
                const sortedTasks = clientService.Task.sort((a, b) => {
                  // Check for 'processing' with progress !== 100
                  if (a.status === 'processing' && a.progress !== 100 && (b.status !== 'processing' || b.progress === 100)) {
                    return -1; // 'processing' with incomplete progress comes first
                  } else if ((a.status !== 'processing' || a.progress === 100) && b.status === 'processing' && b.progress !== 100) {
                    return 1;  // Move non-'processing' or fully completed tasks down
                  }
    
                  // Then check for 'pending' status
                  if (a.status === 'pending' && b.status !== 'pending') {
                    return -1; // 'pending' comes after 'processing'
                  } else if (a.status !== 'pending' && b.status === 'pending') {
                    return 1;  // Move non-'pending' tasks down
                  }
  
                  // Check for 'completed' status with progress === 100, and sort by 'updatedAt' in descending order
                  if (a.status === 'completed' && a.progress === '100' && b.status === 'completed' && b.progress === '100') {
                    const updatedAtA = new Date(a.updatedAt);
                    const updatedAtB = new Date(b.updatedAt);
                    return updatedAtB - updatedAtA; // Sort by updatedAt in descending order
                  }
  
                  return 0; // retain original order for tasks with other statuses
                });
  
                return {
                  ...clientService,
                  Task: sortedTasks
                };
              // }
            })
            // console.log('sortedClientService', sortedClientService)
            setUser(sortedClientService);
            setFilterData(sortedClientService);
            setWorkSpace(workspace);
  
            const customReports = await fetch(`/api/customReport/getPowerBIReports/${clientDetailsResult.id}`)
            const customReportsResult = await customReports.json()
  
            let showSubscribeButton = true;
            let subscribed = false;
            if (customReportsResult.error) {
              warningAlert(customReportsResult);
              showSubscribeButton = false;
            } else {
              subscribed = customReportsResult.subscribed;
            }
  
            setSubscribed(subscribed);
            setShowSubscribeButton(!showSubscribeButton);
  
            const allServiceRecord = await fetch("/api/service/getAllRecords")
            const allServiceRecordResutl = await allServiceRecord.json()
  
            if (allServiceRecordResutl.error) {
              setLoading(false);
              return warningAlert(allServiceRecordResutl);
            }
  
            serviceModal.data = allServiceRecordResutl;
            setServiceModal(serviceModal);
            setFilteredServiceModal(allServiceRecordResutl);
  
          }
        }

        setLoading(false);
      } catch (e) {
        let data = [];

        setFilterData(data);

        console.log(e);
        setLoading(false);
        warningAlert(e);
      }
      // await fetch(`/api/client/getAllRecords`)
      //   .then((res) => res.json())
      //   .then((data) => {
      //     if (data && !data.error) {
      //       // console.log(data)
      //       // let unsignedClient = data.filter((clnt) => clnt.Status === 6);
      //       // allClients = unsignedClient
      //       setClients(data);
      //       firstClient = data[0];
      //       // console.log(firstClient)
      //     } else {
      //       return;
      //     }
      //   })
      //   .then(() => {
      //     if (allClients && firstClient) {
      //       // setSelectedClientID(selectedClientID ? selectedClientID :
      //       //   url.length < 4 ? firstClient.ClientID : url[url.length - 1]
      //       // );
      //       fetch(
      //         `/api/client/showDetails/${sessionSelectedClientID ? sessionSelectedClientID : url.length < 4 ? firstClient.ClientID : url[url.length - 1]
      //         }`
      //       )
      //         .then((res) => res.json())
      //         .then((data) => {
      //           if (data && !data.error) {
      //             if (data.message === "Client not found") {
      //               // setClientID(firstClient.ClientID);
      //               setSelectedClientID(firstClient.ClientID);
      //               return history.push(`/auth/page-not-found`);
      //             }
      //             let clientDetails = data
      //             setSelectedClient(data);
      //             // console.log(data);
      //             fetch(`/api/clientService/getAllRecords/${data.id}`)
      //               .then((res) => res.json())
      //               .then((data) => {
      //                 if (data && !data.error) {
      //                   // console.log('clientService', data)
      //                   let workspace = {};

      //                   if (data.error) {
      //                     // console.log(data.error)
      //                     data = [];
      //                   }

      //                   for (let i = 0; i < data.length; i++) {
      //                     if (data[i].Service.id === 3) {
      //                       workspace = data[i];
      //                       if (!clientDetails.Details.ShowPowerBIPage) {
      //                         delete data[i]
      //                       }
      //                       break;
      //                     }
      //                   }

      //                   setUser(data);
      //                   setFilterData(data);
      //                   setWorkSpace(workspace);
      //                   setLoading(false);
      //                   // console.log(workspace);
      //                   // console.log(data);
      //                 } else {
      //                   return warningAlert(data);
      //                 }

      //               })
      //               .catch((err) => {
      //                 console.log(err);
      //               });

      //             fetch(`/api/customReport/getPowerBIReports/${data.id}`)
      //               .then((res) => res.json())
      //               .then((res) => {
      //                 let showSubscribeButton = true;
      //                 let subscribed = false;

      //                 if (res.error) {
      //                   warningAlert(res);
      //                   showSubscribeButton = false;
      //                 } else {
      //                   //console.log("no error", showSubscribeButton);
      //                   subscribed = res.subscribed;
      //                 }
      //                 // console.log(res)
      //                 setSubscribed(subscribed);
      //                 setShowSubscribeButton(!showSubscribeButton);
      //                 // console.log(res);
      //               })
      //               .catch((err) => {
      //                 console.log(err);
      //               });
      //           } else {
      //             setLoading(false);
      //             // console.log('ERROR',data)
      //             return warningAlert(data);
      //           }
      //         })
      //         .then(() => {
      //           fetch("/api/service/getAllRecords", {
      //             method: "GET",
      //             credentials: "include",
      //           })
      //             .then((response) => response.json())
      //             .then((service) => {
      //               // console.log("/api/service/getAllRecords", service)
      //               if (service.error) {
      //                 return warningAlert(service);
      //                 // service = {}
      //               }
      //               serviceModal.data = service;
      //               setServiceModal(serviceModal);
      //               setFilteredServiceModal(service)
      //               // console.log(serviceModal);
      //             })
      //             .catch((err) => {
      //               console.log(err);
      //             });
      //         })
      //         .catch((error) => {
      //           warningAlert(error);
      //           setLoading(false);
      //         });
      //     } else {
      //       setLoading(false);
      //     }
      //   })
      //   .catch((e) => {
      //     let data = [];

      //     setFilterData(data);

      //     console.log(e);
      //     setLoading(false);
      //     warningAlert(e);
      //   });
    }
  };

  useEffect(() => {
    // console.log(firmDetails)
    setFilterData([])
    setLoading(true);
    if (firmDetails?.Details?.Trial && firmDetails?.Details?.IsTrialExtended) {
      return history.push(`/`);
    }
    let url = window.location.pathname.split("/");
    if (!selectedClientID && url.length >= 4) {
      let clientID = url[url.length - 1];
      history.push(`/client/thirdpartyapplication/${clientID}`);
    }

    if (selectedClientID) {
      history.push(`/client/thirdpartyapplication/${selectedClientID}`);
    }

    setTimeout(() => {
      fetchAPIs();
    }, 100);

    // return () => {
    //   //added to cleanup asynchronous task
    //   setUser(user);
    //   // setLoading(loading);
    //   setFilterData(filterData);
    // };
    //eslint-disable-next-line
  }, [userDetails, selectedClientID]);

  // const toggleModal = () => {
  //   if (filterData.length >= 10) {
  //     return warningAlert(
  //       'The maximum number of 3rd part Applications has been reached. Do you want to upgrade your plan?',
  //     )
  //   }
  //   setModal(!modal)
  // }

  //#region EventSource Listener
  useEffect(() => {
    if (selectedClient && (selectedClient.id === 1644 || selectedClient.id === 1846)) {
      // console.log(selectedClient)
      let oldClientService = filterData;
      const eventSource = new EventSource(`https://evamobileservices.azurewebsites.net/api/task?businessId=${firmDetails.id}&clientId=${selectedClient.id}`);
      // const eventSource = new EventSource(`http://192.168.1.15:4000/api/task?businessId=${firmDetails.id}&clientId=${selectedClient.id}`);

      // Listen for messages from the server
      eventSource.onmessage = (event) => {
        // Parse the JSON data from the event
        let sseResult = JSON.parse(event.data);
        // console.log('sseResult', sseResult);

        if (sseResult.result.length) {
          let taskResults = sseResult.result;

          // Create a new copy of oldClientService to ensure immutability
          const updatedClientService = oldClientService.map(clientService => {
            // Find corresponding tasks for each client service
            const taskResultsForService = taskResults.filter(task => task.clientService === clientService.id);
            // console.log('taskResultsForService', taskResultsForService)

            // If there are no relevant tasks for this client service, check first and change status to completed if task progress equal to 100 and status is processing or return it as is
            if (taskResultsForService.length === 0) return clientService;

            // Create a new version of the clientService with updated tasks
            const updatedTasks = clientService.Task.map(task => {
              const taskToUpdate = taskResultsForService.find(result => result.id === task.id);

              // If the task needs to be updated, return a new object with updated progress and status
              if (taskToUpdate) {
                return {
                  ...task,
                  progress: taskToUpdate.progress,
                  status: taskToUpdate.status
                };
              }

              // Otherwise, return the task as is
              return task;
            });

            // Sort tasks: 'processing' with progress !== 100 first, then 'pending', and 'completed' last
            const sortedTasks = updatedTasks.sort((a, b) => {
              // Check for 'processing' with progress !== 100
              if (a.status === 'processing' && a.progress !== 100 && (b.status !== 'processing' || b.progress === 100)) {
                return -1; // 'processing' with incomplete progress comes first
              } else if ((a.status !== 'processing' || a.progress === 100) && b.status === 'processing' && b.progress !== 100) {
                return 1;  // Move non-'processing' or fully completed tasks down
              }

              // Then check for 'pending' status
              if (a.status === 'pending' && b.status !== 'pending') {
                return -1; // 'pending' comes after 'processing'
              } else if (a.status !== 'pending' && b.status === 'pending') {
                return 1;  // Move non-'pending' tasks down
              }

              // Check for 'completed' status with progress === 100, and sort by 'updatedAt' in descending order
              if (a.status === 'completed' && a.progress === '100' && b.status === 'completed' && b.progress === '100') {
                const updatedAtA = new Date(a.updatedAt);
                const updatedAtB = new Date(b.updatedAt);
                return updatedAtB - updatedAtA; // Sort by updatedAt in descending order
              }

              return 0; // retain original order for tasks with other statuses
            });


            // Return a new clientService object with sorted tasks
            return {
              ...clientService,
              Task: sortedTasks
            };
          });

          const finalSort = updatedClientService.map(clientService => {
            const statusUpdated = clientService.Task.map(task => {
              if(task.progress === "100" && task.status !== "completed") {
                return {
                  ...task,
                  status: "completed"
                }
              }
              return task;
            })
            return {
              ...clientService,
              Task: statusUpdated
            }
          })

          // Update the state with the new data
          // console.log('updatedClientService', finalSort);
          setUser(finalSort);
          setFilterData(finalSort);
        }
      };

      // Handle any errors that occur
      eventSource.onerror = (error) => {
        console.error('Error with SSE:', error);
        // eventSource.close(); // Optionally close the connection on error
      };

      // Cleanup when the component unmounts
      return () => {
        eventSource.close();
      };
    } else {
      console.log("not-applied-to-this-client")
    }
  }, [selectedClient, filterData, user])
  //#endregion

  const warningAlert = (response) => {
    setAlert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message ||
          response.error ||
          response.Details.warning ||
          response}
      </ReactBSAlert>
    );
  };
  const confirmDelete = (data) => {
    setAlert(
      <ReactBSAlert
        warning
        title="Warning"
        onConfirm={() => handleDelete(data)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        showCancel
        btnSize=""
      >
        Are you sure yoou want to delete {data.Service.Name}?
      </ReactBSAlert>
    );
  };

  const successAlert = (response) => {
    setAlert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => {
          closeServiceModal();
          setAlert(null);
        }}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message}
      </ReactBSAlert>
    );
  };

  // const redirectToPricing = () => {
  //   setAlert(null)
  //   setModal(false)
  //   return history.replace('/main/pricing')
  // }

  const thirdPartyList = (id) => {
    const customText = (
      <div className="col-sm-12 mt-4 text-center">
        <h5>
          {id === 3
            ? "No Integrations found."
            : id === 2
              ? "No Integrations Pending Refresh found."
              : "No Integrations found."}
          {/* {id === 3
            ? "No 3rd Party Reconnection found."
            : id === 2
              ? "No 3rd Party Pending Refresh found."
              : "No 3rd Party Applications found."} */}
        </h5>
      </div>
    );

    if (id === 1) {
      if (filterData.length === 0) {

        if (loading) return <CustomSpinner />;
        return customText;
      } else {
        // console.log(filterData)
        return filterData.map((data, i) => ClientCard(data, i));
      }
    }

    if (id === 2) {
      let filter = filterData.filter(
        (e) => e.Status === 1 && (e.Service.id === 1 || e.Service.id === 24)
      );

      if (filter.length === 0) {
        if (loading) return <CustomSpinner />;
        return customText;
      } else {
        return filterData
          .filter(
            (e) => e.Status === 1 && (e.Service.id === 1 || e.Service.id === 24)
          )
          .map((data, i) => ClientCard(data, i));
      }
    }

    if (id === 3) {
      let filter = filterData.filter((e) => e.Status === 15);

      if (filter.length === 0) {
        if (loading) return <CustomSpinner />;
        return customText;
      } else {
        return filterData
          .filter((e) => e.Status === 15)
          .map((data, i) => ClientCard(data, i));
      }
    }
  };

  const ClientCard = (data, i) => {
    const intDetails = integrationDetails(data);
    return <ClientThirdPartyCard
      i={i}
      key={i}
      data={data}
      intDetails={intDetails}
      loading={loading}
      subscribe={subscribe}
      subscribed={subscribed}
      showStatus={showStatus}
      unsubscribe={unsubscribe}
      confirmDelete={confirmDelete}
      handleRefresh={handleRefresh}
      openStaffList={openStaffList}
      openTimeSheet={openJobCost}
      accountingFirm={firmDetails}
      handleReconnect={handleReconnect}
      showSubscribeButton={showSubscribeButton}
      userDetails={userDetails}
      openUploadCSV={openWIPCSVModal}
      openJobCostUploadCSV={openJobCostCSVModal}
      openWMBUploadCSV={openWMBWIPCSVModal}
      openWMBJobCostUploadCSV={openWMBJobCostCSVModal}
      openJobTaskCSVModal={openJobTaskCSVModal}
      openIncomeTaxCSVModal={openIncomeTaxCSVModal}
      openWMBStaffList={openWMBStaffList}
      openWMBJobTaskCSVModal={openWMBJobTaskCSVModal}
      openWMBIncomeTaxCSVModal={openWMBIncomeTaxCSVModal}
      firmDetails={firmDetails}
    />;
  }

  const handleSearch = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    let filterString = value;
    setFilterData(dataFilter(filterString, user));
  };

  // const copyToClipboard = (e) => {
  // 	publicKey.select();
  // 	document.execCommand('copy');

  // 	e.target.focus();
  // 	console.log("Public Key Certificate has been copied successfully");
  // };

  const subscribe = () => {
    // console.log('subsssss')
    setShowBlockUI(true);
    fetch("/api/customReport/subscribeToWorkspace", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log("subscribe", response);

        if (response.error) {
          setShowBlockUI(false);
          return warningAlert(response);
        }

        successAlert(response);
        setSubscribed(true);
        setShowBlockUI(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const unsubscribe = () => {
    // console.log("unssssss");
    setShowBlockUI(true);
    fetch("/api/customReport/unsubscribeToWorkspace", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log("unsubscribe", response);

        if (response.error) {
          setShowBlockUI(false);
          return warningAlert(response);
        }
        successAlert(response);
        setSubscribed(false);
        setShowBlockUI(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openWMBStaffList = (record) => {
    // console.log(record)
    setSelectedData(record)
    let details = {}

    details.dw = 0;
    if (process.env.REACT_APP_IS_STAGING === 'true') {
      if (record.Details?.dbDestination === 'DW') {
        details.csID = 1;
        details.dbName = "etani-data-warehouse";
        details.tbName = "XYZ_CLIENT1_WFM2_CAPACITYDEV";
        details.sTbName = "XYZ_CLIENT1_WFM2_Staff"
        details.taskRateTbName = "XYZ_CLIENT1_WFM2_TasksList"
        details.dw = '1';
        details.integrationType = 'wfm2'
      } else {
        details.csID = 1;
        details.dbName = "EtaniDemoData";
        details.tbName = 'XEROPRACTICEMANAGER_CAPACITYDEV';
      }
    } else {
      const csId = record.Details?.Legacy?.id ? record.Details?.Legacy?.id : record.id;
      const clientID = selectedClient?.Details?.Legacy?.id ? selectedClient?.Details?.Legacy?.id : selectedClient?.id;
      details.csID = csId + "";
      details.dbName = firmDetails.AccountingFirmID;
      // This is to match database name on DW and MSSQL
      let firmCode = firmDetails.Code.includes("-") ? firmDetails.Code.replace("-", "") : firmDetails.Code
      if (record.Details?.dbDestination === 'DW') {
        details.taskRateTbName = `${firmCode}_CLIENT${clientID}_WFM2_TasksList`;
        details.tbName = `${firmCode}_CLIENT${clientID}_WFM2_CAPACITYDEV`;
        details.sTbName = `${firmCode}_CLIENT${clientID}_WFM2_Staff`;
        details.dbName = "etani-data-warehouse";
        details.clientId = `${firmCode}_CLIENT${clientID}`;
        details.firmId = firmDetails.id + "";
        details.dw = '1';
        details.integrationType = 'wfm2'
      } else {
        details.tbName = `WFM2_CAPACITYDEV`;
      }
    }
    wmbStaffListModal.db = {
      dbName: details.dbName,
      tbName: details.tbName,
      sTbName: details.sTbName,
      orgId: details.csID,
      dw: details.dw,
      clientId: details.clientId,
      firmId: details.firmId,
      taskRateTbName: details.taskRateTbName,
      integrationType: details.integrationType
    }
    setWmbIsStaffListModalOpen(true)
  }

  const closeWmbStaffListModal = () => {
    setWmbIsStaffListModalOpen(false)
  }

  const [isWmbWipModalOpen, setIsWmbWIPModalOpen] = useState(false);

  const openWMBWIPCSVModal = (data, clientServiceDetails) => {
    // console.log(data)
    // console.log(selectedClient)
    let details = {}

    details.dw = false;
    if (process.env.REACT_APP_IS_STAGING === 'true') {
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.csID = 1;
        details.dbName = "etani-data-warehouse";
        details.tbName = "XYZ_CLIENT1_WFM2_WIPcleared";
        details.dw = true;
        details.staging = true;
      } else {
        details.csID = 1;
        details.dbName = "EtaniDemoData";
        details.tbName = "WFM2_WIPcleared";
        details.staging = true;
      }
    } else {
      const csId = clientServiceDetails.Details?.Legacy?.id ? clientServiceDetails.Details?.Legacy?.id : clientServiceDetails.id;
      const clientID = selectedClient?.Details?.Legacy?.id ? selectedClient?.Details?.Legacy?.id : selectedClient?.id;
      details.csID = csId + "";
      details.dbName = firmDetails.AccountingFirmID;
      let firmCode = firmDetails.Code.includes("-") ? firmDetails.Code.replace("-", "") : firmDetails.Code
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.dbName = "etani-data-warehouse";
        details.tbName = `${firmCode}_CLIENT${clientID}_WFM2_WIPcleared`;
        details.dw = true;
      } else {
        details.tbName = `WFM2_WIPcleared`;
      }
    }
    // console.log(details)
    setIsWmbWIPModalOpen(true);
    setWmbWipDb(details);
  };

  const closeWmbCSVModal = () => {
    setIsWmbWIPModalOpen(false);
  };

  const [isWmbJobCostModalOpen, setIsWmbJobCostModalOpen] = useState(false);

  const openWMBJobCostCSVModal = (data, clientServiceDetails) => {
    // console.log(clientServiceDetails)
    let details = {}

    details.dw = false;
    if (process.env.REACT_APP_IS_STAGING === 'true') {
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.csID = 1;
        details.dbName = "Data Warehouse SQL Pool";
        details.tbName = "XYZ_CLIENT1_WFM2_JobCostUpload";
        details.dw = true;
        details.staging = true;
      } else {
        details.csID = 1;
        details.dbName = "EtaniDemoData";
        details.tbName = "WFM2_JobCostUpload";
        details.staging = true;
      }
    } else {
      const csId = clientServiceDetails.Details?.Legacy?.id ? clientServiceDetails.Details?.Legacy?.id : clientServiceDetails.id;
      const clientID = selectedClient?.Details?.Legacy?.id ? selectedClient?.Details?.Legacy?.id : selectedClient?.id;
      details.csID = csId + "";
      details.dbName = firmDetails.AccountingFirmID;
      let firmCode = firmDetails.Code.includes("-") ? firmDetails.Code.replace("-", "") : firmDetails.Code
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.tbName = `${firmCode}_CLIENT${clientID}_WFM2_JobCostUpload`;
        details.dw = true;
        details.dbName = "etani-data-warehouse";
      } else {
        details.tbName = `WFM2_JobCostUpload`;
      }
    }
    // console.log(details)
    setIsWmbJobCostModalOpen(true);
    setWmbJobCostDb(details);
  };

  const closeWmbJobCostCSVModal = () => {
    setIsWmbJobCostModalOpen(false);
  };

  const openStaffList = (record, activeOnly = true) => {
    setSelectedData(record)

    const { dbName, csID, tbName, dw, sTbName, clientId, firmId, taskRateTbName, integrationType } = integrationDetails(record);
    staffListModal.db = {
      dbName,
      tbName,
      sTbName,
      orgId: csID,
      dw,
      clientId,
      firmId,
      taskRateTbName,
      integrationType
    }
    setIsStaffListModalOpen(true);
  };
  const [isWipModalOpen, setIsWIPModalOpen] = useState(false);

  const openWIPCSVModal = (data, clientServiceDetails) => {
    // console.log(data)
    // console.log(selectedClient)
    let details = {}

    details.dw = false;
    if (process.env.REACT_APP_IS_STAGING === 'true') {
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.csID = 1;
        details.dbName = "Data Warehouse SQL Pool";
        details.tbName = "EtaniDemo_XEROPRACTICEMANAGER_WIPcleared";
        details.dw = true;
        details.staging = true;
      } else {
        details.csID = 1;
        details.dbName = "EtaniDemoData";
        details.tbName = "XEROPRACTICEMANAGER_WIPcleared";
        details.staging = true;
      }
    } else {
      const csId = clientServiceDetails.Details?.Legacy?.id ? clientServiceDetails.Details?.Legacy?.id : clientServiceDetails.id;
      const clientID = selectedClient?.Details?.Legacy?.id ? selectedClient?.Details?.Legacy?.id : selectedClient?.id;
      details.csID = csId + "";
      details.dbName = firmDetails.AccountingFirmID;
      let firmCode = firmDetails.Code.includes("-") ? firmDetails.Code.replace("-", "") : firmDetails.Code
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.dbName = "etani-data-warehouse";
        details.tbName = `${firmCode}_CLIENT${clientID}_XEROPRACTICEMANAGER_WIPcleared`;
        details.dw = true;
      } else {
        details.tbName = `XEROPRACTICEMANAGER_WIPcleared`;
      }
    }
    setIsWIPModalOpen(true);
    setWipDb(details);
  };

  const closeCSVModal = () => {
    setIsWIPModalOpen(false);
  };

  const [isJobCostModalOpen, setIsJobCostModalOpen] = useState(false);

  const openJobCostCSVModal = (data, clientServiceDetails) => {
    // console.log(clientServiceDetails)
    let details = {}

    details.dw = false;
    if (process.env.REACT_APP_IS_STAGING === 'true') {
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.csID = 1;
        details.dbName = "Data Warehouse SQL Pool";
        details.tbName = "EtaniDemo_XEROPRACTICEMANAGER_JobCostUpload";
        details.dw = true;
        details.staging = true;
      } else {
        details.csID = 1;
        details.dbName = "EtaniDemoData";
        details.tbName = "XEROPRACTICEMANAGER_JobCostUpload";
        details.staging = true;
      }
    } else {
      const csId = clientServiceDetails.Details?.Legacy?.id ? clientServiceDetails.Details?.Legacy?.id : clientServiceDetails.id;
      const clientID = selectedClient?.Details?.Legacy?.id ? selectedClient?.Details?.Legacy?.id : selectedClient?.id;
      details.csID = csId + "";
      details.dbName = firmDetails.AccountingFirmID;
      let firmCode = firmDetails.Code.includes("-") ? firmDetails.Code.replace("-", "") : firmDetails.Code
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.tbName = `${firmCode}_CLIENT${clientID}_XEROPRACTICEMANAGER_JobCostUpload`;
        details.dw = true;
        details.dbName = "etani-data-warehouse";
      } else {
        details.tbName = `XEROPRACTICEMANAGER_JobCostUpload`;
      }
    }
    setIsJobCostModalOpen(true);
    setJobCostDb(details);
  };

  const closeJobCostCSVModal = () => {
    setIsJobCostModalOpen(false);
  };

  const openJobCost = (record) => {
    timeSheetModal.show = true;
    setIsTimeSheetModalOpen(true)
  }

  const [isWMBJobTaskModalOpen, setWMBIsJobTaskModalOpen] = useState(false);
  const openWMBJobTaskCSVModal = (data, clientServiceDetails) => {
    // console.log(data)
    // console.log(clientServiceDetails)
    let details = {}

    details.dw = false;
    if (process.env.REACT_APP_IS_STAGING === 'true') {
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.csID = 1;
        details.dbName = "Data Warehouse SQL Pool";
        details.tbName = "XYZ_CLIENT1_WFM2_JOBTASK_REPORT";
        details.dw = true;
        details.staging = true;
      } else {
        details.csID = 1;
        details.dbName = "EtaniDemoData";
        details.tbName = "WFM2_JOBTASK_REPORT";
        details.staging = true;
      }
    } else {
      const csId = clientServiceDetails.Details?.Legacy?.id ? clientServiceDetails.Details?.Legacy?.id : clientServiceDetails.id;
      const clientID = selectedClient?.Details?.Legacy?.id ? selectedClient?.Details?.Legacy?.id : selectedClient?.id;
      details.csID = csId + "";
      details.dbName = firmDetails.AccountingFirmID;
      let firmCode = firmDetails.Code.includes("-") ? firmDetails.Code.replace("-", "") : firmDetails.Code
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.tbName = `${firmCode}_CLIENT${clientID}_WFM2_JOBTASK_REPORT`;
        details.dw = true;
        details.dbName = "etani-data-warehouse";
      } else {
        details.tbName = `WFM2_JOBTASK_REPORT`;
      }
    }
    // console.log(details)
    setWMBIsJobTaskModalOpen(true)
    setWmbJobTaskDb(details);
  }

  const closeWmbJobTaskCSVModal = () => {
    setWMBIsJobTaskModalOpen(false);
  };

  const [isJobTaskModalOpen, setIsJobTaskModalOpen] = useState(false);
  const openJobTaskCSVModal = (data, clientServiceDetails) => {
    // console.log(data)
    // console.log(clientServiceDetails)
    let details = {}

    details.dw = false;
    if (process.env.REACT_APP_IS_STAGING === 'true') {
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.csID = 1;
        details.dbName = "Data Warehouse SQL Pool";
        details.tbName = "EtaniDemo_XEROPRACTICEMANAGER_JOBTASK_REPORT";
        details.dw = true;
        details.staging = true;
      } else {
        details.csID = 1;
        details.dbName = "EtaniDemoData";
        details.tbName = "XEROPRACTICEMANAGER_JOBTASK_REPORT";
        details.staging = true;
      }
    } else {
      const csId = clientServiceDetails.Details?.Legacy?.id ? clientServiceDetails.Details?.Legacy?.id : clientServiceDetails.id;
      const clientID = selectedClient?.Details?.Legacy?.id ? selectedClient?.Details?.Legacy?.id : selectedClient?.id;
      details.csID = csId + "";
      details.dbName = firmDetails.AccountingFirmID;
      let firmCode = firmDetails.Code.includes("-") ? firmDetails.Code.replace("-", "") : firmDetails.Code
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.tbName = `${firmCode}_CLIENT${clientID}_XEROPRACTICEMANAGER_JOBTASK_REPORT`;
        details.dw = true;
        details.dbName = "etani-data-warehouse";
      } else {
        details.tbName = `XEROPRACTICEMANAGER_JOBTASK_REPORT`;
      }
    }
    // console.log(details)
    setIsJobTaskModalOpen(true)
    setJobTaskDb(details);
  }

  const closeJobTaskCSVModal = () => {
    setIsJobTaskModalOpen(false);
  };
  const [isWmbIncomeTaxModalOpen, setIsWmbIncomeTaxModalOpen] = useState(false);
  const openWMBIncomeTaxCSVModal = (data, clientServiceDetails) => {
    // console.log(data)
    let details = {}

    details.dw = false;
    if (process.env.REACT_APP_IS_STAGING === 'true') {
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.csID = 1;
        details.dbName = "Data Warehouse SQL Pool";
        details.tbName = "XYZ_CLIENT1_WFM2_ITCRPT_REPORTS";
        details.dw = true;
        details.staging = true;
      } else {
        details.csID = 1;
        details.dbName = "EtaniDemoData";
        details.tbName = "XEROPRACTICEMANAGER_ITCRPT_REPORTS";
        details.staging = true;
      }
    } else {
      const csId = clientServiceDetails.Details?.Legacy?.id ? clientServiceDetails.Details?.Legacy?.id : clientServiceDetails.id;
      const clientID = selectedClient?.Details?.Legacy?.id ? selectedClient?.Details?.Legacy?.id : selectedClient?.id;
      details.csID = csId + "";
      details.dbName = firmDetails.AccountingFirmID;
      let firmCode = firmDetails.Code.includes("-") ? firmDetails.Code.replace("-", "") : firmDetails.Code
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.tbName = `${firmCode}_CLIENT${clientID}_WFM2_ITCRPT_REPORTS`;
        details.dw = true;
        details.dbName = "etani-data-warehouse";
      } else {
        details.tbName = `XEROPRACTICEMANAGER_ITCRPT_REPORTS`;
      }
    }
    setIsWmbIncomeTaxModalOpen(true)
    setWmbIncomeTaxDb(details);
  }

  const closeWmbIncomeTaxCSVModal = () => {
    setIsWmbIncomeTaxModalOpen(false);
  };


  const [isIncomeTaxModalOpen, setIsIncomeTaxModalOpen] = useState(false);
  const openIncomeTaxCSVModal = (data, clientServiceDetails) => {
    // console.log(data)
    let details = {}

    details.dw = false;
    if (process.env.REACT_APP_IS_STAGING === 'true') {
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.csID = 1;
        details.dbName = "Data Warehouse SQL Pool";
        details.tbName = "EtaniDemo_XEROPRACTICEMANAGER_ITCRPT_REPORTS";
        details.dw = true;
        details.staging = true;
      } else {
        details.csID = 1;
        details.dbName = "EtaniDemoData";
        details.tbName = "XEROPRACTICEMANAGER_ITCRPT_REPORTS";
        details.staging = true;
      }
    } else {
      const csId = clientServiceDetails.Details?.Legacy?.id ? clientServiceDetails.Details?.Legacy?.id : clientServiceDetails.id;
      const clientID = selectedClient?.Details?.Legacy?.id ? selectedClient?.Details?.Legacy?.id : selectedClient?.id;
      details.csID = csId + "";
      details.dbName = firmDetails.AccountingFirmID;
      let firmCode = firmDetails.Code.includes("-") ? firmDetails.Code.replace("-", "") : firmDetails.Code
      if (clientServiceDetails.Details.dbDestination === 'DW') {
        details.tbName = `${firmCode}_CLIENT${clientID}_XEROPRACTICEMANAGER_ITCRPT_REPORTS`;
        details.dw = true;
        details.dbName = "etani-data-warehouse";
      } else {
        details.tbName = `XEROPRACTICEMANAGER_ITCRPT_REPORTS`;
      }
    }
    setIsIncomeTaxModalOpen(true)
    setIncomeTaxDb(details);
  }

  const closeIncomeTaxCSVModal = () => {
    setIsIncomeTaxModalOpen(false);
  };

  const openTimeSheet = (record) => {
    console.log(record)
    setSelectedData(record)
    setShowBlockUI(true);
    let dbName = "EtaniDemoData"
    let tbName = 'XEROPRACTICEMANAGER_TimesheetsTESTING'
    fetch(`/api/clientService/getTimeSheet?databaseName=${dbName}&tableName=${tbName}&orgID=${1}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return warningAlert(response.error);
        timeSheetModal.show = true;

        console.log(response.data[0]);

        setShowBlockUI(false);
        timeSheetModal.data = response.data[0];
        setTimeout(() => {
          setIsTimeSheetModalOpen(true)
          setShowBlockUI(false);
          setTimeSheetModal(timeSheetModal);
        }, 1000);
      })
      .catch((err) => {
        setShowBlockUI(false);
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            confirmBtnText="Ok"
            btnSize=""
          >
            Something went wrong. Please try again later.
          </ReactBSAlert>
        );
        console.log(err);
      });
  };

  const closeStaffListModal = () => {
    console.log("withEditedData : ")
    console.log(withEditedData)
    if (withEditedData.length > 0) {
      setAlert(
        <ReactBSAlert
          warning
          title="Warning"
          onConfirm={() => {
            closeStaffListModalHandler()
            setAlert(null)
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="warning"
          confirmBtnText="Yes"
          showCancel
          btnSize=""
        >
          Do you wish to close the modal with unsaved changes?
        </ReactBSAlert>
      );

    } else {
      closeStaffListModalHandler()
    }
  };
  const closeStaffListModalHandler = () => {
    let newstaffListModal = staffListModal
    newstaffListModal.show = false;
    setStaffListModal(newstaffListModal);
    setIsStaffListModalOpen(false)
    setwithEditedData([])
  }

  const closeTimeSheetModal = () => {
    let newTimeSheetModal = staffListModal
    newTimeSheetModal.show = false;
    setTimeSheetModal(newTimeSheetModal);
    setIsTimeSheetModalOpen(false)
  };

  const openStaffDetailsModal = (row, type) => {
    setIsStaffDetailsModalOpen(true)
    setStaffDetailsModal({
      show: true,
      record: row,
      type: type,
    });
  };

  const closeStaffDetailsModal = () => {
    //console.log(row);

    staffDetailsModal.show = false;
    setIsStaffDetailsModalOpen(false)
    setTimeout(() => {
      setStaffDetailsModal(staffDetailsModal);
    }, 1000);
  };

  const handleStaffDetailsUpdate = (event) => {
    // console.log('saved', staffDetailsModal.record)
    event.preventDefault();

    setShowBlockUI(true)
    // let dbName = firmDetails.AccountingFirmID
    let dbName = "EtaniDemoData"

    fetch(`/api/clientService/updateStaffDetails`, {
      method: "PUT",
      body: JSON.stringify({ ...staffDetailsModal.record, dbName }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          setShowBlockUI(false)
          return console.log(response.error)
        };
        // console.log(response.data);
        staffDetailsModal.show = false;

        // console.log(response.success);


        setStaffDetailsModal(staffDetailsModal);
        setIsStaffDetailsModalOpen(false)
        setShowBlockUI(false)
        openStaffList(selectedData)
      })
      .catch((err) => {
        setShowBlockUI(false)
        console.log(err);
      });
  };

  const handleStaffDetailsInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    staffDetailsModal.record[name] = value

    setStaffDetailsModal(staffDetailsModal)
  }

  const staffDetailsFormatTime = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    // if (!/[0-9:]/.test(String.fromCharCode(value))) {
    //   // console.log(!/[0-9:]/.test(String.fromCharCode(value)))
    //   event.preventDefault();
    //   return
    // }

    if (!value.match(/\d+/g)) {
      return
    }

    let extractedNumbers = value.match(/\d+/g).join('')
    //extractedNumbers = extractedNumbers.replace('0', '')
    extractedNumbers = parseInt(extractedNumbers).toString()

    //console.log(extractedNumbers);

    while (extractedNumbers.length < 3) {
      extractedNumbers = '0' + extractedNumbers
    }

    extractedNumbers = `${extractedNumbers.slice(
      0,
      extractedNumbers.length - 2,
    )}:${extractedNumbers.slice(extractedNumbers.length - 2)}`
    staffDetailsModal.record[name] = extractedNumbers

    setStaffDetailsModal(staffDetailsModal)
    //setValue(time);
  }

  const staffDetailsFormatWholeNumber = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let newValue = value;
    if (newValue === "") newValue = "0";

    // if (!/[0-9:]/.test(String.fromCharCode(value))) {
    //   // console.log(!/[0-9:]/.test(String.fromCharCode(value)))
    //   event.preventDefault();
    //   return
    // }
    if (!value.match(/\d+/g)) {
      return
    }

    let extractedNumbers = newValue.match(/\d+/g).join("");

    extractedNumbers = parseInt(extractedNumbers).toString();
    staffDetailsModal.record[name] = extractedNumbers;

    setStaffDetailsModal(staffDetailsModal);
  };


  const calculateTotalHoursAndMinutes = (record) => {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
    let totalHours = 0;
    let totalMinutes = 0;

    days.forEach(day => {
      if (record[day]) {
        const [hours, minutes] = record[day].split(':');
        totalHours += parseInt(hours, 10);
        totalMinutes += parseInt(minutes, 10);
      }
    });

    const totalExtraHours = totalMinutes > 0 ? Math.floor(totalMinutes / 60) : 0;
    totalMinutes = totalMinutes % 60;
    totalHours += totalExtraHours;

    return [totalHours, totalMinutes];
  }

  const staffDetailsFormatTimeOnBlur = (event) => {
    // Check if event and event.target exist
    if (!event || !event.target) return;

    const { target } = event;
    const { value, name } = target;

    // Check if value and name exist
    if (!value || !name) return;

    // Check if value is a string and contains digits
    if (typeof value !== 'string' || !value.match(/\d+/g)) return;

    let extractedNumbers = value.match(/\d+/g).join('');
    extractedNumbers = parseInt(extractedNumbers, 10).toString();

    while (extractedNumbers.length < 3) {
      extractedNumbers = '0' + extractedNumbers;
    }

    let hours = parseInt(extractedNumbers.slice(0, extractedNumbers.length - 2), 10);
    let minutes = parseInt(extractedNumbers.slice(extractedNumbers.length - 2), 10);
    let extraHours = minutes > 0 ? Math.floor(minutes / 60) : 0;
    minutes = minutes % 60;
    hours += extraHours;

    let minutesStr = minutes.toString();
    if (minutesStr.length < 2) minutesStr = '0' + minutesStr;

    extractedNumbers = `${hours.toString()}:${minutesStr}`;

    // Check if staffDetailsModal and staffDetailsModal.record exist
    if (!staffDetailsModal || !staffDetailsModal.record) return;

    // Update staffDetailsModal.record
    const updatedRecord = { ...staffDetailsModal.record, [name]: extractedNumbers };

    // Calculate total hours and minutes
    const [totalHours, totalMinutes] = calculateTotalHoursAndMinutes(updatedRecord);
    let totalMinutesStr = totalMinutes.toString();
    if (totalMinutesStr.length < 2) totalMinutesStr = '0' + totalMinutesStr;

    // Update 'Total'
    updatedRecord['Total'] = `${totalHours.toString()}:${totalMinutesStr}`;

    // Update staffDetailsModal
    setStaffDetailsModal({ ...staffDetailsModal, record: updatedRecord });
  }

  const refreshNow = (clientService) => {
    setShowBlockUI(true);

    fetch(`/api/clientService/refreshNowSolo?clientService=${clientService}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        setShowBlockUI(false);

        // console.log("Refresh Now Response", response);

        if (response.error) return warningAlert(response);
        successAlert(response);

        if (response.data) {
          for (let i = 0; i < response.data.length; i++) {
            for (let ii = 0; ii < user.length; ii++) {
              if (user[ii].id === response.data[i].id) {
                user[ii].Status = response.data[i].Status;
                break;
              }
            }
            for (let ii = 0; ii < filterData.length; ii++) {
              if (filterData[ii].id === response.data[i].id) {
                filterData[ii].Status = response.data[i].Status;
                break;
              }
            }
          }

          // console.log("USER", user);
          // console.log("FILETERED", filterData);
        }

        setUser(user);
        setFilterData(filterData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeModal = () => {
    setError("");
    setModal({
      title: modal.title,
      show: false,
      record: modal.record,
      action: modal.action,
      format: modal.format,
      details: modal.details,
      logo: modal.logo,
    });
  };

  const closeServiceModal = () => {
    setServiceModal({
      show: false,
      data: serviceModal.data,
    });
    setFilteredServiceModal(serviceModal.data)
  };

  // const closeAuthorizeModal = () => {
  //   authorizeModal.show = false
  //   setAuthorizeModal(authorizeModal)
  // }

  const openServiceModal = () => {
    // console.log(userDetails?.User?.Details?.Authority);
    userDetails?.User?.Details?.Authority?.ThirdPartyConnectors || userDetails?.User?.UserAccess === 5
      ? setServiceModal({
        show: true,
        data: serviceModal.data,
      })
      : setAlert(
        <ReactBSAlert
          warning
          title="Warning"
          onConfirm={() => setAlert(null)}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          Your don't have permissions to do this task.
        </ReactBSAlert>
      );
  };

  const handleReconnect = (serviceId, clientServiceId) => {
    // console.log(serviceId, clientServiceId)
    setShowBlockUI(true);
    setTimeout(() => {
      let params =
        "width=600,height=800,top=" +
        (Math.round(window.innerHeight / 2) - 400) +
        ",left=" +
        (Math.round(window.innerWidth / 2) - 300);
      //console.log(params);
      let popupWindow = window.open(
        `/api/${serviceId === 69 || serviceId === 70 ? `workflowmax` : `xeroOAuth`}/centralize?id=${serviceId}&clientServiceId=${clientServiceId}&clientId=${selectedClient.id}`,
        "popup",
        params
      );
      //popupWindow.onunload = () => alert('Popup window closed');

      const focusBehaviour = () => {
        //console.log('testing');
        if (popupWindow.closed) {
          //console.log('closed');
          window.removeEventListener("focus", focusBehaviour);
        } else {
          //popupWindow.blur();
          popupWindow.focus();
          //popupWindow.alert("Please finish this step first");
        }
        //this.alert('testing');
      };
      window.addEventListener("focus", focusBehaviour);
      setShowBlockUI(false);
    }, 1500);
  };

  const openModal = (record) => {
    // console.log('open')
    if (record) {
      setModal({
        title: "Update Integrations",
        // title: "Update 3rd Party Application",
        show: true,
        record: record.Details,
        actionUrl: "/api/clientService/updateRecord?id=" + record.id,
        actionUrlMethod: "PUT",
      });
    } else {
      setModal({
        title: "Add Integrations",
        // title: "Add 3rd Party Application",
        show: true,
        record: {},
        actionUrl: "/api/clientService/createRecordManual?ServiceID=1",
        actionUrlMethod: "POST",
      });
    }
  };

  const handleDelete = (record) => {
    setShowBlockUI(true);
    fetch("/api/clientService/deleteRecord?id=" + record.id, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) warningAlert(response);
        else {
          for (let i = 0; i < user.length; i++) {
            if (user[i].id === record.id) {
              user.splice(i, 1);
              break;
            }
          }

          for (let i = 0; i < filterData.length; i++) {
            if (filterData[i].id === record.id) {
              filterData.splice(i, 1);
              break;
            }
          }

          setTimeout(() => {
            setShowBlockUI(false);
            setUser(user);
            setFilterData(filterData);
            // console.log(response);
            successAlert(response);
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRefresh = (obj) => {
    setShowBlockUI(true);
    fetch(
      "/api/clientService/updateAzureSQLRecords?clientServiceId=" + obj.id,
      {
        method: "PUT",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return warningAlert(response);
        setShowBlockUI(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    modal.record[name] = value;
    // console.log(modal.record)
    setError("");
    setModal(modal);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let err = "This field is required!";

    if (modal.format === "name") {
      if (!modal.record.Name) {
        return setError(err);
      }
    }

    if (modal.format === "dear") {
      if (modal.AccountID === undefined || modal.Key === undefined) {
        return setError(err);
      }
    }

    setShowBlockUI(true);

    fetch(modal.actionUrl, {
      method: modal.actionUrlMethod,
      body: JSON.stringify(modal.record),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        setTimeout(() => {
          setShowBlockUI(false);

          if (response.error) {
            return warningAlert(response);
          } else {
            if (modal.actionUrlMethod === "POST" && response.record) {
              user.push(response.record);
              modal.show = false;

              setModal(modal);
              setUser(user);
              setFilterData(filterData);
              // (() => handleSearch(filterString || ""))
              successAlert(response);
            }
          }
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showStatus = (status, service, clientService, i) => {
    if (status === 1) {
      if (service === 3)
        return (
          <Button
            color="success"
            size="sm"
            className="btn-rounded"
            style={{ borderRadius: "50px" }}
          >
            Workspace Active
          </Button>
        );
      else if (service === 1 || service === 24)
        return (
          <Button
            color="success"
            size="sm"
            className="btn-rounded"
            onClick={() => refreshNow(clientService)}
            style={{ borderRadius: "50px" }}
          >
            Refresh Now
          </Button>
        );
      else
        return (
          <Button
            color="success"
            size="sm"
            className="btn-rounded"
            style={{ borderRadius: "50px" }}
          >
            Dataset Active
          </Button>
        );
    } else if (status === 9) {
      return (
        <Button
          color="warning"
          size="sm"
          className="btn-rounded"
          style={{ borderRadius: "50px" }}
        >
          Data Loading
        </Button>
      );
    } else if (status === 12) {
      return (
        <Button
          color="warning"
          size="sm"
          className="btn-rounded"
          style={{ borderRadius: "50px" }}
        >
          Updating Data
        </Button>
      );
    } else if (status === 14) {
      return (
        <Button
          color="warning"
          size="sm"
          className="btn-rounded"
          style={{ borderRadius: "50px" }}
        >
          Refreshing Dataflow
        </Button>
      );
    } else if (status === 15) {
      return (
        <Button
          color="warning"
          size="sm"
          className="btn-rounded"
          style={{ borderRadius: "50px" }}
        >
          Refreshing Dataset
        </Button>
      );
    } else if (status === 13) {
      return (
        <>
          <Button
            id={`btn${i}`}
            color="danger"
            size="sm"
            className="btn-rounded"
            onClick={() => handleReconnect(service, clientService)}
          >
            Disconnected
          </Button>
          <UncontrolledTooltip delay={0} placement="top" target={`btn${i}`}>
            Disconnected - Please click to reconnect
          </UncontrolledTooltip>
        </>
      );
    }
  };

  const showServiceStatus = (status, i) => {
    if (status === 1) {
      return (
        <Button
          color="success"
          id={`active${i}`}
          size="sm"
          className="btn-rounded"
          style={{
            height: 20,
            width: 20,
            borderRadius: "50%",
            transform: "scale(.9)",
          }}
        >
          <UncontrolledTooltip target={`active${i}`}>
            This service is Active
          </UncontrolledTooltip>
        </Button>
      );
    } else if (status === 11) {
      return (
        <Button
          color="warning"
          size="sm"
          id={`id${i}`}
          className="btn-rounded"
          style={{
            height: 20,
            width: 20,
            borderRadius: "50%",
            transform: "scale(.9)",
          }}
        >
          <UncontrolledTooltip target={`id${i}`}>
            This service is not yet available
          </UncontrolledTooltip>
        </Button>
      );
    }
  };


  const handleUpgradeToBusinessPlan = () => {
    // console.log(firmDetails.CountryCode)
    // return
    setShowBlockUI(true);
    fetch("/api/pricing/upgradeOrDowngradePlan", {
      method: "PUT",
      body: JSON.stringify({
        planCode: "TIER2",
        countryCode: firmDetails.CountryCode,
        forUpgrade: true,
      }),
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setShowBlockUI(false)
        if (data && !data.error) {
          setAlert(
            <ReactBSAlert
              success
              title="Success"
              onConfirm={() => {
                getDetails(userDetails?.AccountingFirm)
                setAlert(null)
              }}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              <>
                <p>
                  You are now subscribed to <strong style={{ fontWeight: 700 }}>{`Etani Business`}</strong>.
                </p>
                <p>
                  You can continue to use the platform.etani URL but we have now setup a white-labelled platform for your business at <strong><a href={`https://${data.record.Details.azureDomain}`} target="_blank" rel="noreferrer">{data.record.Details.azureDomain}</a></strong>
                </p>
                <p>
                  Now, you can also add other third-party applications.
                </p>
                <p>
                  Welcome to Etani!
                </p>
              </>
            </ReactBSAlert>
          );
        }
        if (data.error) {
          warningAlert(data.error)
        }
      })
      .catch(e => {
        console.log(e)
        setShowBlockUI(false)
        warningAlert(e)
      })
  }

  const openCreateModal = (service) => {
    setShowBlockUI(true);

    // These are the service ids of XERO, QBO and MYOB
    const allowedServiceIds = [1, 4, 7];

    //Edited by Joef: Quickbook Fix
    fetch("/api/accountingFirm/getRecord")
      .then((res) => res.json())
      .then((data) => {
        setShowBlockUI(false);
        if (data && !data.error) {
          if (data?.PricingPlan?.Code === 'TIER1' && !allowedServiceIds.includes(service.id)) {
            // console.log("Di to kasama")
            setAlert(
              <ReactBSAlert
                warning
                title="Warning"
                onConfirm={() => {
                  handleUpgradeToBusinessPlan()
                  setAlert(null)
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="warning"
                confirmBtnText="Proceed"
                showCancel
                btnSize=""
              >
                <p>On the <strong>Essentials Plan</strong>, adding third-party applications other than <strong>Xero, Quickbooks and MYOB</strong> isn't possible. To continue adding this 3rd party app, click proceed to upgrade to our <strong>Business Plan</strong>.</p>
              </ReactBSAlert>
            );
          } else {
            const processService = (service) => {
              setShowBlockUI(true);

              switch (service.id) {
                case 4:
                  setTimeout(() => {
                    let params =
                      "width=600,height=800,top=" +
                      (Math.round(window.innerHeight / 2) - 400) +
                      ",left=" +
                      (Math.round(window.innerWidth / 2) - 300);
                    //console.log(params);
                    let popupWindow = window.open(
                      `/api/quickbooks/centralize?clientId=${selectedClient.id}`,
                      "popup",
                      params
                    );
                    //popupWindow.onunload = () => alert('Popup window closed');
                    const focusBehaviour = () => {
                      //console.log('testing');
                      if (popupWindow.closed) {
                        //console.log('closed');
                        window.removeEventListener("focus", focusBehaviour);
                      } else {
                        //popupWindow.blur();
                        popupWindow.focus();
                        //popupWindow.alert("Please finish this step first");
                      }
                      //this.alert('testing');
                    };
                    window.addEventListener("focus", focusBehaviour);
                    //popupWindow.onblur = () => alert('Popup window blured');
                    setServiceModal(serviceModal);
                    setShowBlockUI(false);
                  }, 1500);
                  break;

                case 7:
                  setTimeout(() => {
                    let params =
                      "width=600,height=800,top=" +
                      (Math.round(window.innerHeight / 2) - 400) +
                      ",left=" +
                      (Math.round(window.innerWidth / 2) - 300);
                    //console.log(params);
                    let popupWindow = window.open(
                      `/api/myob/centralize?clientId=${selectedClient.id}`,
                      "popup",
                      params
                    );
                    //popupWindow.onunload = () => alert('Popup window closed');

                    const focusBehaviour = () => {
                      //console.log('testing');

                      if (popupWindow.closed) {
                        //console.log('closed');
                        window.removeEventListener("focus", focusBehaviour);
                      } else {
                        //popupWindow.blur();
                        popupWindow.focus();
                        //popupWindow.alert("Please finish this step first");
                      }
                      //this.alert('testing');
                    };
                    window.addEventListener("focus", focusBehaviour);
                    //popupWindow.onblur = () => alert('Popup window blured');
                    setServiceModal(serviceModal);
                    setShowBlockUI(false);
                  }, 1500);
                  break;

                case 15:
                case 24:
                case 1:
                case 21:
                case 25:
                  setTimeout(() => {
                    let params =
                      "width=600,height=800,top=" +
                      (Math.round(window.innerHeight / 2) - 400) +
                      ",left=" +
                      (Math.round(window.innerWidth / 2) - 300);
                    //console.log(params);
                    let popupWindow = window.open(
                      `/api/xeroOAuth/centralize?id=${service.id}&clientId=${selectedClient.id}`,
                      "popup",
                      params
                    );

                    //popupWindow.onunload = () => alert('Popup window closed');
                    const focusBehaviour = () => {
                      //console.log('testing');
                      if (popupWindow.closed) {
                        //console.log('closed');
                        window.removeEventListener("focus", focusBehaviour);
                      } else {
                        //popupWindow.blur();
                        popupWindow.focus();
                        //popupWindow.alert("Please finish this step first");
                      }
                      //this.alert('testing');
                    };
                    window.addEventListener("focus", focusBehaviour);
                    //popupWindow.onblur = () => alert('Popup window blured');

                    setServiceModal(serviceModal);
                    setShowBlockUI(false);
                  }, 1500);
                  break;

                case 68:
                  setTimeout(() => {
                    let params =
                      "width=600,height=800,top=" +
                      (Math.round(window.innerHeight / 2) - 400) +
                      ",left=" +
                      (Math.round(window.innerWidth / 2) - 300);
                    //console.log(params);
                    let popupWindow = window.open(
                      `/api/xeroOAuth/centralize?id=${service.id}&clientId=${selectedClient.id}`,
                      "popup",
                      params
                    );

                    //popupWindow.onunload = () => alert('Popup window closed');
                    const focusBehaviour = () => {
                      //console.log('testing');
                      if (popupWindow.closed) {
                        //console.log('closed');
                        window.removeEventListener("focus", focusBehaviour);
                      } else {
                        //popupWindow.blur();
                        popupWindow.focus();
                        //popupWindow.alert("Please finish this step first");
                      }
                      //this.alert('testing');
                    };
                    window.addEventListener("focus", focusBehaviour);
                    //popupWindow.onblur = () => alert('Popup window blured');

                    setServiceModal(serviceModal);
                    setShowBlockUI(false);
                  }, 1500);
                  break;
                case 55:
                  setTimeout(() => {
                    let params =
                      "width=600,height=800,top=" +
                      (Math.round(window.innerHeight / 2) - 400) +
                      ",left=" +
                      (Math.round(window.innerWidth / 2) - 300);
                    //console.log(params);
                    let popupWindow = window.open(
                      `/api/actionstep/centralize?id=${service.id}&clientId=${selectedClient.id}`,
                      "popup",
                      params
                    );

                    //popupWindow.onunload = () => alert('Popup window closed');
                    const focusBehaviour = () => {
                      //console.log('testing');
                      if (popupWindow.closed) {
                        //console.log('closed');
                        window.removeEventListener("focus", focusBehaviour);
                      } else {
                        //popupWindow.blur();
                        popupWindow.focus();
                        //popupWindow.alert("Please finish this step first");
                      }
                      //this.alert('testing');
                    };
                    window.addEventListener("focus", focusBehaviour);
                    //popupWindow.onblur = () => alert('Popup window blured');

                    setServiceModal(serviceModal);
                    setShowBlockUI(false);
                  }, 1500);
                  break;

                case 69:
                case 70:
                  setTimeout(() => {
                    let params =
                      "width=600,height=800,top=" +
                      (Math.round(window.innerHeight / 2) - 400) +
                      ",left=" +
                      (Math.round(window.innerWidth / 2) - 300);
                    //console.log(params);
                    let popupWindow = window.open(
                      `/api/workflowmax/centralize?id=${service.id}&clientId=${selectedClient.id}`,
                      "popup",
                      params
                    );

                    //popupWindow.onunload = () => alert('Popup window closed');
                    const focusBehaviour = () => {
                      //console.log('testing');
                      if (popupWindow.closed) {
                        //console.log('closed');
                        window.removeEventListener("focus", focusBehaviour);
                      } else {
                        //popupWindow.blur();
                        popupWindow.focus();
                        //popupWindow.alert("Please finish this step first");
                      }
                      //this.alert('testing');
                    };
                    window.addEventListener("focus", focusBehaviour);
                    //popupWindow.onblur = () => alert('Popup window blured');

                    setServiceModal(serviceModal);
                    setShowBlockUI(false);
                  }, 1500);
                  break;

                case 3:
                  if (client && client.Name) modal.record.Name = client.Name;
                  setModal({
                    title: "Add Power BI Workspace",
                    show: true,
                    record: {},
                    actionUrl: "/api/clientService/createPowerBIWorkspace?ServiceID=3",
                    actionUrlMethod: "POST",
                    format: "name",
                    logo: service.Logo,
                  });
                  setShowBlockUI(false);
                  setServiceModal(serviceModal);
                  break;

                default:
                  setModal({
                    title: "Add " + service.Name,
                    show: true,
                    record: {},
                    actionUrl: `/api/clientService/createRecordManual?ServiceID=${service.id}&clientId=${selectedClient.id}`,
                    actionUrlMethod: "POST",
                    format: "default",
                    details: service.Details || {},
                    logo: service.Logo,
                  });
                  setShowBlockUI(false);
                  setServiceModal(serviceModal);
              }
            };

            if (service.Details && service.Details.warning) {
              setTimeout(() => {
                setAlert(
                  <ReactBSAlert
                    warning
                    title={service.Name}
                    onConfirm={() => {
                      setAlert(null);
                      processService(service);
                    }}
                    onCancel={() => setAlert(null)}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Proceed"
                    cancelBtnText="Cancel"
                    // reverseButtons={true}
                    showCancel={true}
                    btnSize=""
                  >
                    <p>{service.Details.warning}</p>
                  </ReactBSAlert>
                );
                // setShowBlockUI(false);
                // warningAlert(service);
              }, 1500);
              //return;
            } else processService(service);
          }
        }
      })

  };

  // const getLastItem = (thePath) =>
  //   thePath.substring(thePath.lastIndexOf("/") + 1);
  let workSpaceID =
    workspace && workspace.Details && workspace.Details.id
      ? workspace.Details.id
      : "Not available";

  const thirdPartyComponent = (
    <CustomThirdParty
      note="Note"
      loading={loading}
      list={thirdPartyList(1)}
      handleSearch={handleSearch}
      workSpaceLabel="Workspace ID"
      workSpaceInfo={loading ? "" : workSpaceID}
      onClick={loading ? null : () => openServiceModal()}
      selectedClient={selectedClient}
      firmDetails={firmDetails}
      headerName={
        loading ? "Integrations" : "All Integrations"
        // loading ? "3rd Party Applications" : "All 3rd Party Applications"
      }
      userDetails={userDetails}
    />
  );

  const handleUpdateRecord = (data) => {
    console.log(data);

    // Find the index of the client service that needs to be updated
    const oldIndex = filterData.findIndex(clientService => clientService.id === data.id);

    // If client service is found, proceed with update
    if (oldIndex !== -1) {
      // Create a copy of the client service and update its status
      const updatedClientService = {
        ...filterData[oldIndex],
        Status: data.Status,
        Task: data.Task
      };

      // Create a new array with the updated client service
      const newFilteredData = [...filterData];
      newFilteredData[oldIndex] = updatedClientService;

      // Sort tasks within the updated client service
      const sortedClientService = newFilteredData.map(clientService => ({
        ...clientService,
        Task: [...clientService.Task].sort((a, b) => {
          // Sort 'processing' with incomplete progress first
          if (a.status === 'processing' && a.progress !== 100 && (b.status !== 'processing' || b.progress === 100)) {
            return -1;
          } else if ((a.status !== 'processing' || a.progress === 100) && b.status === 'processing' && b.progress !== 100) {
            return 1;
          }

          // Then sort 'pending' status
          if (a.status === 'pending' && b.status !== 'pending') {
            return -1;
          } else if (a.status !== 'pending' && b.status === 'pending') {
            return 1;
          }

          // Check for 'completed' status with progress === 100, and sort by 'updatedAt' in descending order
          if (a.status === 'completed' && a.progress === '100' && b.status === 'completed' && b.progress === '100') {
            const updatedAtA = new Date(a.updatedAt);
            const updatedAtB = new Date(b.updatedAt);
            return updatedAtB - updatedAtA; // Sort by updatedAt in descending order
          }

          // Retain order for other statuses
          return 0;
        })
      }));

      // Update state with the new sorted data
      // console.log(sortedClientService);
      setFilterData(sortedClientService);
      setUser(sortedClientService);
    }
  }

  const handleSearchServices = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    let filterString = value;
    setFilteredServiceModal(dataFilterNameOnly(filterString, serviceModal.data));
  }

  return (
    <>
      {alert}
      {showBlockUI ? <BlockUI /> : null}
      <CustomHeader />
      <Container className="mt-6 fadeIn" fluid>
        {/* <div className="mt-3" style={{ position: "relative" }}>
          <ClientsInfo
            data={selectedClient}
            clientsList={clients}
            isLoading={loading}
            page={`thirdpartyapplication`}
          />
        </div> */}
        <div className="nav-wrapper">
          {loading
            ? null
            : !selectedClient
              ? null
              : ""}
        </div>
        {/* {
          thirdPartyComponent
        } */}
        <CustomThirdParty
          note="Note"
          loading={loading}
          list={thirdPartyList(1)}
          handleSearch={handleSearch}
          workSpaceLabel="Workspace ID"
          workSpaceInfo={loading ? "" : workSpaceID}
          onClick={loading ? null : () => openServiceModal()}
          selectedClient={selectedClient}
          firmDetails={firmDetails}
          headerName={
            loading ? "Integrations" : "All Integrations"
            // loading ? "3rd Party Applications" : "All 3rd Party Applications"
          }
          userDetails={userDetails}
        />
        {/* <TabContent className="tabs">
          {window.location.pathname === allThirdPartyRoute || loading ? (
            thirdPartyComponent
          ) : (
            <Switch>
              <Route
                path={`${allThirdPartyRoute}/${selectedClient.ClientID}`}
                exact
                render={() => thirdPartyComponent}
              />
            </Switch>
          )}
        </TabContent> */}

        <AddThirdParty
          bgColor={bgColor}
          loading={loading}
          openModal={openModal}
          textColor={textColor}
          modal={serviceModal.show}
          serviceData={serviceModal.data}
          openCreateModal={openCreateModal}
          openServiceModal={openServiceModal}
          closeServiceModal={closeServiceModal}
          showServiceStatus={showServiceStatus}
          workspace={workspace}
          handleSearchServices={handleSearchServices}
          filteredServiceData={filteredServiceModalData}
          firmDetails={firmDetails}
        />
        <AddServiceInfo
          modal={modal}
          openModal={openModal}
          closeModal={closeModal}
          errorStyle={errorStyle}
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          staffListModal={staffListModal}
          staffDetailsModal={staffDetailsModal}
          handleInputChange={handleInputChange}
          closeStaffListModal={closeStaffListModal}
          setStaffDetailsModal={setStaffDetailsModal}
          openStaffDetailsModal={openStaffDetailsModal}
          closeStaffDetailsModal={closeStaffDetailsModal}
          handleStaffDetailsUpdate={handleStaffDetailsUpdate}
          staffDetailsFormatWholeNumber={staffDetailsFormatWholeNumber}
          staffDetailsFormatTime={staffDetailsFormatTime}
          handleStaffDetailsInputChange={handleStaffDetailsInputChange}
          staffDetailsFormatTimeOnBlur={staffDetailsFormatTimeOnBlur}
        />

        <WmbStaffListModal
          isWmbStaffListModalOpen={isWmbStaffListModalOpen}
          closeWmbStaffListModal={closeWmbStaffListModal}
          ThirdPartyAppWmbStaffTable={
            <ThirdPartyAppWmbStaffTable
              warningAlert={warningAlert}
              selectedData={selectedData}
              dbDetails={wmbStaffListModal.db}
              openStaffList={openWMBStaffList}
              userDetails={userDetails}
              selectedClient={selectedClient}
              firmDetails={firmDetails}
              setwithEditedDataHandler={setwithEditedDataHandler}
              setShowBlockUI={setShowBlockUI}
              closeStaffListModal={closeStaffListModal}
              handleUpdateRecord={handleUpdateRecord}
            />
          }
        />

        {/* Staff List Modal */}
        <Modal
          size="xl"
          className="modal-dialog-centered"
          isOpen={isStaffListModalOpen}
          toggle={closeStaffListModal}
          style={{ height: '100%', maxWidth: '100%' }}
        >
          <div className="modal-header">
            <h6 className=" modal-title" id="modal-title-default">
              Edit Staff Details
            </h6>
          </div>
          <div className="modal-body p-2">
            {
              selectedData?.Service?.id === 55 ?
                <ThirdPartyAppActionStepStaffTable
                  warningAlert={warningAlert}
                  dbDetails={staffListModal.db}
                  openStaffDetailsModal={openStaffDetailsModal}
                  selectedData={selectedData}
                  openStaffList={openStaffList}
                  setwithEditedDataHandler={setwithEditedDataHandler}
                  userDetails={userDetails}
                  selectedClient={selectedClient}
                  firmDetails={firmDetails}
                  setShowBlockUI={setShowBlockUI}
                  closeStaffListModal={closeStaffListModal}
                  handleUpdateRecord={handleUpdateRecord}
                />
                :
                <ThirdPartyAppStaffTable
                  warningAlert={warningAlert}
                  dbDetails={staffListModal.db}
                  openStaffDetailsModal={openStaffDetailsModal}
                  selectedData={selectedData}
                  openStaffList={openStaffList}
                  setwithEditedDataHandler={setwithEditedDataHandler}
                  userDetails={userDetails}
                  selectedClient={selectedClient}
                  firmDetails={firmDetails}
                  setShowBlockUI={setShowBlockUI}
                  closeStaffListModal={closeStaffListModal}
                  handleUpdateRecord={handleUpdateRecord}
                />
            }
          </div>
          <div className="modal-footer pt-0">
            <Button color="dark" onClick={closeStaffListModal}>
              Close
            </Button>
          </div>
        </Modal>
        {/* Upload CSV Modal */}

        {
          isWipModalOpen ?
            <CsvWIPUploadModal
              wipDb={wipDb}
              isOpen={isWipModalOpen}
              onClose={closeCSVModal}
              selectedClient={selectedClient}
              userDetails={userDetails}
              accountingFirm={firmDetails}
              firmDetails={firmDetails}
              setShowBlockUI={setShowBlockUI}
              handleUpdateRecord={handleUpdateRecord}
            />
            : null
        }

        <WmbCSVUploadModal
          wipDb={wmbWipDb}
          isOpen={isWmbWipModalOpen}
          onClose={closeWmbCSVModal}
          selectedClient={selectedClient}
          userDetails={userDetails}
          accountingFirm={firmDetails}
          firmDetails={firmDetails}
          setShowBlockUI={setShowBlockUI}
          handleUpdateRecord={handleUpdateRecord}
        />

        <WmbCSVJobCostModal
          jobCostDb={wmbJobCostDb}
          isOpen={isWmbJobCostModalOpen}
          onClose={closeWmbJobCostCSVModal}
          selectedClient={selectedClient}
          userDetails={userDetails}
          accountingFirm={firmDetails}
          firmDetails={firmDetails}
          setShowBlockUI={setShowBlockUI}
          handleUpdateRecord={handleUpdateRecord}
        />

        <WmbCSVJobTaskModal
          jobTaskDb={wmbJobTaskDb}
          isOpen={isWMBJobTaskModalOpen}
          onClose={closeWmbJobTaskCSVModal}
          selectedClient={selectedClient}
          userDetails={userDetails}
          accountingFirm={firmDetails}
          firmDetails={firmDetails}
          setShowBlockUI={setShowBlockUI}
          handleUpdateRecord={handleUpdateRecord}
        />

        <WmbCSVIncomeTaxModal
          incomeTaxDb={wmbIncomeTaxDb}
          isOpen={isWmbIncomeTaxModalOpen}
          onClose={closeWmbIncomeTaxCSVModal}
          selectedClient={selectedClient}
          userDetails={userDetails}
          accountingFirm={firmDetails}
          firmDetails={firmDetails}
          setShowBlockUI={setShowBlockUI}
          handleUpdateRecord={handleUpdateRecord}
        />

        <CSVJobCostModal
          jobCostDb={jobCostDb}
          isOpen={isJobCostModalOpen}
          onClose={closeJobCostCSVModal}
          selectedClient={selectedClient}
          userDetails={userDetails}
          accountingFirm={firmDetails}
          firmDetails={firmDetails}
          setShowBlockUI={setShowBlockUI}
          handleUpdateRecord={handleUpdateRecord}
        />

        <CSVJobTaskModal
          jobTaskDb={jobTaskDb}
          isOpen={isJobTaskModalOpen}
          onClose={closeJobTaskCSVModal}
          selectedClient={selectedClient}
          userDetails={userDetails}
          accountingFirm={firmDetails}
          firmDetails={firmDetails}
          setShowBlockUI={setShowBlockUI}
          handleUpdateRecord={handleUpdateRecord}
        />

        <CSVIncomeTaxModal
          incomeTaxDb={incomeTaxDb}
          isOpen={isIncomeTaxModalOpen}
          onClose={closeIncomeTaxCSVModal}
          selectedClient={selectedClient}
          userDetails={userDetails}
          accountingFirm={firmDetails}
          firmDetails={firmDetails}
          setShowBlockUI={setShowBlockUI}
          handleUpdateRecord={handleUpdateRecord}
        />

        {/* StaffDetails Modal */}
        <Modal
          size="xl"
          className="modal-dialog-centered"
          isOpen={isStaffDetailsModalOpen}
          toggle={closeStaffDetailsModal}
        >
          <div className="modal-header">
            <h6 className=" modal-title" id="modal-title-default">
              Edit Staff Details
            </h6>
          </div>
          <Form onSubmit={handleStaffDetailsUpdate}>
            <div className="modal-body p-2">
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`StaffID${staffDetailsModal.record.id}`}
                    name="StaffID"
                    type="text"
                    label="Staff ID"
                    placeholder="Enter Staff ID"
                    //onChange={props.handleStaffDetailsInputChange}
                    ////required={true}
                    readOnly
                    defaultValue={staffDetailsModal.record["StaffID"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`StaffName${staffDetailsModal.record.id}`}
                    name="StaffName"
                    type="text"
                    label="Staff Name"
                    placeholder="Enter Staff Name"
                    //onChange={props.handleStaffDetailsInputChange}
                    ////required={true}
                    readOnly
                    defaultValue={staffDetailsModal.record["StaffName"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`Mon${staffDetailsModal.record.id}`}
                    name="Mon"
                    type="text"
                    label="Monday"
                    placeholder="0:00"
                    onChange={staffDetailsFormatTime}
                    onBlur={staffDetailsFormatTimeOnBlur}
                    //required={true}
                    readOnly={staffDetailsModal.type === "open"}
                    defaultValue={staffDetailsModal.record["Mon"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`Tue${staffDetailsModal.record.id}`}
                    name="Tue"
                    type="text"
                    label="Tuesday"
                    placeholder="0:00"
                    onChange={staffDetailsFormatTime}
                    onBlur={staffDetailsFormatTimeOnBlur}
                    //required={true}
                    readOnly={staffDetailsModal.type === "open"}
                    defaultValue={staffDetailsModal.record["Tue"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`Wed${staffDetailsModal.record.id}`}
                    name="Wed"
                    type="text"
                    label="Wednesday"
                    placeholder="0:00"
                    onChange={staffDetailsFormatTime}
                    onBlur={staffDetailsFormatTimeOnBlur}
                    //required={true}
                    readOnly={staffDetailsModal.type === "open"}
                    defaultValue={staffDetailsModal.record["Wed"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`Thu${staffDetailsModal.record.id}`}
                    name="Thu"
                    type="text"
                    label="Thursday"
                    placeholder="0:00"
                    onChange={staffDetailsFormatTime}
                    onBlur={staffDetailsFormatTimeOnBlur}
                    //required={true}
                    readOnly={staffDetailsModal.type === "open"}
                    defaultValue={staffDetailsModal.record["Thu"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`Fri${staffDetailsModal.record.id}`}
                    name="Fri"
                    type="text"
                    label="Friday"
                    placeholder="0:00"
                    onChange={staffDetailsFormatTime}
                    onBlur={staffDetailsFormatTimeOnBlur}
                    //required={true}
                    readOnly={staffDetailsModal.type === "open"}
                    defaultValue={staffDetailsModal.record["Fri"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`Total${staffDetailsModal.record.id}`}
                    name="Total"
                    type="text"
                    label="Total"
                    placeholder="0:00"
                    onChange={staffDetailsFormatTime}
                    onBlur={staffDetailsFormatTimeOnBlur}
                    //required={true}
                    readOnly
                    defaultValue={staffDetailsModal.record["Total"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`Percent${staffDetailsModal.record.id}`}
                    name="Percent"
                    type="text"
                    label="Productivity %"
                    placeholder="Enter Productivity %"
                    onChange={staffDetailsFormatWholeNumber}
                    //required={true}
                    readOnly={staffDetailsModal.type === "open"}
                    defaultValue={staffDetailsModal.record["Percent"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`Team${staffDetailsModal.record.id}`}
                    name="Team"
                    type="text"
                    label="Team"
                    placeholder="Enter Team"
                    onChange={handleStaffDetailsInputChange}
                    ////required={true}
                    readOnly={staffDetailsModal.type === "open"}
                    defaultValue={staffDetailsModal.record["Team"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`BillRate${staffDetailsModal.record.id}`}
                    name="BillRate"
                    type="text"
                    label="Bill Rate"
                    placeholder="Enter Bill Rate"
                    onChange={staffDetailsFormatWholeNumber}
                    //required={true}
                    readOnly={staffDetailsModal.type === "open"}
                    defaultValue={staffDetailsModal.record["BillRate"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`BillableRate${staffDetailsModal.record.id}`}
                    name="BillableRate"
                    type="text"
                    label="Billable Rate"
                    placeholder="Enter Billable Rate"
                    onChange={staffDetailsFormatWholeNumber}
                    //required={true}
                    readOnly={staffDetailsModal.type === "open"}
                    defaultValue={staffDetailsModal.record["BillableRate"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`FulltimeEquivPercent${staffDetailsModal.record.id}`}
                    name="FulltimeEquivPercent"
                    type="text"
                    label="Fulltime Equiv %"
                    placeholder="Enter Fulltime Equiv %"
                    onChange={staffDetailsFormatWholeNumber}
                    //required={true}
                    readOnly={staffDetailsModal.type === "open"}
                    defaultValue={staffDetailsModal.record["FulltimeEquivPercent"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldGroup
                    id={`Notes${staffDetailsModal.record.id}`}
                    name="Notes"
                    type="text"
                    label="Annual Budget"
                    placeholder="Enter Annual Budget"
                    onChange={handleStaffDetailsInputChange}
                    //required={true}
                    readOnly={staffDetailsModal.type === "open"}
                    defaultValue={staffDetailsModal.record["Notes"] || ""}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomToggleField
                    onChange={
                      //   (defaultValue) => {
                      //   //console.log(defaultValue);
                      //   staffDetailsModal.record["Working"] = !defaultValue;
                      //   setStaffDetailsModal(staffDetailsModal);
                      // }
                      handleStaffDetailsInputChange
                    }
                    label="Working?"
                    type="checkbox"
                    id={`Working${staffDetailsModal.record.id}`}
                    name="Working"
                    defaultValue={staffDetailsModal.record["Working"] || false}
                    defaultChecked={staffDetailsModal.record["Working"] || false}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomToggleField
                    onChange={
                      //   (defaultValue) => {
                      //   //console.log(defaultValue);
                      //   staffDetailsModal.record["DirectorPartner"] = !defaultValue;

                      //   setStaffDetailsModal(staffDetailsModal);
                      // }
                      handleStaffDetailsInputChange
                    }
                    label="Director/Partner?"
                    type="checkbox"
                    id={`DirectorPartner${staffDetailsModal.record.id}`}
                    name="DirectorPartner"
                    defaultValue={staffDetailsModal.record["DirectorPartner"] || false}
                    defaultChecked={staffDetailsModal.record["DirectorPartner"] || false}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomToggleField
                    onChange={
                      //   (defaultValue) => {
                      //   //console.log(defaultValue);
                      //   staffDetailsModal.record["FeeEarner"] = !defaultValue;

                      //   setStaffDetailsModal(staffDetailsModal);
                      // }
                      handleStaffDetailsInputChange
                    }
                    label="Fee Earner?"
                    type="checkbox"
                    id={`FeeEarner${staffDetailsModal.record.id}`}
                    name="FeeEarner"
                    defaultValue={staffDetailsModal.record["FeeEarner"] || false}
                    defaultChecked={staffDetailsModal.record["FeeEarner"] || false}
                  />
                </Col>
              </Row>
            </div>
            <div className="modal-footer pt-0">
              <Button type="submit">Save</Button>
              <Button color="dark" onClick={closeStaffDetailsModal}>
                Close
              </Button>
            </div>
          </Form>
        </Modal>
      </Container>
    </>
  );
};

export default ThirdPartyApplications;
