import { MainContext } from 'context/mainContext'
import GraphView from 'eva-component/response/GraphView'
import GridView from 'eva-component/response/GridView'
import TreeView from 'eva-component/response/TreeView'
import React, { useContext, useEffect } from 'react'
import { Col, FormGroup, Row } from 'reactstrap'

function DashboardTempaltemGridView({ item, gridHeader, sqlresult, treeViewData, isWithChild, isShowGrid, toggleAll , isShowGrap}) 
{
    const { bgColor, textColor, userDetails, firmDetails, selectedClientID } = useContext(MainContext);
    const theme = { PrimaryColor: bgColor, TextColor: textColor }
    useEffect(() => {

    },[])
    return (
        <>
            <div className='EVAResponseContainer' style={{ display: 'unset', padding: 0 }}>
                <div className='EVADataContent' style={{ overflow: 'auto' }}>
                    {isShowGrap ? <GraphView
                        sqlResult={sqlresult}
                        gridHeader={gridHeader}
                        theme={theme} 
                        treeViewData={treeViewData}
                        isWithChild={isWithChild}
                        isShowGrid={isShowGrid}
                    /> : 
                    // isWithChild && !isShowGrid ? 
                        <TreeView
                            item={item}
                            theme={theme} 
                            sqlresult={sqlresult} 
                            gridHeader={gridHeader}
                            treeViewData={treeViewData}
                            toggleAll={toggleAll}
                        /> 
                        // :
                        // <GridView
                        //     item={item}
                        //     theme={theme} 
                        //     sqlresult={sqlresult} 
                        //     EVAData={sqlresult}
                        //     gridHeader={gridHeader}
                        // />
                    }
                </div>
            </div>
        </>
    )
}

export default DashboardTempaltemGridView