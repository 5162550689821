import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import { Bar, Line, Pie } from 'react-chartjs-2';
import { getRandomLightColor } from 'services/EVAService';
import { defaultControl } from 'services/EVAVisualService';

function DashboardTemplateltemPieGraph({ sqlresult, item, treeViewData, templateItemVisual, selectedViewOption }) 
{
    const [selectedGraphHeaderOption, setSelectedGraphHeaderOption] = useState(templateItemVisual.selectedGraphHeaderOption)
    const [selectedDataList, setSelectedDataList] = useState([]);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [gridGroupLevel, setGridGroupLevel] = useState(null)
    const [oldPieItem, setOldPieItem] = useState(null)

    const {
        bgColor,
        textColor
    } = useContext(MainContext);
    const { 
        selectedGroup,
        selectedGroups,
        chartHeader,
        selectedLabel,
        selectedData,
        pieItem,
        selectedFooter,
        headerToMultiSelect
    } = templateItemVisual

    const [chartDataSet, setChartDataSet] = useState(null);
    let chartData = {
        labels: [],
        datasets: [],
    }

    useEffect(() => {
        if(selectedLabel && selectedData)
        {
            const label = getPieChartLabel(selectedLabel.id)
            const pieItem = getPieChartLabelGroup(label)
            const data = gridGroupLevel ? getPieChartData(selectedData.id, label) : getPieChartData(selectedData.id, pieItem)
            // console.log(pieItem)
            setOldPieItem(pieItem)
            setSelectedDataList(data)
        }
        // const footer = getChartFooter(selectedFooter.id)
        // chartData.labels = footer
        // getChartData(selectedGroups)
        // console.log(templateItemVisual)
        

    },[selectedGraphHeaderOption, gridGroupLevel])

    useEffect(() => {
        if(item && item.editedGridCellData) setGridGroupLevel(item.editedGridCellData.gridGroupLevel)
    },[item])

    useEffect(() => {
        const handleResize = () => {
          setScreenHeight(window.innerHeight);
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    const getPieChartLabelGroup = (label) => 
    {
        const group = [];
        label.forEach((element, index) => {
            group.push({ 
                data: element, 
                color: pieItem && pieItem[index] && pieItem[index].color ? pieItem[index].color : defaultControl(index), 
                isOpen: false
            });
        });
        return group
    };
    const getPieChartLabel = (id) => {
        const label = []
        const chartLabel = []
        if(gridGroupLevel) {
            let currentIndentLevel = 0;
            // console.log(selectedGraphHeaderOption)
            if(selectedGraphHeaderOption)
            { 
                loopThruItemsWithFilter(id, treeViewData)
            } else {
                loopThruItems(id, treeViewData);
            }

            function loopThruItems(indentLevel, treeViewData) {
                const rowsToSum = [];
                
                for (let i = 0; i < treeViewData.length; i++) {
                    const row = treeViewData[i];

                    if (currentIndentLevel === id) {
                        if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                            const param = {id: row.sqlId, name: row.name}
                            chartLabel.push(row.name)
                            label.push(param)
                        }
                    }

                    if (row.children) {
                        currentIndentLevel++;
                        const childRowsToSum = loopThruItems(indentLevel, row.children);
                        rowsToSum.push(...childRowsToSum); // Accumulate results from child nodes
                        currentIndentLevel--;
                    }
                }
                return rowsToSum;
            }

            function loopThruItemsWithFilter(indentLevel, treeViewData) {
                for (let i = 0; i < treeViewData.length; i++) {
                    const row = treeViewData[i];
                    if(row.name === selectedGraphHeaderOption)
                    {
                        // console.log(row)
                        if(row.children) {
                            currentIndentLevel++;
                            loopThruParentChild(indentLevel, row.children)
                            currentIndentLevel--;
                        }
                    

                        function loopThruParentChild (indentLevel, parent)
                        {
                            for (let i = 0; i < parent.length; i++) { 
                                const row = parent[i];
                                if (currentIndentLevel === id) {
                                    if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                                        const param = {id: row.sqlId, name: row.name}
                                        chartLabel.push(row.name)
                                        label.push(param)
                                    }
                                }

                                if (row.children) {
                                    currentIndentLevel++;
                                    loopThruParentChild(indentLevel, row.children);
                                    currentIndentLevel--;
                                }
                            }
                        }
                    }

                    if (row.children) {
                        currentIndentLevel++;
                        loopThruItemsWithFilter(indentLevel, row.children);
                        currentIndentLevel--;
                    }
                }
            }
            chartData.labels = chartLabel
            return label
        } else {
            // console.log(sqlresult)
            // console.log(selectedGraphHeaderOption)
            sqlresult.forEach(element => {
                if(selectedGraphHeaderOption)
                {
                    if(!label.includes(element[id]) && element[chartHeader.fieldIndex] === selectedGraphHeaderOption)
                    chartLabel.push(element[id])
                    label.push(element[id])
                } else {
                    if(!label.includes(element[id]))
                    chartLabel.push(element[id])
                    label.push(element[id])
                }
            });

            // console.log(chartLabel)
            chartData.labels = chartLabel
            return label
        }
    }
    useEffect(() => {
    },[chartDataSet])

const getPieChartData = (id, pieitem) => {
        const data = []
        const color = []

        if(selectedGraphHeaderOption || selectedGroup)
        {
            pieitem && pieitem.length > 0 && pieitem.forEach((item, index) => 
            {
                let totalAmount = 0
                if(gridGroupLevel) {
                   
                    loopThruItems(treeViewData)
                    function loopThruItems(treeViewData) {
                        for (let i = 0; i < treeViewData.length; i++) {
                            const row = treeViewData[i];
                            if(row.sqlId === item.data.id) {
                                if (!row.id.includes("-TotalRow") && 
                                    row.id !== "grand-total" && 
                                    !row.id.includes("-grandParent-")) 
                                {
                                    if(row.value)
                                    {
                                        totalAmount += row.value[id - gridGroupLevel]
                                    }

                                    if (row.children) {
                                        loopThruParentChild(row.children);
                                    }
                                }
                            }
                            if (row.children) {
                                loopThruItems(row.children);
                            }
                        }
                    }

                    function loopThruParentChild(parent) {
                        for (let i = 0; i < parent.length; i++) {
                            const row = parent[i];
                            if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                                if(row.value)
                                {
                                    totalAmount += row.value[id - gridGroupLevel]
                                }

                                if (row.children) {
                                    loopThruParentChild(row.children);
                                }
                            }
                        }
                    }
                    

                    data.push(totalAmount)
                    color.push(pieItem && pieItem[index] && pieItem[index].color ? pieItem[index].color : defaultControl(index));
                } else {
                    sqlresult.forEach((element, index) => {
                        if(item.data === element[selectedLabel.id])
                        {
                            if (typeof element[id] === 'string') 
                                if (element[id].includes('%')) 
                                    if(!isNaN(parseFloat(element[id].replace('%', '')).toFixed(2)))
                                        element[id] = parseFloat(element[id].replace('%', '')).toFixed(2)
                            totalAmount+= element[id]
                        }
                    });
                    data.push(totalAmount)
                    color.push(pieitem && pieitem[index] && pieitem[index].color ? pieitem[index].color : defaultControl(index));
                }
            })

            chartData.datasets = [{
                label: '',
                data: data,
                backgroundColor: color,
                hoverOffset: 4,
            }]
        } else {
            if(gridGroupLevel) {
                pieitem && pieitem.length > 0 && pieItem.forEach((item, index) => 
                {
                    let totalAmount = 0
                    loopThruItems(treeViewData)
                    function loopThruItems(treeViewData) {
                        for (let i = 0; i < treeViewData.length; i++) {
                            const row = treeViewData[i];
                            if(row.sqlId === item.data.id) {
                                if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                                    if(row.value)
                                    {
                                        totalAmount += row.value[id - gridGroupLevel]
                                    }

                                    if (row.children) {
                                        loopThruParentChild(row.children);
                                    }
                                }
                            }
                            if (row.children) {
                                loopThruItems(row.children);
                            }
                        }
                    }

                    function loopThruParentChild(parent) {
                        for (let i = 0; i < parent.length; i++) {
                            const row = parent[i];
                            if (!row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                                if(row.value)
                                {
                                    totalAmount += row.value[id - gridGroupLevel]
                                }

                                if (row.children) {
                                    loopThruParentChild(row.children);
                                }
                            }
                        }
                    }
                    

                    data.push(totalAmount)
                    color.push(pieItem && pieItem[index] && pieItem[index].color ? pieItem[index].color : defaultControl(index));
                })
            } else {
                pieitem && pieitem.length > 0 && pieitem.forEach((item, index) => 
                {
                    let totalAmount = 0
                    sqlresult.forEach((element, index) => {
                        if(item.data === element[selectedLabel.id])
                        {
                            if (!data.includes(element[id])) {
                                if (typeof element[id] === 'string') 
                                            if (element[id].includes('%')) 
                                                if(!isNaN(parseFloat(element[id].replace('%', '')).toFixed(2)))
                                                    element[id] = parseFloat(element[id].replace('%', '')).toFixed(2)
    
                                totalAmount+= element[id]
                            }
                        }
                    });
                    data.push(totalAmount)
                    color.push(pieItem && pieItem[index] && pieItem[index].color ? pieItem[index].color : defaultControl(index));
                })
            }
           

            chartData.datasets = [{
                label: '',
                data: data,
                backgroundColor: color,
                hoverOffset: 4,
            }]
        }
        // console.log(chartData)
        setChartDataSet(chartData)
        return data
    }

    const handleSelectGraphHeader = (item) => {
        setSelectedGraphHeaderOption(item)
    }

    return (
        <>
            <div className='templateVisualGraphHeaderContent'>
                {chartDataSet &&
                    <>
                        <div className='templateVisualGraphHeaderContainer'>
                            {chartHeader && chartHeader.header && chartHeader.header.map((item) => (
                            <div 
                                className='templateVisualGraphHeaderItem' 
                                style={selectedGraphHeaderOption === item ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                                onClick={() => handleSelectGraphHeader(item)}
                            >{item}</div>
                            ))}
                        </div>
                        <div style={{ maxHeight: screenHeight  - 300 }}>
                            <Pie
                                data={chartDataSet}
                            />
                        </div>
                    
                    </>
                }
            </div>
        </>   
    )
}

export default DashboardTemplateltemPieGraph