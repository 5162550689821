import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Table,
} from "reactstrap";
import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";
import ReactPaginate from "react-paginate";
import "./paginatestyles.css";
import { CustomToggleField } from "customComponents/Form";
import avatar from "../../assets/img/blank-avatar.png";
import moment from "moment";
var momentTZ = require("moment-timezone");
import { businessTZ } from "utils/utilityFunctions";

const IntegrationsTable = ({
  data,
  warningAlert,
  handleEditClient,
  userDetails,
  handleShowEnablePCEModal,
  ...props
}) => {
  const { setSelectedClientID, selectedClientID, setSelectedClient, contextClientLists } = useContext(MainContext);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [numberPerPage, setNumberPerPage] = useState(5);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    // console.log(data)
    setPaginatedData(data);
    // Get clientListNumberPerPage
    localStorage.getItem("clientListNumberPerPage") !== null
      ? setNumberPerPage(localStorage.getItem("clientListNumberPerPage"))
      : setNumberPerPage(5);
  }, [currentPage, paginatedData, data]);

  const userFullName = (user) => {
    let clientUserAdmin = [];
    user.map((usr) => {
      if (usr.UserAccess === 5) {
        clientUserAdmin.push(usr);
      }
      return null;
    });
    if (clientUserAdmin.length) {
      return `${clientUserAdmin[0].FirstName} ${clientUserAdmin[0].LastName}`;
    }
    return "N/A";
  };

  const userEmail = (user) => {
    let clientUserAdmin = [];
    user.map((usr) => {
      if (usr.UserAccess === 5) {
        clientUserAdmin.push(usr);
      }
      return null;
    });
    if (clientUserAdmin.length) {
      return `${clientUserAdmin[0].Email}`;
    }
    return "N/A";
  };

  const onMouseEnter2 = () => {
    setDropdownOpen2(true);
  };

  const onMouseLeave2 = () => {
    setDropdownOpen2(false);
  };

  const toggle2 = () => {
    setDropdownOpen2((prevState) => ({
      dropdownOpen2: !prevState.dropdownOpen2,
    }));
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const handleChangeNumPerPage = (num) => {
    setNumberPerPage(num);
    setCurrentPage(0);
    // Save clientListNumberPerPage
    localStorage.setItem("clientListNumberPerPage", num);
  };

  const offset = currentPage * numberPerPage;

  const tableRowClick = (val) => {
    history.push(`/client/thirdpartyapplication/${val.ClientID}`);
  };

  // const businessTZ = (date, isLastRefresh) => {
  //   if(props.accountingFirm.CountryCode === "nz") return momentTZ.tz(date, "Pacific/Auckland").format(`${isLastRefresh ? "DD-MM-YYYY hh:mm" : "DD-MM-YYYY"}`)
  //   if(props.accountingFirm.CountryCode === "us") return momentTZ.tz(date, "America/New_York").format(`${isLastRefresh ? "DD-MM-YYYY hh:mm" : "DD-MM-YYYY"}`)
  //   if(props.accountingFirm.CountryCode === "uk") return momentTZ.tz(date, "Europe/London").format(`${isLastRefresh ? "DD-MM-YYYY hh:mm" : "DD-MM-YYYY"}`)
  //   if(props.accountingFirm.CountryCode === "au") return momentTZ.tz(date, "Australia/Adelaide").format(`${isLastRefresh ? "DD-MM-YYYY hh:mm" : "DD-MM-YYYY"}`)
  // }

  const currentPageData = () => {
    return paginatedData
      .slice(offset, Number(offset) + Number(numberPerPage))
      .map((int, i) => {
        return (
          <tr key={i} style={{ cursor: "pointer" }} className="all-integrations-row"
          // onClick={() => changeUrl("thirdpartyapplication", int.Client.ClientID)}
          >
            <td className="">
              <Media className="align-items-center">
                <img
                  className="rounded mr-3" //Edited by Joef: removed avatar
                  alt="..."
                  src={
                    int.Service.Logo
                      ? int.Service.Logo
                      : avatar
                  }
                  style={{
                    //Added by Joef: instead of avatar to retain dimension
                    width: "60px",
                  }}
                />
              </Media>
            </td>
            <td className="">
              {int.Service.Name}
            </td>
            <td className="">
              {
                int.Details.Organisation ||
                int.Details.Name ||
                int.Details.name ||
                (int.Status === 9 ? "Provisioning" : "Dataset Active")
              }
            </td>
            <td className="">
              {int.Client.Name}
            </td>
            <td className="">
            <Media className="align-items-center">
                <img
                  className="rounded mr-3" //Edited by Joef: removed avatar
                  alt="..."
                  src={
                    int.Client.Logo
                      ? int.Client.Logo
                      : avatar
                  }
                  style={{
                    //Added by Joef: instead of avatar to retain dimension
                    width: "60px",
                  }}
                />
              </Media>
            </td>
            <td className="">
              {
                props.accountingFirm.TimeZone
                  ? momentTZ
                    .tz(int.createdAt, props.accountingFirm.TimeZone)
                    .format("DD-MM-YYYY")
                  : businessTZ(props.accountingFirm.CountryCode, int.createdAt)
              }
            </td>
            <td className="">
              {
                int.LastRefreshDate
                  ? props.accountingFirm.TimeZone
                    ? momentTZ
                      .tz(int.LastRefreshDate, props.accountingFirm.TimeZone)
                      .format("DD-MM-YYYY hh:mm")
                    : businessTZ(props.accountingFirm.CountryCode, data.LastRefreshDate, true)
                  : "No record found"
              }
            </td>
            <td className="">
              {showStatus(int.Status, int.Service.id, int.id, i)}
            </td>
          </tr>
        );
      });
  };

  const pageCount = () => {
    return Math.ceil(data.length / numberPerPage);
  };

  const changeUrl = (page, clientID) => {
    // console.log(clientID)
    sessionStorage.setItem("selectedClientID", clientID);
    let newSelectedClient = contextClientLists.filter(c => c.ClientID === clientID)
    // console.log('newSelectedClient', newSelectedClient)
    setSelectedClient(newSelectedClient[0])
    if (page === "thirdpartyapplication") {
      setSelectedClientID(clientID);
      return history.push(`/client/thirdpartyapplication/${clientID}`);
    }
  };

  const showStatus = (status, service, clientService, i) => {
    if (status === 1) {
      if (service === 3)
        return (
          // <Button
          //   color="success"
          //   size="sm"
          //   className="btn-rounded"
          //   style={{ borderRadius: "50px" }}
          // >
          "Workspace Active"
          // </Button>
        );
      else if (service === 1 || service === 24)
        return (
          // <Button
          //   color="success"
          //   size="sm"
          //   className="btn-rounded"
          //   onClick={() => refreshNow(clientService)}
          //   style={{ borderRadius: "50px" }}
          // >
          "Refresh Now"
          // </Button>
        );
      else
        return (
          // <Button
          //   color="success"
          //   size="sm"
          //   className="btn-rounded"
          //   style={{ borderRadius: "50px" }}
          // >
          "Dataset Active"
          // </Button>
        );
    } else if (status === 9) {
      return (
        // <Button
        //   color="warning"
        //   size="sm"
        //   className="btn-rounded"
        //   style={{ borderRadius: "50px" }}
        // >
        "Data Loading"
        // </Button>
      );
    } else if (status === 12) {
      return (
        // <Button
        //   color="warning"
        //   size="sm"
        //   className="btn-rounded"
        //   style={{ borderRadius: "50px" }}
        // >
        "Updating Data"
        // </Button>
      );
    } else if (status === 14) {
      return (
        // <Button
        //   color="warning"
        //   size="sm"
        //   className="btn-rounded"
        //   style={{ borderRadius: "50px" }}
        // >
        "Refreshing Dataflow"
        // </Button>
      );
    } else if (status === 15) {
      return (
        // <Button
        //   color="warning"
        //   size="sm"
        //   className="btn-rounded"
        //   style={{ borderRadius: "50px" }}
        // >
        "Refreshing Dataset"
        // </Button>
      );
    } else if (status === 13) {
      return (
        // <>
        //   <Button
        //     id={`btn${i}`}
        //     color="danger"
        //     size="sm"
        //     className="btn-rounded"
        //     onClick={() => handleReconnect(service, clientService)}
        //   >
        "Disconnected"
        //   </Button>
        //   <UncontrolledTooltip delay={0} placement="top" target={`btn${i}`}>
        //     Disconnected - Please click to reconnect
        //   </UncontrolledTooltip>
        // </>
      );
    }
  };

  return (
    <>
      <Card>
        <CardHeader className="border-0">
          {/* <h3 className="mb-0">Light table</h3> */}
        </CardHeader>
        {paginatedData && paginatedData.length >= 1 ? (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th className="">
                  Integration Logo
                </th>
                <th className="">
                  Integration Type
                </th>
                <th className="">
                  Name
                </th>
                <th className="">
                  Client
                </th>
                <th className="">
                  Client Logo
                </th>
                <th className="">
                  Created At
                </th>
                <th className="">
                  Last Refresh
                </th>
                <th className="">
                  Status
                </th>
                {/* <th className="">
                  Admin Email
                </th>
                <th scope="col">Actions</th> */}
              </tr>
            </thead>
            <tbody className="list">
              {currentPageData()}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        ) : null}
        <CardFooter className="py-4">
          {data && data.length >= 1 ? null : (
            <p className="text-center mt-3">No Integrations found.</p>
          )}
          <Row>
            <Col>
              <UncontrolledDropdown direction="down" size="sm">
                <DropdownToggle
                  caret
                // style={{background: '#5E72E4', color: '#fff', borderColor: '#5E72E4'}}
                >
                  {`${numberPerPage}`}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleChangeNumPerPage(5)}>
                    {`5 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(15)}>
                    {`15 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(30)}>
                    {`30 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(50)}>
                    {`50 `}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col>
              <ReactPaginate
                previousLabel={<i className="fas fa-angle-left" />}
                nextLabel={<i className="fas fa-angle-right" />}
                pageCount={pageCount()}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default IntegrationsTable;
