import { CustomButton } from 'components/Button/CustomButton'
import CustomSpinner from 'components/Misc/CustomSpinner'
import { MainContext } from 'context/mainContext';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, CustomInput, Form, FormGroup, Input, Label, Modal, Row, Table } from 'reactstrap'
import { evaThemeLighter } from 'services/EVAResponseService';
import { registerDashboardTemplate } from 'services/mongoDB';
import { dashboardTemplate } from 'services/mongoDB';
import ReactBSAlert from "react-bootstrap-sweetalert";
import DashboardTemplateItem from './DashboardTemplateItem';
import DashboardTemplateItemModal from './DashboardTemplateItemModal';
import PanelCard from 'components/Cards/PanelCard';
import { useHistory } from "react-router";
import DashboardTemplateKPI from './DashboardTemplateKPI';
import { submitEvaQueryHandler } from 'services/DashboardService';
import { forEach, wrap } from 'lodash';
import { generateUUID } from 'services/EVAService';
import ManageDashboardDnD from '../manageDashboard/ManageDashboardDnD';
import EVAVisualEditDashboard from '../manageDashboard/EVAVisualEditDashboard';
import { userDashboardTemplate } from 'services/mongoDB';
import { userDashboardTemplateByUser } from 'services/mongoDB';
import { getCustomGroups } from 'services/mongoDB';
import Select from 'react-select';
import { registerGroupNotification } from 'services/mongoDB';
import SendMultiDeviceNotification from 'services/NotificationService';
import SendExpoNotifications from 'services/ExpoNotificationService';
import { mongoFetchToken } from 'services/mongoDB';
import { etaniDashboardTemplates } from 'services/mongoDB';
// import socket from 'services/MainSocket';
// import socket from 'chat-components/utils/socket';
import XBImage from '../../../assets/img/XB.png';
import XPMImage from '../../../assets/img/XPM.png';
import WFMImage from '../../../assets/img/WFM2.png'

function DashboardTemplate({ ...props }) 
{
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [isLoading, setIsLoading] = useState(true);
    const [alert, setAlert] = useState(false);
    // const [templateList, setTemplateList] = useState(null)
    const [selectedTemplateItem, setSelectedTemplateItem] = useState([]);
    const [newDashboardName, setNewDashboardName] = useState(null);
    const [tabKey, setKey] = useState(null);
    const [dashboardType, setDashboardType] = useState('New');
    const [KPITabKey, setKPITabKey] = useState(null);
    const [templates, setTemplates] = useState([])
    const [integration, setIntegration] = useState([])
    const [categories, setCategories] = useState([])
    const [sub_category, setSubCategories] = useState([])
    const [sqlExampleResult, setSQLExampleResult] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [onEditVisual, setOnEditVisual] = useState(false)
    const [onEditItem, setOneditItem] = useState(null)
    const [onEditItemIndex, setOneditItemIndex] = useState(null)
    const [newUpdateCount, setNewUpdateCount] = useState(0);
    const [userTemplatesList, setUserTemplateList] = useState([])
    const [selectedExistingDashboard, setSelectedExistingDashboard] = useState(null)
    const [pintoDashboard, setPintoDashboard] = useState('personal');
    const [selectedGroupId, setSelectedGroupId] = useState([]);
    const [groupList, setGroupList] = useState([])
    const [customGroupList, setCustomGroupList] = useState(null)
    const [clientUsers, setClientUsers] = useState([]);
    const [selectGroupUsers, setSelectGroupUsers] = useState(null)
    const [defaultSelectedGroup, setDefaultSelectedGroup] = useState(null)
    const [etaniTempaltes, setEtaniTemplates] = useState([])
    const [selectedEtaniTempaltes, setSelectedEtaniTemplates] = useState(null)
    const [toggleShowSettings, setToggleShowSettings] = useState(true)

    const {
        bgColor,
        textColor,
        selectedClientID,
        userDetails,
        firmDetails,
        getDetails,
        isTemplateEdit,
        selectedTemplatetoEdit, 
        selectedClient,
        selectClientIntegration
      } = useContext(MainContext);

    const theme = { PrimaryColor: bgColor, TextColor: textColor }
    const history = useHistory();
    const isInitialMount = useRef(true)

    useEffect(()=> {
        const loadTemplates = async () => {
            // const template = await dashboardTemplate()
            // console.log(firmDetails)
            try{
                const res = await submitEvaQueryHandler(`SELECT * FROM _eva_sql_templates WHERE template IS NOT null ORDER BY intergration_id`, selectedClient, firmDetails, userDetails);
                
                if(res === undefined || !res) history.push(`/client/customDashboard`)
                const newRes = convertWFMtoXPM(res)

                const integrationNames = selectClientIntegration.map(item => item.integration);
                const filteredDashboards = newRes.filter(dashboard => integrationNames.includes(dashboard.integration));
                // console.log(filteredDashboards);

                getTemplates(filteredDashboards)
                const groupedData = groupBy(filteredDashboards, 'template', 'integration', 'category');
                const transformedData = Object.entries(groupedData).map(([key, value]) => {
                    const [template, integration, category] = key.split('-');
                    return {
                        template,
                        integration,
                        category,
                        // details: value.map(item => ({
                        //     sub_category: item.sub_category,
                        //     display_name: item.display_name,
                        //     query: item.query,
                        //     visual_type: item.visual_type,
                        //     visual_data: parseVisualData(item.visual_data) 
                        // }))
                        details: groupBySubCategory(value)
                    };
                });
              
                // console.log(selectClientIntegration)
                const etaniTemplate = await etaniDashboardTemplates()

                setEtaniTemplates(etaniTemplate)
                setSQLExampleResult(transformedData);
                setIsLoading(false)
            }catch(error)
            {
                console.log(error)
                setIsLoading(false)
            }

            // 
        }
        loadTemplates()
    }, [])
    const convertWFMtoXPM = (res) => {
        // console.log(res);
        
        // Filter out items with integration "WFM"
        const removedWFM = res && res.filter(i => i.integration !== "WFM");
        // console.log(removedWFM);
    
        // Filter items with integration "XPM"
        const XPMList = res && res.filter(i => i.integration === "XPM");
        // console.log(XPMList);
    
        // Convert XPM to WFM and modify the query
        const WFMList = XPMList.map((item) => {
            return {
                ...item,
                integration: "WFM",
                query: item.query.replace(/_xpm/g, "_wfm2"),
            };
        });
        // console.log(WFMList);
    
        // Combine removedWFM and WFMList
        const combinedList = [...removedWFM, ...WFMList];
    
        // Sort by integration_id (assumes integration_id can be compared directly)
        const sortedList = combinedList.sort((a, b) => {
            if (a.intergration_id === null) return 1;
            if (b.intergration_id === null) return -1;
            return a.intergration_id - b.intergration_id;
        });
    
        // console.log(sortedList);
        return sortedList
    }
    
    const getTemplates = (res) => {
        const templates = []
        const integration = []
        const categories = []
        const newData = []
        const sub_category = []
        // console.log(res)
        res !== undefined && res.length > 0 && res.forEach(item => {
            if (!templates.includes(item.template)) templates.push(item.template)
            if (!integration.includes(item.integration)) integration.push(item.integration)
            if (!categories.includes(item.category)) categories.push(item.category)
            if (!sub_category.includes(item.sub_category)) sub_category.push(item.sub_category)
        })

        if(!tabKey) setKey(integration[0])

        setTemplates(templates)
        setIntegration(integration)
        setCategories(categories)
        setSubCategories(sub_category)
    }
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            history.push(`/client/customDashboard`);
        }
    }, [selectedClientID, history]);

    useEffect(() => {
        loadUserTemplate()
        loadClientUser(selectedClient?.id)
    },[selectedClientID, selectedClient])
    useEffect(() => {
        const handleResize = () => {
          setScreenHeight(window.innerHeight);
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    const loadClientUser = async (selectedClientID) => {
        if(!selectedClientID || selectedClientID === undefined) return

        await fetch(`/api/user/getAllRecords/${selectedClientID}`)
        .then((res) => res.json())
        .then((data) => {
            if (data && !data.error) {
                const users = []
                data.map((user) => users.push(user.Email));
                setClientUsers(users);
            } else {
                setIsLoading(false)
                return;
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    const loadUserTemplate = async () => 
    {
        if (Object.keys(userDetails).length === 0) {
            return false;
        }

        const param = {
            clientId: selectedClientID,
            user: userDetails.User.Email
        }
        const res = await userDashboardTemplateByUser(param)
        // console.log(res)

        const publicTemplate = res.filter((item) => item.userAccessSetting?.accessType === 'public'
            && (item.userAccessSetting?.clientOption === "All Client" || item.userAccessSetting?.selectedClient?.some(client => client.ClientID === selectedClientID))
            && item.businessId === firmDetails.AccountingFirmID
        )

        // console.log(publicTemplate)
        setUserTemplateList(publicTemplate)
    }
    const fetchClientGroups = async () => {
        if(pintoDashboard === 'personal') return
        const res = await getCustomGroups({ ClientId: selectedClientID, email: userDetails.User.Email })
        setCustomGroupList(res)

        const users = []
        selectedClient && selectedClient.User && selectedClient.User.map(item => users.push(item.Email))
        const groups = [{
            "_id": selectedClient.ClientID,
            "Users": users,
            "GroupName": selectedClient.Name,
            "ClientId": selectedClient.ClientID
        }]
        const newRes = groups.map(item => { return { value: item._id, label: item.GroupName } }).concat(res.map(item => {return { value: item._id, label: item.GroupName }}))
        setGroupList(newRes);
    };
    const parseVisualData = (visualData) => {
        try {
            const data = JSON.parse(visualData) 
            return data
        }catch(error){
            return []
        }
    }
    const groupBy = (array, ...keys) => {
        return array.reduce((acc, obj) => {
            const key = keys.map(key => obj[key]).join('-');
            (acc[key] = acc[key] || []).push(obj);
            return acc;
        }, {});
    };
    const groupBySubCategory = (array) => {
        array.sort((a, b) => {
            if (a.sub_category < b.sub_category) return -1;
            if (a.sub_category > b.sub_category) return 1;
            return 0;
        });
        const newData = []
        const groupedData = array.reduce((acc, item) => {
            const { sub_category, display_name, query, visual_type, visual_data } = item;
            
            let existingEntry = newData.find(entry => entry.sub_category === sub_category ? sub_category : '');
            if(existingEntry) {
                existingEntry.details.push({
                    display_name: display_name,
                    query: query,
                    visual_type: visual_type,
                    visual_data: parseVisualData(visual_data) 
                })
            } else {
                existingEntry = { sub_category, details: [{
                    display_name: display_name,
                    query: query,
                    visual_type: visual_type,
                    visual_data: parseVisualData(visual_data) 
                }] };
                newData.push(existingEntry);
            }

            // console.log(sub_category)
            // if (!acc[sub_category]) {
            //   acc[sub_category] = [];
            // }
            // acc[sub_category].push(item);

            return acc;
        }, {});
        return newData
    }
    useEffect(() => {
        const loadTemplates = async () => {
           
            if(isTemplateEdit)

            {
                let templatetoEdit = selectedTemplatetoEdit ? selectedTemplatetoEdit : sessionStorage.getItem('selectedTemplatetoEdit')
                setPintoDashboard(selectedTemplatetoEdit.isPersonalDashboard ? 'personal' : 'group')
                // console.log(templatetoEdit)
                // console.log(selectedTemplatetoEdit)
                
                const groupsIds = selectedTemplatetoEdit?.selectedGroupId?.groupsId.map(item => {
                    return groupList.find(group => group.value === item);
                }).filter(group => group !== undefined);
        
                setDefaultSelectedGroup(groupsIds);
                
                console.log(groupsIds);
                // setDefaultSelectedGroup(groupsIds)

                const selectedTemplate = templatetoEdit
                handleSelectGroupChange(groupsIds)
                setNewDashboardName(selectedTemplate.name)
                setSelectedTemplateItem(selectedTemplate.dashboardTemplate)
            }else{
                return
            }
        }

        loadTemplates()
    },[groupList])
    useEffect(() => {
        if(selectedTemplateItem.length === 0) return

        const newItem = { ...selectedTemplateItem };


        if(selectedTemplateItem.length === 1) {
            setKPITabKey(selectedTemplateItem[0].name) 
            return
        }
        if(!KPITabKey) {
            setKPITabKey(selectedTemplateItem[0].name) 
            return
        }

        const index = selectedTemplateItem.findIndex((prevItem) => prevItem.name === KPITabKey.name);
        if (index < 0 && selectedTemplateItem.length > 0) setKPITabKey(selectedTemplateItem[0].name)

    },[selectedTemplateItem])

    const handleNewDashboardNameChange = (event) => {
        setNewDashboardName(event.target.value);
    };
    const handleSelectItem = async (item) => {
        item.uid = await generateUUID();
        setSelectedTemplateItem((prevItems) => {
            const index = prevItems.findIndex((prevItem) => prevItem.name === item.name);
            if (index > -1) {
                const updatedItems = [...prevItems];
                updatedItems.splice(index, 1);
                return updatedItems;
            } else {
                return [...prevItems, item];
            }
        });
    }
    const handleSave = async () => 
    {
        if(selectedTemplateItem.length > 0 && newDashboardName)
        {
            let param = {
                name: newDashboardName,
                clientId: selectedClientID,
                businessId: firmDetails.AccountingFirmID,
                groupId: selectedClientID,
                user: userDetails.User.Email,
                isPersonalDashboard: pintoDashboard === 'personal',
                selectedGroupId: { groupsId: selectedGroupId },
                dashboardTemplate: selectedTemplateItem.map(item => ({
                    ...item,
                    visual: item.tempVisual,
                    editedGridCellData: item.editedGridCellData
                })),
                uid: selectedEtaniTempaltes?.uid ? selectedEtaniTempaltes.uid : ''
            };
            
            param.dashboardTemplate.forEach(item => {
                delete item.tempVisual;
            });
            
            // console.log("selectedEtaniTempaltes: ", selectedEtaniTempaltes)
            console.log("param: ", param)
            if(isTemplateEdit)
            {
                param._id= selectedTemplatetoEdit._id
                registerDashboardTemplate(param)
                if(pintoDashboard !== 'personal')
                    sendNotificationPerGroup(isTemplateEdit, newDashboardName)
                const alertMessage = `You have successfully update ${newDashboardName}`
                handleSuccessUpdate(alertMessage)
            }else{
                registerDashboardTemplate(param)
                const alertMessage = `You have successfully add ${newDashboardName}`
                if(pintoDashboard !== 'personal')
                    sendNotificationPerGroup(isTemplateEdit, newDashboardName)
                handleSuccessUpdate(alertMessage)
            }
        }else{
            if (!selectedTemplateItem && selectedTemplateItem.length === 0) warningAlert("You need to add at least one item!");
            if (!newDashboardName) warningAlert("The dashboard name is invalid!");
        }
    }

    const handleImportTemplate = async () => 
    {
        let param = {
            name: newDashboardName,
            clientId: selectedClientID,
            businessId: firmDetails.AccountingFirmID,
            groupId: selectedClientID,
            user: userDetails.User.Email,
            dashboardTemplate: selectedTemplateItem.map(item => ({
                ...item,
                visual: item.tempVisual
            })),
            importedTemplate: true
        };
        
        param.dashboardTemplate.forEach(item => {
            delete item.tempVisual;
        });

        try{
            registerDashboardTemplate(param)
            const alertMessage = `You have successfully add ${newDashboardName}`
            handleSuccessUpdate(alertMessage)
        } catch(error) {
            const alertMessage = `An error occured while saving the template`
            warningAlert(alertMessage)
        }
    }
     const warningAlert = (message) => {
        setAlert(
        <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Warning"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            btnSize=""
        >
            {message}
        </ReactBSAlert>
        );
    };
    const handleSuccessUpdate = (msg, data) => {
        setAlert(
            <ReactBSAlert
                succes
                title="Success"
                onConfirm={async () => {
                    setAlert(null)
                    history.push(`/client/customDashboard`);
                }}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
            >
                <>
                    <p>
                        {msg}
                    </p>
                </>
            </ReactBSAlert>
        );
    }
    const handleSelectExistingTemplate =  useCallback( (item) => {
        console.log(item)
        setNewDashboardName(item.name)
        setSelectedExistingDashboard(item._id)
        setSelectedTemplateItem([]);
        
        setTimeout(() => {
            setSelectedTemplateItem(item.dashboardTemplate);
        }, 200);

    }, []);
    const handleSelectTemplate =  async(item, selectedTemplate) => {
        setSelectedTemplate(null);
        setSelectedTemplateItem([]);

        setTimeout(async () => {
            if (selectedTemplate === item) {
                setSelectedTemplate(null);
                setSelectedTemplateItem([]);
            } else {
                const newSelectedTemplateItem = [];
                const filterByTemplate = sqlExampleResult.filter((e) => e.template === item);
        
                for (const e of filterByTemplate) {
                    if (e.template === item) {
                        const details = e.details;
                        for (const detail of details) {
                            const uid = await generateUUID();
                            newSelectedTemplateItem.push({ 
                                name: detail.display_name, 
                                query: detail.query, uid, 
                                visual_type: detail.visual_type, 
                                visual_data: detail.visual_data,
                                integration: item.integration
                            });
                        }
                    }
                }
                setSelectedTemplate(item);
                setSelectedTemplateItem(newSelectedTemplateItem);
            }
        }, 300);
    };
    const handleSelectEtaniTemplate =  async(selectedTemplate) => {
       
        if(selectedTemplate._id === selectedEtaniTempaltes?._id){
            setSelectedTemplateItem([])
            setNewDashboardName(null)
            setSelectedEtaniTemplates(null)
        }else{
            setSelectedTemplateItem([])
            setNewDashboardName(null)
            setSelectedEtaniTemplates(null)
            setTimeout(() => {
                const templateKPI = [...selectedTemplate.dashboardTemplate]
                const KPIDataSet = []

                sqlExampleResult.map(template => {
                    template.details.map(category => {
                        category.details.map(details => {
                            KPIDataSet.push(details)
                        })
                    })
                })

                const updatedTemplateKPI = templateKPI.map(kpi => {
                    const item = KPIDataSet.find(i => i.display_name === kpi.name);
                    if (item && kpi.query !== item.query) {
                        // Return the updated item
                        return item;
                    }
                    // Return the original kpi if no changes are needed
                    return kpi;
                });
                
                // console.log(updatedTemplateKPI);
                

                setNewDashboardName(selectedTemplate.name.split("-")[0])
                setSelectedTemplateItem(updatedTemplateKPI)
                setSelectedEtaniTemplates(selectedTemplate)
            }, 100);
        }
    };
    const handleSelectCategory = useCallback(async (checked, value) => {
        let newItems = [...selectedTemplateItem];
        console.log(value)
        for (const item of value.details) {
            for (const e of item.details) {
                const exists = selectedTemplateItem.some(item => item.name === e.display_name);
            
        
                if (exists && !checked) {
                    // Remove item if it exists and should be unchecked
                    newItems = newItems.filter(item => item.name !== e.display_name);
                } else if (!exists && checked) {
                    // Add item if it does not exist and should be checked
                    const newItem = {
                        uid: await generateUUID(),
                        name: e.display_name,
                        query: e.query,
                        visual_type: e.visual_type,
                        visual_data: e.visual_data,
                        integration: value.integration
                    };
                    newItems.push(newItem);
                }
            }
        }
        setSelectedTemplateItem(newItems);
    }, [selectedTemplateItem]);
    
    const handleSelectDashboardType = useCallback((value) => {
        setNewDashboardName(null)
        setSelectedExistingDashboard(null)
        setSelectedTemplateItem([]);
        setDashboardType(value)
    }, []);
    const handleTabSelect = useCallback((value) => {
        setKey(value)
    }, []);
    const handleOnEditVisual = (index) => {
        setOnEditVisual(!onEditVisual)
        setOneditItem(selectedTemplateItem[index])
        setOneditItemIndex(index)
    }
    const handleCloseEditVisual = () => {
        
        setOnEditVisual(false)
        setNewUpdateCount(prevCount => prevCount + 1);
    }
    useEffect(() => {
    },[newUpdateCount])
    const handleSelectPinToDashboard = async (event) => {
        // setDashboardType('new');
        setPintoDashboard(event.target.value);
    };
    useEffect(() => {
        fetchClientGroups()
    },[pintoDashboard])

    const handleSelectGroupChange = async (value) => {
        const selectedGroupId = value && value.length > 0 && value.map((item) => item.value)
        setSelectedGroupId(selectedGroupId);
    };
    
    const sendNotificationPerGroup = (isTemplateEdit, newDashboardName) => {
        if(selectedGroupId.includes(selectedClientID))
        {
            const usersSet = new Set();
            clientUsers.forEach(user => { if(user !== userDetails.User.Email) usersSet.add(user)});
            const msg = isTemplateEdit ? 
            `${userDetails.User.FirstName} ${userDetails.User.LastName} updated the ${newDashboardName} dashboard in ${selectedClient.Name} group` : 
            `${userDetails.User.FirstName} ${userDetails.User.LastName} posted new dashboard in ${selectedClient.Name} group`

            sendNotificationHandler(usersSet, msg, selectedClient.Name)
        }

        customGroupList && customGroupList.forEach(group => {
            const usersSet = new Set();
            if (selectedGroupId.includes(group._id) && group.UserList && group.UserList.users) {
                group.UserList.users.forEach(user => { if(user !== userDetails.User.Email) usersSet.add(user)});
                const msg = isTemplateEdit ? 
                `${userDetails.User.FirstName} ${userDetails.User.LastName} updated the ${newDashboardName} dashboard in ${group.GroupName}` :
                `${userDetails.User.FirstName} ${userDetails.User.LastName} posted a dashboard in ${group.GroupName}`

                sendNotificationHandler(usersSet, msg, group.GroupName)
            }
        });
    }

    const sendNotificationHandler = async (users, msg, groupName) => {
        sendNotification(users, msg)
        const ClientID = props.selectedClientID
        const notification = {
          title: `new notification from the "${groupName}" group`,
          body:  msg,
          sound: 'default',
          badge: '1',
        };
        
        const clientToken = await mongoFetchToken({ clientid : ClientID, groupId: selectedClientID })
        if(!clientToken) return 
        
        const filteredTokens = clientToken.tokens
        .filter(token => {
            return users.includes(token.email.toLowerCase()) && token.email.toLowerCase() !== userDetails.User.Email.toLowerCase();
        })
        .map(token => token.token);

        if (filteredTokens.length === 0) return
    
        const message = {
          tokens: filteredTokens,
          data: { ClientID: ClientID, Name: selectedClient.Name ,  type: 'custom group', Id: selectedClient.id},
          notification: notification,
        };

        SendMultiDeviceNotification(message)
        SendExpoNotifications(message)
    };

    const sendNotification = async (selectedUsers, message) => {
        for (const email of selectedUsers) {
    
          if(email !== userDetails.User.Email)
          {
            const notifications = {
              uid: await generateUUID(),
              id: selectedClientID,
              clientId: selectedClientID,
              message: message,
              createdAt: new Date(),
              sender: userDetails.User.Email,
              isReport: true,
              email: email,
              user: email,
              url: '',
              groupId: selectedClientID,
            };
            // await socket.emit("newClientNotification", notifications);
            registerGroupNotification(notifications)
          }
        }
    };
    const fileTypeImages = {
        XB: XBImage,
        XPM: XPMImage,
        WFM: WFMImage
    };
    const RenderIntegrationTemplates = ({tabKey, etaniTempaltes}) => {
        // const newTabKey = tabKey === "WFM" ? "XPM" : tabKey
        const newTabKey = tabKey

        const newData = etaniTempaltes && etaniTempaltes.filter(template => template.name.split("-")[1] && template.name.split("-")[1] === newTabKey)

        return (
            newData.length > 0 ? 
            <div className='templateContainer' style={{ border: 'unset' }}>
                <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Templates</Label>
                <div className='templateContent'>

                    {etaniTempaltes.length > 0 && etaniTempaltes.map((template, index) => (
                        <>
                        {template.name.split("-")[1] && template.name.split("-")[1] === newTabKey &&
                            <div 
                                key={index} 
                                style={{ background: selectedEtaniTempaltes?._id === template._id ? bgColor : undefined, color: selectedEtaniTempaltes?._id === template._id ? textColor : undefined }}
                                onClick={e => handleSelectEtaniTemplate(template)}
                            >
                                {template.name.split("-")[0]}
                            </div>
                        }
                        </>
                    ))}
                </div>
            </div>
            : null
        )
    }
    const handleToggleShowSettings = () => {
        setToggleShowSettings(!toggleShowSettings)
    }
    return (
        <div className="mt-6 fadeIn mainDashboard" style={{ height: windowHeight - 72 , overflow: 'hidden'}}>
        {alert}
        
            <div className="nav-wrapper dashboardTemplateContainer">

                {onEditVisual &&
                    <div className='onEditVisualContainer'>
                        <div className='onEditVisualContent'>
                            <EVAVisualEditDashboard
                                index={onEditItemIndex}
                                name={onEditItem.name}
                                query={onEditItem.query}
                                item={onEditItem}
                                selectedTemplateItem={selectedTemplateItem}
                                onEditVisual={onEditVisual}
                                handleCloseEditVisual={handleCloseEditVisual}
                            />
                        </div>
                    </div>
                }

                <div className='templateListContainer' 
                    style={{ 
                        marginTop: 1, height: screenHeight, background: '#f7f7f7', 
                        padding: 10, overflow: 'auto', paddingBottom: 110,
                        width: !toggleShowSettings ? '100%' : 'calc(100% - 400px)'
                    }}>
                    <ManageDashboardDnD 
                        selectedTemplateItem={selectedTemplateItem}
                        handleOnEditVisual={handleOnEditVisual}
                        onEditVisual={onEditVisual}
                        handleCloseEditVisual={handleCloseEditVisual}
                        newUpdateCount={newUpdateCount}
                        handleSelectItem={handleSelectItem}
                    />
                </div>
               
                <div className='templateListSettings' style={{ height: screenHeight - 73 , width: toggleShowSettings ? 600 : 0 }}>
                    <div className='toggleShowSettings' onClick={() => handleToggleShowSettings()}>
                        {toggleShowSettings ? 'Hide' : 'Show'} Menu
                    </div>

                    {toggleShowSettings &&
                        <div className='templateListContainer' style={{ height: screenHeight - 73}}>
                        {isLoading ? <CustomSpinner /> :
                            <Form>
                                <FormGroup>
                                    {sqlExampleResult && sqlExampleResult.length > 0 ?
                                        <div className='templateDashboardTabView'>
                                            {/* <div className="nav nav-tabs" role="tablist" style={{ border: `unset`}}>
                                                    <>
                                                        <a 
                                                            id={1} 
                                                            href="#" role="tab" 
                                                            key={1}
                                                            style={dashboardType === 'New' ? { backgroundColor: bgColor, color: textColor }:{ border: '1px solid #e0e0e0' }}
                                                            className={`nav-item nav-link ${dashboardType === 'New' ? "active" : ""}`}
                                                            onClick={() => handleSelectDashboardType('New')}>
                                                                New Dashboard
                                                        </a>
                                                        <a 
                                                            id={2} 
                                                            href="#" role="tab" 
                                                            key={2}
                                                            style={dashboardType === 'Existing' ? { backgroundColor: bgColor, color: textColor }:{ border: '1px solid #e0e0e0' }}
                                                            className={`nav-item nav-link ${dashboardType === 'Existing' ? "active" : ""}`}
                                                            onClick={() => handleSelectDashboardType('Existing')}>
                                                                Public Dashboard
                                                        </a>
                                                    </>
                                            </div> */}
                                            {dashboardType === 'Existing' ? 
                                                <div style={{ padding: '10px 10px', border: '1px solid #e0e0e0', marginBottom: 10 }}>
                                                    {/* USER DASHBOARD */}
                                                    <div className='templateContainer' style={{ border: 'unset' }}>
                                                        <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Dashboards</Label>
                                                        {userTemplatesList.length > 0 ? 
                                                            <div className='templateContent'>
                                                                    {userTemplatesList.map((item, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <div  key={index} style={{ background: selectedExistingDashboard === item._id && bgColor, color: selectedExistingDashboard === item._id && textColor }}
                                                                                onClick={e => handleSelectExistingTemplate(item)}>
                                                                                {item.name}
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ))}
                                                            </div>
                                                            :
                                                            <>
                                                                <div className='noTemplateFound'>
                                                                    No public dashboard template found!
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="modal-footer">
                                                        <CustomButton
                                                            type="button"
                                                            onClick={() => handleImportTemplate()}
                                                            outlineStyle={false}
                                                            title="Save Dashboard"
                                                        />
                                                        <CustomButton
                                                            onClick={() => {
                                                                return history.push(`/client/customDashboard`);
                                                            }}
                                                            outlineStyle={true}
                                                            data-dismiss="modal"
                                                            type="button"
                                                            color="link"
                                                            title="Close"
                                                        /> 
                                                    
                                                    </div>
                                                </div>
                                            :
                                                <div style={{ padding: '10px 10px', border: '1px solid #e0e0e0', borderRadius: 5, marginBottom: 10, position: 'relative' }}>
                                                
                                                        <Row>
                                                            <Col xl='12'>
                                                                <FormGroup>
                                                                    <Label 
                                                                        htmlFor="newDashboardName" 
                                                                        style={{ 
                                                                            fontWeight: 'bold', 
                                                                            fontSize: 14, 
                                                                            marginBottom: 7
                                                                        }}>Dashboard Name</Label>
                                                                        <Input
                                                                            key={1}
                                                                            type="text"
                                                                            id="newDashboardName"
                                                                            placeholder="Dashboard Name"
                                                                            value={newDashboardName}
                                                                            onChange={handleNewDashboardNameChange}
                                                                        />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl='12'>
                                                                <FormGroup tag="fieldset">
                                                                    <Label 
                                                                        htmlFor="personalDashboard" 
                                                                        style={{ 
                                                                            fontWeight: 'bold', 
                                                                            fontSize: 14, 
                                                                            marginBottom: 7
                                                                        }}>Dashboard Location</Label>
                                                                    <CustomInput
                                                                        type="radio"
                                                                        id="personalDashboard"
                                                                        name="pinTodashboardType"
                                                                        value="personal"
                                                                        label="Personal Dashboard"
                                                                        checked={pintoDashboard === 'personal'}
                                                                        onChange={handleSelectPinToDashboard}
                                                                    />
                                                                    <CustomInput
                                                                        type="radio"
                                                                        id="otherDashboard"
                                                                        name="pinTodashboardType"
                                                                        value="group"
                                                                        label="Group Dashboard"
                                                                        checked={pintoDashboard === 'group'}
                                                                        onChange={handleSelectPinToDashboard}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl='12'>
                                                                {pintoDashboard === 'group' && (
                                                                    <FormGroup>
                                                                        <Label 
                                                                        htmlFor="personalDashboard" 
                                                                        style={{ 
                                                                            fontWeight: 'bold', 
                                                                            fontSize: 14, 
                                                                            marginBottom: 7
                                                                        }}>Group List</Label>
                                                                        <Select 
                                                                            defaultValue={defaultSelectedGroup}
                                                                            isMulti
                                                                            name="colors"
                                                                            options={groupList}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            onChange={handleSelectGroupChange}
                                                                        />
                                                                    </FormGroup>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <div className="nav nav-tabs" role="tablist" style={{ border: `unset`}}>
                                                            {integration && integration.map((item, index) => {
                                                                const correspondingItem = selectClientIntegration.find(listItem => listItem.integration === item);
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <a 
                                                                            id={correspondingItem.id} 
                                                                            href="#" 
                                                                            role="tab" 
                                                                            style={{
                                                                                ...(tabKey === item ? { backgroundColor: bgColor, color: textColor } : { border: '1px solid #e0e0e0' }),
                                                                                padding: '5px 10px 5px 4px'
                                                                            }}
                                                                            className={`nav-item nav-link ${tabKey === item ? "active" : ""}`}
                                                                            onClick={() => handleTabSelect(item)}>
                                                                                {item === "XB" || item === "XPM" ||item === "WFM"? 
                                                                                    <img src={fileTypeImages[item]} alt={`${item} logo`} style={{ height: 45, padding: 5 }} />
                                                                                :
                                                                                    <img src={correspondingItem.logo} alt={`${item} logo`} style={{ height: 45, padding: 5 }} />
                                                                                }
                                                                                
                                                                                {item} Integration
                                                                        </a>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </div>
                                                        <div className='card-body' style={{ border: '1px solid #e0e0e0' , padding: '10px 10px',}}>
                                                            <Row>
                                                                <Col xs='12'>
                                                                    <RenderIntegrationTemplates 
                                                                        tabKey={tabKey}
                                                                        etaniTempaltes={etaniTempaltes} />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                {sqlExampleResult && sqlExampleResult.map((item, index) => (
                                                                    item.integration === tabKey &&
                                                                    <Col xs='12'>
                                                                        <div className='card'>
                                                                            <DashboardTemplateKPI  
                                                                                index={index}
                                                                                categories={categories}
                                                                                sub_category={sub_category}
                                                                                tabKey={tabKey}
                                                                                item={item}
                                                                                selectedTemplateItem={selectedTemplateItem}
                                                                                handleSelectItem={handleSelectItem}
                                                                                sqlExampleResult={sqlExampleResult}
                                                                                category={item.category}
                                                                                handleSelectCategory={handleSelectCategory}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </div>
                                                    
                                                </div>
                                            }

                                            <div className="modal-footer">
                                                <CustomButton
                                                    type="button"
                                                    onClick={() => handleSave()}
                                                    outlineStyle={false}
                                                    title="Save Dashboard"
                                                />
                                                <CustomButton
                                                    onClick={() => {
                                                        return history.push(`/client/customDashboard`);
                                                    }}
                                                    outlineStyle={true}
                                                    data-dismiss="modal"
                                                    // className="ml-auto"
                                                    type="button"
                                                    color="link"
                                                    title="Close"
                                                /> 
                                            </div> 
                                        </div>
                                    :
                                        <>
                                            <div className="dashboardItems" style={{
                                                height: '200px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center', // Center horizontally
                                                justifyContent: 'center', // Center vertically
                                                flexWrap: 'wrap'
                                            }}>
                                                <div className="dashboardItemError" style={{color: '#9f9f9f'}}>No available templates for this client</div>
                                                <div className="dashboardItemError" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
                                            </div>
                                        </>
                                    }
                                </FormGroup>
                            </Form>
                        }
                        </div>
                    }
                </div>

                {!toggleShowSettings &&
                    <div className="toggle-modal-footer">
                        <CustomButton
                            onClick={() => {
                                return history.push(`/client/customDashboard`);
                            }}
                            outlineStyle={true}
                            data-dismiss="modal"
                            // className="ml-auto"
                            type="button"
                            color="link"
                            title="Close"
                        /> 
                        <CustomButton
                            type="button"
                            onClick={() => handleSave()}
                            outlineStyle={false}
                            title="Save Dashboard"
                        />
                    </div> 
                }
            </div>
       
        </div>
    )
}

export default DashboardTemplate

// <PanelCard headerName="Dashboard Templates">
//                     {isLoading ? <CustomSpinner />
//                     : 
//                     <Form>
//                         <FormGroup>
//                             {sqlExampleResult && sqlExampleResult.length > 0 ?
//                                 <div className='templateDashboardTabView'>
//                                     <div className="nav nav-tabs" role="tablist" style={{ border: `unset`}}>
//                                             <>
//                                                 <a 
//                                                     id={1} 
//                                                     href="#" role="tab" 
//                                                     key={1}
//                                                     style={dashboardType === 'New' ? { backgroundColor: bgColor, color: textColor }:{ border: '1px solid #e0e0e0' }}
//                                                     className={`nav-item nav-link ${dashboardType === 'New' ? "active" : ""}`}
//                                                     onClick={() => handleSelectDashboardType('New')}>
//                                                         New Dashboard
//                                                 </a>
//                                                 <a 
//                                                     id={2} 
//                                                     href="#" role="tab" 
//                                                     key={2}
//                                                     style={dashboardType === 'Existing' ? { backgroundColor: bgColor, color: textColor }:{ border: '1px solid #e0e0e0' }}
//                                                     className={`nav-item nav-link ${dashboardType === 'Existing' ? "active" : ""}`}
//                                                     onClick={() => handleSelectDashboardType('Existing')}>
//                                                         Public Dashboard
//                                                 </a>
//                                             </>
//                                     </div>
//                                     {dashboardType === 'Existing' ? 
//                                         <div style={{ padding: '20px 15px', border: '1px solid #e0e0e0', borderRadius: 5, marginBottom: 10 }}>
//                                             {/* USER DASHBOARD */}
//                                             <div className='templateContainer' style={{ border: 'unset' }}>
//                                                 <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Dashboards</Label>
//                                                 {userTemplatesList.length > 0 ? 
//                                                     <div className='templateContent'>
//                                                             {userTemplatesList.map((item, index) => (
//                                                                 <React.Fragment key={index}>
//                                                                     <div  key={index} style={{ background: selectedExistingDashboard === item._id && bgColor, color: selectedExistingDashboard === item._id && textColor }}
//                                                                         onClick={e => handleSelectExistingTemplate(item)}>
//                                                                         {item.name}
//                                                                     </div>
//                                                                 </React.Fragment>
//                                                             ))}
//                                                     </div>
//                                                     :
//                                                     <>
//                                                         <div className='noTemplateFound'>
//                                                             No public dashboard template found!
//                                                         </div>
//                                                     </>
//                                                 }
//                                             </div>
//                                             <div className="modal-footer">
//                                                 <CustomButton
//                                                     type="button"
//                                                     onClick={() => handleImportTemplate()}
//                                                     outlineStyle={false}
//                                                     title="Save Dashboard"
//                                                 />
//                                                 <CustomButton
//                                                     onClick={() => {
//                                                         return history.push(`/client/customDashboard`);
//                                                     }}
//                                                     outlineStyle={true}
//                                                     data-dismiss="modal"
//                                                     // className="ml-auto"
//                                                     type="button"
//                                                     color="link"
//                                                     title="Close"
//                                                 /> 
                                            
//                                             </div>
//                                         </div>
//                                     :
//                                         <div style={{ padding: '20px 15px', border: '1px solid #e0e0e0', borderRadius: 5, marginBottom: 10 }}>
                                        
//                                                 <Row>
//                                                     <Col xl='4' md='6' sm='12'>
//                                                         <FormGroup>
//                                                             <Label 
//                                                                 htmlFor="newDashboardName" 
//                                                                 style={{ 
//                                                                     fontWeight: 'bold', 
//                                                                     fontSize: 14, 
//                                                                     marginBottom: 7
//                                                                 }}>Dashboard Name</Label>
//                                                                 <Input
//                                                                     key={1}
//                                                                     type="text"
//                                                                     id="newDashboardName"
//                                                                     placeholder="Dashboard Name"
//                                                                     value={newDashboardName}
//                                                                     onChange={handleNewDashboardNameChange}
//                                                                 />
//                                                         </FormGroup>
//                                                     </Col>
//                                                     <Col xl='3' md='6' sm='12'>
//                                                         <FormGroup tag="fieldset">
//                                                             <Label 
//                                                                 htmlFor="personalDashboard" 
//                                                                 style={{ 
//                                                                     fontWeight: 'bold', 
//                                                                     fontSize: 14, 
//                                                                     marginBottom: 7
//                                                                 }}>Dashboard Location</Label>
//                                                             <CustomInput
//                                                                 type="radio"
//                                                                 id="personalDashboard"
//                                                                 name="pinTodashboardType"
//                                                                 value="personal"
//                                                                 label="Personal Dashboard"
//                                                                 checked={pintoDashboard === 'personal'}
//                                                                 onChange={handleSelectPinToDashboard}
//                                                             />
//                                                             <CustomInput
//                                                                 type="radio"
//                                                                 id="otherDashboard"
//                                                                 name="pinTodashboardType"
//                                                                 value="group"
//                                                                 label="Group Dashboard"
//                                                                 checked={pintoDashboard === 'group'}
//                                                                 onChange={handleSelectPinToDashboard}
//                                                             />
//                                                         </FormGroup>
//                                                     </Col>
//                                                     <Col xl='4' md='6' sm='12'>
//                                                         {pintoDashboard === 'group' && (
//                                                             <FormGroup>
//                                                                 <Label 
//                                                                 htmlFor="personalDashboard" 
//                                                                 style={{ 
//                                                                     fontWeight: 'bold', 
//                                                                     fontSize: 14, 
//                                                                     marginBottom: 7
//                                                                 }}>Group List</Label>
//                                                                 <Select 
//                                                                 // defaultValue={[groupList[2], groupList[3]]}
//                                                                     defaultValue={defaultSelectedGroup}
//                                                                     isMulti
//                                                                     name="colors"
//                                                                     options={groupList}
//                                                                     className="basic-multi-select"
//                                                                     classNamePrefix="select"
//                                                                     onChange={handleSelectGroupChange}
//                                                                 />
//                                                                 {/* <Input type="select" id="selectedGroupId" value={selectedGroupId} onChange={handleSelectGroupChange}>
//                                                                 <option value="">Select Group</option>
//                                                                 {groupList.map(group => (
//                                                                     <option key={group._id} value={group._id}>
//                                                                     {group.GroupName}
//                                                                     </option>
//                                                                 ))}
//                                                                 </Input> */}
//                                                             </FormGroup>
//                                                         )}
//                                                     </Col>
//                                                 </Row>

//                                                 {/* TEMPLATES */}
//                                                 {/* <div className='templateContainer' style={{ border: 'unset' }}>
//                                                     <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Templates</Label>
//                                                     <div className='templateContent'>
    
//                                                         {etaniTempaltes.length > 0 && etaniTempaltes.map((template, index) => (
//                                                             <div 
//                                                                 key={index} 
//                                                                 style={{ background: selectedEtaniTempaltes?._id === template._id ? bgColor : undefined, color: selectedEtaniTempaltes?._id === template._id ? textColor : undefined }}
//                                                                 onClick={e => handleSelectEtaniTemplate(template)}
//                                                             >
//                                                                 {template.name}
//                                                             </div>
//                                                         ))}
//                                                     </div>
//                                                 </div> */}

//                                                 {/* INTEGRATION */}
//                                                 <div className="nav nav-tabs" role="tablist" style={{ border: `unset`}}>
//                                                     {integration && integration.map((item, index) => {
//                                                         const correspondingItem = selectClientIntegration.find(listItem => listItem.integration === item);
//                                                         return (
//                                                             <React.Fragment key={index}>
//                                                                 <a 
//                                                                     id={correspondingItem.id} 
//                                                                     href="#" 
//                                                                     role="tab" 
//                                                                     style={{
//                                                                         ...(tabKey === item ? { backgroundColor: bgColor, color: textColor } : { border: '1px solid #e0e0e0' }),
//                                                                         padding: '5px 10px 5px 4px'
//                                                                     }}
//                                                                     className={`nav-item nav-link ${tabKey === item ? "active" : ""}`}
//                                                                     onClick={() => handleTabSelect(item)}>
//                                                                         {item === "XB" || item === "XPM" ||item === "WFM"? 
//                                                                             <img src={fileTypeImages[item]} alt={`${item} logo`} style={{ height: 45, padding: 5 }} />
//                                                                         :
//                                                                             <img src={correspondingItem.logo} alt={`${item} logo`} style={{ height: 45, padding: 5 }} />
//                                                                         }
                                                                        
//                                                                         {item} Integration
//                                                                 </a>
//                                                             </React.Fragment>
//                                                         );
//                                                     })}
//                                                 </div>
//                                                 {/* KPI */}
//                                                 {/* <div className='nav-child' style={{ padding: 10, border: '1px solid #e0e0e0', borderRadius: 5, display: 'flex', flexWrap: 'wrap', marginBottom: 10 }}> */}
//                                                 <div className='card-body' style={{ border: '1px solid #e0e0e0'}}>
//                                                     <Row>
//                                                         <Col xs='12'>
//                                                             <RenderIntegrationTemplates 
//                                                                 tabKey={tabKey}
//                                                                 etaniTempaltes={etaniTempaltes} />
//                                                         </Col>
//                                                     </Row>
//                                                     <Row>
//                                                         {sqlExampleResult && sqlExampleResult.map((item, index) => (
//                                                             item.integration === tabKey &&
//                                                             // <div key={index} 
//                                                             //     // style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10}}
//                                                             //     style={{ height: 'max-content'}}
//                                                             //     >
//                                                             <Col xs='12' xl='4' lg='4' md='6' >
//                                                                 <div className='card'>
//                                                                     <DashboardTemplateKPI  
//                                                                         index={index}
//                                                                         categories={categories}
//                                                                         sub_category={sub_category}
//                                                                         tabKey={tabKey}
//                                                                         item={item}
//                                                                         selectedTemplateItem={selectedTemplateItem}
//                                                                         handleSelectItem={handleSelectItem}
//                                                                         sqlExampleResult={sqlExampleResult}
//                                                                         category={item.category}
//                                                                         handleSelectCategory={handleSelectCategory}
//                                                                     />
//                                                                 </div>
//                                                             </Col>
//                                                         ))}
//                                                     </Row>
//                                                 </div>
//                                                 {/* </div> */}
//                                             {/* Add Ons */}
//                                             {/* <div className='addOneContainer' style={{ border: 'unset' }}>
//                                                 <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Add Ons</Label>
//                                                 <div className='addOneContent'>
//                                                 <div>Text Box</div>
//                                                 <div>Note</div>
//                                                 <div>Image</div>
//                                                 </div>
//                                             </div>
//                                             */}
//                                             <div className="modal-footer">
//                                                 <CustomButton
//                                                     type="button"
//                                                     onClick={() => handleSave()}
//                                                     outlineStyle={false}
//                                                     title="Save Dashboard"
//                                                 />
//                                                 <CustomButton
//                                                     onClick={() => {
//                                                         return history.push(`/client/customDashboard`);
//                                                     }}
//                                                     outlineStyle={true}
//                                                     data-dismiss="modal"
//                                                     // className="ml-auto"
//                                                     type="button"
//                                                     color="link"
//                                                     title="Close"
//                                                 /> 
                                            
//                                             </div> 
//                                         </div>
//                                     }

//                                 </div>
//                             :
//                                 <>
//                                     <div className="dashboardItems" style={{
//                                         height: '200px',
//                                         display: 'flex',
//                                         flexDirection: 'column',
//                                         alignItems: 'center', // Center horizontally
//                                         justifyContent: 'center', // Center vertically
//                                         flexWrap: 'wrap'
//                                     }}>
//                                         <div className="dashboardItemError" style={{color: '#9f9f9f'}}>No available templates for this client</div>
//                                         <div className="dashboardItemError" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
//                                     </div>
//                                 </>
//                             }
//                         </FormGroup>
//                     </Form>
//                     }
                
//                 </PanelCard>